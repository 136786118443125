import MaterialModal from '..'
import ActivateCardModalContent from './ActivateCardModalContent'

interface ActivateCardModalProps {
  isOpen: boolean
  onClose: () => void
  actionProps: any,
  updateCardholder:any,
}

const ActivateCardModal = ({ isOpen, ...rest }: ActivateCardModalProps) => {
  return (
    <MaterialModal open={isOpen} handleClose={rest.onClose} width={'25rem'}>
      <ActivateCardModalContent {...rest} />
    </MaterialModal>
  )
}

export default ActivateCardModal
