import { Grid } from "@mui/material";
import moment from "moment";
import { useState } from "react";

import { fundTransfer } from "../../../../../apis/b2c_api";
import useConfigs from "../../../../../hooks/use-config";
import useLabels from "../../../../../hooks/use-labels";
import useStyles from "../../../../../hooks/use-styles";
import {
  formatCurrency,
  padLastFour,
  showErrorNotification,
} from "../../../../../utils/common-utils";
import CustomButton from "../../../../custom-button";
import FlexButtons from "../../../../flex-button";
import Loader from "../../../../shared/loader/loader";
import customToast from "../../../../shared/services/toaster-service";

interface ConfirmTransferProps {
  onSuccessfulTransfer: () => void;
  handlePrevious: (values: any) => void;
  onClose: () => void;
  recipient?: any;
  account: any;
  amount: string;
  SecondaryTransfer: boolean,
  secondaryAccount: any;

}

const ConfirmTransfer = (props: ConfirmTransferProps) => {
  const {
    recipient,
    account,
    amount,
    secondaryAccount,
    onClose,
    onSuccessfulTransfer,
    handlePrevious,
    SecondaryTransfer
  } = props;

  const { currencyCode } = account.balance;

  const [loading, setLoading] = useState(false);

  const {
    cancelLabel,
    transferFromLabel,
    transferToLabel,
    transferDateLabel,
    transferAmountLabel,
    previousButtonLabel,
    transferNowButtonLabel,
    confirmTransferDetails,
  } = useLabels([
    "cancelLabel",
    "transferFromLabel",
    "transferToLabel",
    "transferDateLabel",
    "transferAmountLabel",
    "previousButtonLabel",
    "transferNowButtonLabel",
    "confirmTransferDetails",
  ]);

  const { transferFundsSuccessMessage } = useConfigs([
    "transferFundsSuccessMessage",
  ]);

  const { labelColor, lightBlueColor, lightTitleColor, subTitleColor } =
    useStyles([
      "labelColor",
      "lightBlueColor",
      "lightTitleColor",
      "subTitleColor",
    ]);

  const transferAmountToRecepient = () => {
    const { cardId } = account;
    

    const { currencyCode } = account.balance;
    const { recipientId } = recipient;

    const transferProps = {
      amount: parseFloat(amount),
      currency: currencyCode,
      recipientId,
      fromCardId: cardId,
    };

    setLoading(true);

    fundTransfer(transferProps)
      .then((response) => {
        setLoading(false);
        onSuccessfulTransfer();
        customToast.success(transferFundsSuccessMessage)
      })
      .catch((error) => {
        setLoading(false);
        showErrorNotification(error);
      });
  };

  const transferAmountToCard = () => {
    const { cardId } = account;
    

    const { currencyCode } = account.balance;

    const transferProps = {
      amount: parseFloat(amount),
      currency: currencyCode,
      beneficiary: secondaryAccount,
      fromCardId: cardId,
      SecondaryTransfer,
    };

    setLoading(true);

    fundTransfer(transferProps)
      .then((response) => {
        setLoading(false);
        onSuccessfulTransfer();
        customToast.success(transferFundsSuccessMessage)
      })
      .catch((error) => {
        setLoading(false);
        showErrorNotification(error);
      });
  };

  const transferPreview = [
    {
      label: transferFromLabel,
      content: `${account.nameOnCard} (${padLastFour(account.lastFour)})`,
    },
    {
      label: transferToLabel,
      content: SecondaryTransfer ? `${secondaryAccount.nameOnCard} (${padLastFour(secondaryAccount.lastFour)})`  : recipient.nickName
    },
    {
      label: transferAmountLabel,
      content: formatCurrency(+amount, currencyCode),
    },
    {
      label: transferDateLabel,
      content: moment().format("MM/DD/YYYY"),
    },
  ];

  return (
    <div className="mat-modal-body align-center pl-2 pr-2">
      <div className="align-center pl-2 pr-2">
        <Grid
          container
          columnSpacing={{ md: 12, xs: 6, sm: 12 }}
          rowSpacing={2}
        >
          {transferPreview.map((transferItem, index) => (
            <Grid key={index} item md={6} sm={6} xs={6}>
              <p className="weight-600 text-sm" style={{ color: labelColor }}>
                {transferItem.label}
              </p>
              <p
                className="weight-400 text-sm"
                style={{ color: lightTitleColor }}
              >
                {transferItem.content}
              </p>
            </Grid>
          ))}
        </Grid>
      </div>

      <p
        className="weight-400 text-xs text-left mt"
        style={{ color: subTitleColor }}
      >
        {confirmTransferDetails}
      </p>

      {loading && <Loader className="custom-loader mt-1" />}

      <FlexButtons className="layout-buttons mb-3 pt-4">
        <CustomButton
          type="previous"
          text={previousButtonLabel}
          className="weight-500 transparent-background"
          onClick={handlePrevious}
          style={{ width: "9rem" }}
        />
        <CustomButton
          type="next"
          text={transferNowButtonLabel}
          onClick={SecondaryTransfer ? transferAmountToCard: transferAmountToRecepient  }
          style={{ width: "9rem" }}
          disabled={loading}
        />
      </FlexButtons>
      <p
        className="pointer text-sm text-center pb-3"
        style={{ color: lightBlueColor }}
        onClick={onClose}
      >
        {cancelLabel}
      </p>
    </div>
  );
};
export default ConfirmTransfer;
