import { Grid } from '@mui/material'
import { useState } from 'react'
import useConfigs from '../../../../../hooks/use-config'
import useLabels from '../../../../../hooks/use-labels'
import { changePasswordFields } from '../../../../../utils/fields.utils'
import { errorMessage, fieldValidator } from '../../../../../utils/validation'
import CustomButton from '../../../../custom-button'
import FlexButtons from '../../../../flex-button'
import FormField from '../../../../form/form-field'
import Loader from '../../../../shared/loader/loader'
import SectionHeader from '../../../../signup-form/header/section-header'

interface SetNewPasswordProps {
  onClose: () => void
  onFormSubmit: (fieldValues: any) => void
  loading?: boolean
}

const ResetPasswordForm = (props: SetNewPasswordProps) => {
  const { onFormSubmit, onClose, loading } = props

  const [errors, setFieldErrors] = useState({}) as any

  const [fieldValues, setFieldValues] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: ''
  }) as any

  let { strongPasswordOptions } = useConfigs(['strongPasswordOptions'])

  const { setNewPasswordLabel, cancelLabel, confirmLabel } = useLabels([
    'setNewPasswordLabel',
    'confirmLabel',
    'cancelLabel'
  ])

  const { oldPasswordLabel, newPasswordLabel, reTypePasswordLabel } = useLabels([
    'oldPasswordLabel',
    'newPasswordLabel',
    'reTypePasswordLabel'
  ])

  const fieldProps = {
    oldPasswordLabel,
    newPasswordLabel,
    reTypePasswordLabel
  }

  const validate = (key: string, value: string, options?: any) => {
    let fieldErrors = { ...errors }
    const { password, confirmPassword } = fieldValues

    if (!value.trim() && options.required) {
      fieldErrors[key] = errorMessage.required
    } else if (key !== 'oldPassword' && value.length < 8) {
      fieldErrors[key] = errorMessage.shortPassword
    } else if (
      key !== 'oldPassword' &&
      !fieldValidator.strongPassword(value, strongPasswordOptions)
    ) {
      fieldErrors[key] = errorMessage.weakPassword
    } else if (
      key === 'password' &&
      !fieldValidator.matchPassword(value, confirmPassword)
    ) {
      fieldErrors[key] = errorMessage.mismatchPassword
    } else if (
      key === 'confirmPassword' &&
      !fieldValidator.matchPassword(value, password)
    ) {
      fieldErrors[key] = errorMessage.mismatchPassword
    } else if (
      ['password', 'confirmPassword'].includes(key) &&
      fieldValues.password &&
      fieldValues.confirmPassword
    ) {
      delete fieldErrors.password
      delete fieldErrors.confirmPassword
    } else if (key === 'oldPassword' && value) {
      delete fieldErrors.oldPassword
    } else {
      delete fieldErrors[key]
    }
    setFieldErrors(fieldErrors)
  }

  const onFieldChange = (key: string, value: string, options?: any) => {
    validate(key, value, options)
    setFieldValues((prev: any) => ({ ...prev, [key]: value }))
  }

  const validFields = () => {
    let fieldErrors = { ...errors }

    const requiredFields = ['password', 'confirmPassword']

    let valid = true

    requiredFields.forEach((field) => {
      if (!fieldValues[field]) {
        fieldErrors[field] = errorMessage.required
        valid = false
      }
    })

    setFieldErrors(fieldErrors)
    return valid
  }

  const onSubmit = () => {
    if (validFields()) {
      onFormSubmit(fieldValues)
    }
  }

  return (
    <div>
      <SectionHeader className="mat-modal-header" title={setNewPasswordLabel} />

      <div className='mt'>
      <Grid container className="pl-2 pr-2">
        {changePasswordFields(fieldProps).map((item: any) => {
          const { name, required } = item.elementProps
          return (
            <Grid item md={12} sm={12} xs={12}>
              <FormField
                {...item}
                onChange={(name: string, value: string) =>
                  onFieldChange(name, value, { required })
                }
                value={fieldValues[name]}
                error={errors[name]}
              />
            </Grid>
          )
        })}
      </Grid>

      </div>
      

      {loading && <Loader className="custom-loader" />}

      <FlexButtons className="mt-5 mb-5">
        <CustomButton
          className="weight-500"
          type="outline"
          text={cancelLabel}
          onClick={onClose}
          style={{ background: 'transparent', width: '8rem' }}
        />
        <CustomButton
          type="primary"
          text={confirmLabel}
          style={{ width: '8rem' }}
          onClick={onSubmit}
        />
      </FlexButtons>
    </div>
  )
}

export default ResetPasswordForm
