import useConfigs from "../../hooks/use-config";
import useStyles from "../../hooks/use-styles";
import "./card-view-style.css";

const CardView = (props: any) => {
  const { cardProps } = props;
  const { cardRemitLogo, cardBackground } = useConfigs([
    "cardRemitLogo",
    "cardBackground",
  ]);

  const { cardTextColor } = useStyles(["cardTextColor"]);

  return (
    <div
      className={`cardView d-flex-col ${props.className!}`}
      style={{
        backgroundImage: `url(${cardBackground})`,
        color: cardTextColor,
      }}
    >
      <div className="remitImage">
        <img src={cardRemitLogo} alt="Remit" className="first-image" />
      </div>
      {cardProps.type && <p className="card-type">{cardProps.type}</p>}
      <p className="card-number">{cardProps.cardNumber}</p>

      <div className="security-valid-info d-flex mt-1">
        <div className="security-info d-flex gap-1">
          <p className="card-info-title">
            Security <br /> Code
          </p>
          <p className="text-xs weight-600">{cardProps.security}</p>
        </div>

        <div className="valid-info ">
          <p className="card-info-title">
            Valid <br /> Thru
          </p>
          <p className="text-xs weight-600">{cardProps.validTill}</p>
        </div>
      </div>

      <div className="d-flex align-center justify-between mt-2">
        <p className="cardTitle">{cardProps.title}</p>
        <img
          src={cardProps.discoverImage}
          alt="discoverImage"
          className="second-image"
        />
      </div>
    </div>
  );
};

export default CardView;
