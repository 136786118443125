export function VGS_ENABLED(): string {
  const data = JSON.parse(localStorage.getItem('init') || "{}")
  return data?.vgs_enabled
}

export function VGS_URL(): string {
  const data = JSON.parse(localStorage.getItem('init') || "{}")
  return data?.vgs_url
}

export function B2C_URL(): string {
  const data = JSON.parse(localStorage.getItem('init') || "{}")
  return data?.b2c_url
}

export function B2C_KEY(): string {
  const data = JSON.parse(localStorage.getItem('init') || "{}")
  return data?.b2c_key
}

export function B2C_CONTEXT(): string {
  const data = JSON.parse(localStorage.getItem('init') || "{}")
  return data?.b2c_context
}

export function B2C_REFERRER(): string {
  const data = JSON.parse(localStorage.getItem('init') || "{}")
  return data?.b2c_referrer
}

export function AUTH_URL(): string {
  const data = JSON.parse(localStorage.getItem('init') || "{}")
  return data?.auth_url
}

export function AUTH_KEY(): string {
  const data = JSON.parse(localStorage.getItem('init') || "{}")
  return data?.auth_key
}

export function AUTH_APP_ID(): string {
  const data = JSON.parse(localStorage.getItem('init') || "{}")
  return data.auth_app_id
}

export function INIT_URL(): string | undefined {
  return process.env.REACT_APP_INIT_URL?.trim()
}

export function INIT_KEY(): string {
  return process.env.REACT_APP_INIT_KEY || ""
}

export const INIT_APP_ID = (): string => {
  return "CARDHOLDER_WEBSITE";
}
export function SESSION_TIMEOUT(): string {
  // const data = JSON.parse(localStorage.getItem('init') || "{}")
  // return data.session_timeout || 300
  return '10'  
}

export function IDLE_TIMEOUT(): number {
  // const data = JSON.parse(localStorage.getItem('init') || "{}")
  // return data.idle_timeout || 300 
  return 10
}
