import { useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { getCardholder, updateCardHolder } from '../../../apis/b2c_api'
import customToast from '../../../components/shared/services/toaster-service'
import useConfigs from '../../../hooks/use-config'
import { setCardholder } from '../../../store/user/user-reducer'
import { showErrorNotification } from '../../../utils/common-utils'
import ProfileForm from './ProfileForm'

const UserProfile = (props: any) => {
  const { cardholder } = useSelector((state: RootStateOrAny) => state.users);
  const { state } = useLocation()
  const { secondaryCardholder }: any = state || {}
  const { cardholderId: primaryCardholderId } = cardholder
  const { cardholderId: secondaryCardholderId } = secondaryCardholder || {}

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { updateProfileSuccessMessage } = useConfigs([
    'updateProfileSuccessMessage'
  ])
  const  getPrimaryCardHolder =(id? :any) => {
    getCardholder()
        .then(({ cardholder }) => {
          setLoading(false);
          dispatch(setCardholder(cardholder))
        })
        .catch((error: any) => {
          setLoading(false)
          showErrorNotification(error)
        })
  
    }

  const updateProfile = (profileDetails: any) => {
    setLoading(true)
    updateCardHolder(
      profileDetails,
      secondaryCardholderId || primaryCardholderId
    )
      .then(({ updateCardHolder }: any) => {
        customToast.success(updateProfileSuccessMessage)
        getPrimaryCardHolder();
      })
      .catch((error: any) => {
        setLoading(false)
        showErrorNotification(error)
      })
  }



  return (
    <div>
      <ProfileForm
        onUpdateProfile={updateProfile}
        loading={loading}
        profileData={secondaryCardholder || cardholder}
        isSecondary={Boolean(secondaryCardholder)}
      />
    </div>
  )
}

export default UserProfile
