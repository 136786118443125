import React from 'react'
import './loading.css'

const Loader = (props: {
  message?: string
  style?: object
  className?: string
}): JSX.Element => {
  return (
    <div
      className={`parent-div ${props.className!}`}
      style={props.style && props.style}
    >
      <div className="center">
        <div className="loader"></div>
      </div>
    </div>
  )
}

export default Loader
