import { FC } from 'react'
import useLabels from '../../../../hooks/use-labels'
import useStyles from '../../../../hooks/use-styles'
import CustomButton from '../../../custom-button'
import SectionHeader from '../../../signup-form/header/section-header'
import TransactionStatements from './TransactionStatements'

interface StatementModalProps {
  onClose: () => void
  cardId: string
}

const StatementModalContent: FC<StatementModalProps> = ({
  onClose,
  cardId
}) => {
  const { closeLabel, accountStatement } = useLabels([
    'accountStatement',
    'routingNumberLabel',
    'closeLabel'
  ])

  const { fontColor } = useStyles(['fontColor'])

  return (
    <>
      <SectionHeader
        title={accountStatement}
        subTitle="View or download your statements below."
        subTitleColor={fontColor}
        className={'half-width'}
        subTitleClass={'mt-3'}
      />

      <div className="mat-modal-body fullWidth">
        <TransactionStatements cardId={cardId} />
      </div>

      <CustomButton
        className="mat-modal-footer modal-footer-button mt-2"
        text={closeLabel}
        type="primary"
        onClick={onClose}
      />
    </>
  )
}

export default StatementModalContent
