import MaterialModal from "..";
import useLabels from "../../../hooks/use-labels";
import ContentContainer from "../content-container";
import CustomButton from "../../custom-button";
import MaterialModalHeader from "../material-modal-header";
import FlexButtons from "../../flex-button";

const OwwQuestionModal = (props: any) => {
  const { isOpen, onClose } = props;
  const { outOfWalletPopupText, outOfWalletPopupButton } = useLabels([
    "outOfWalletPopupText",
    "outOfWalletPopupButton",
  ]);


  return (
    <MaterialModal
      boxClass="modal-width"
      open={isOpen}
      handleClose={onClose}
      width={"30rem"}
    >
      <MaterialModalHeader
        classMain="text-center"
        titleColor="#05315F"
        title={outOfWalletPopupText}
        onClose={onClose}
      />

      <ContentContainer className="full-height">
        <FlexButtons className="layout-buttons mb-3 pt-0">
          <CustomButton
            type="primary"
            text={outOfWalletPopupButton}
            style={{ width: "5.188rem", height: " 2.25rem" }}
            className="header-button-width header-button-height text-sm"
            onClick={onClose}
          />
        </FlexButtons>
      </ContentContainer>
    </MaterialModal>
  );
};
export default OwwQuestionModal;
