import MaterialModal from '..'
import ChangePinModalContent from './ChangePinModalContent'

interface ChangePinModalProps {
  isOpen: boolean
  onClose: () => void
  actionProps: any
}

const ChangePinModal = ({isOpen, ...rest}: ChangePinModalProps) => {
    
  return (
    <MaterialModal
      open={isOpen}
      handleClose={rest.onClose}
      width={'33rem'}
      boxClass={'transparent-background'}
    >
      <ChangePinModalContent {...rest} />
    </MaterialModal>
  )
}

export default ChangePinModal
