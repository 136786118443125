import { createSlice } from "@reduxjs/toolkit";
import User from "../../interfaces/user";

const getLocalState = (key:string) => {
  const localState = localStorage.getItem(key);
  if (localState) {
    return JSON.parse(localState);
  }

  return {};
};

const userState = {
  activeUser: getLocalState('activeUser'),
  cardholder: getLocalState('cardholder'),
  extRefNo: getLocalState('extRefNo'),
  reCallTransactions: false,
};

const userSlice = createSlice({
  name: "users",
  initialState: userState,
  reducers: {
    setLoginUser: (state, action) => {
      localStorage.setItem("activeUser", JSON.stringify(action.payload));
      state.activeUser = action.payload;
    },
    setCardholder: (state, action) => {
      localStorage.setItem("cardholder", JSON.stringify(action.payload));
      state.cardholder = action.payload;
    },
    setExtRefNo: (state, action) => {
      localStorage.setItem("extRefNo", JSON.stringify(action.payload));
      state.cardholder = action.payload;
    },
    setTransactionApi:(state)=>{
      state.reCallTransactions = !state.reCallTransactions;
    },
    logoutUser: (state) => {
      localStorage.removeItem("activeUser");
      localStorage.removeItem("cardholder");
      localStorage.removeItem("currentCard")
      state.activeUser = {} as User;
    },
  },
});

export const { setLoginUser, logoutUser, setCardholder, setTransactionApi,setExtRefNo } = userSlice.actions;
export default userSlice.reducer;
