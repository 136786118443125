import React, { useEffect, useState } from "react";
import Loader from "./components/shared/loader/loader";
import "./App.css";
import "./Bootstrap.css";
import { setMetadata } from "./utils/metadata";
import { changeLanguage } from "./utils/language-helper";
import AppContainer from "./views/AppContainer";
import { fetchMetaData } from "./apis/b2c_api";
import useConfigs from "./hooks/use-config";

const setLanguage = () => {
  const params = new URLSearchParams(window.location.search);
  const languageCode = params.get("language_code") || "en";
  changeLanguage(languageCode);
};

const App = (): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const { tabsRefreshTime_onBuild, screenRefreshTime_onBuild } = useConfigs(["tabsRefreshTime_onBuild", "screenRefreshTime_onBuild"]);

  const [currentVersion, setCurrentVersion] = useState<string | null>(null);
//   const checkInterval =      5 * 60 * 1000; // Poll every 5 minutes (or adjust as necessary)

  let lastCheckTime = 0;
  const checkForUpdatesThrottled = async () => {
    const now = Date.now();
    if (now - lastCheckTime >= Number(tabsRefreshTime_onBuild)) //tabsRefreshTime_onBuild is a metadata base its value is 1 minuites

    {
      lastCheckTime = now;
      await checkForUpdates();
    }
  };

  // Throttle version checks using time interval (for users actively on the tab)
  const setupPeriodicCheck = () => {
    const interval = setInterval(() => {
      checkForUpdates();
    }, Number(screenRefreshTime_onBuild)); //  screenRefreshTime_onBuild is a metadata base its value is 5 minuites. Check every 5 minutes

    return () => clearInterval(interval); // Cleanup on unmount
  };

  // Fetch version.json only when needed
  const checkForUpdates = async () => {
    try {
      const response = await fetch("/version.json", { cache: "no-store" });
      if (
        response.ok &&
        response.headers.get("content-type")?.includes("application/json")
      ) {
        const { version } = await response.json();
        if (currentVersion && version !== currentVersion) {
          window.location.reload(); // Forces reload
        } else {
          setCurrentVersion(version);
        }
        localStorage.setItem("version", version);
      } else {
        console.error("version.json not found or is not valid JSON");
      }
    } catch (error) {
      console.error("Failed to fetch version", error);
    }
  };

  // Event handler to check for updates when tab is focused or window is visible
  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      checkForUpdatesThrottled(); // Check only when the tab becomes visible
    }
  };

  // Event handler for window focus
  const handleWindowFocus = () => {
    checkForUpdatesThrottled(); // Check only when the window gets focus
  };

  useEffect(() => {
    // Perform an initial version check on mount
    checkForUpdates();

    // Setup interval-based checking (for long active sessions)
    const clearPeriodicCheck = setupPeriodicCheck();

    // Attach event listeners for tab focus and visibility change
    window.addEventListener("focus", handleWindowFocus);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup event listeners on unmount
    return () => {
      clearPeriodicCheck(); // Clear the interval when the component unmounts
      window.removeEventListener("focus", handleWindowFocus);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [currentVersion]);

  useEffect(() => {
    setLanguage();
    fetchMetaData()
      .then((metadata) => {
        setMetadata(metadata?.metadata ? JSON.parse(metadata.metadata) : {});
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  if (loading) return <Loader />;
  return (
    <div className="App Bootstrap">
      <AppContainer />
    </div>
  );
};

export default App;
