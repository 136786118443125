import { useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { applyForPhysicalCard } from '../../../../apis/b2c_api'
import useConfigs from '../../../../hooks/use-config'
import useLabels from '../../../../hooks/use-labels'
import useStyles from '../../../../hooks/use-styles'
import { showErrorNotification } from '../../../../utils/common-utils'
import CustomButton from '../../../custom-button'
import FlexButtons from '../../../flex-button'
import Loader from '../../../shared/loader/loader'
import customToast from '../../../shared/services/toaster-service'
import SectionHeader from '../../../signup-form/header/section-header'

interface ApplyPhysicalModalContentProps {
  isOpen: boolean
  onClose: () => void
  actionProps: any,
  updateCardholder:any,
}

const ApplyPhysicalModalContent = (props: ApplyPhysicalModalContentProps) => {
  const { onClose, updateCardholder } = props;

  const [loading, setLoading] = useState(false)

  const { cardholder } = useSelector((state: RootStateOrAny) => state.users)

  const { address1, city, region, postalCode, country } =
    cardholder.addresses[0]

  const { titleColor, subTitleColor } = useStyles([
    'titleColor',
    'subTitleColor'
  ])

  const {
    applyPhysicalCardLabel,
    verifyAddressLabel,
    verifyAddressDescription,
    shippingAddressLabel
  } = useLabels([
    'applyPhysicalCardLabel',
    'verifyAddressLabel',
    'verifyAddressDescription',
    'shippingAddressLabel'
  ])

  const { cardReceiveService, cardActivationNotice, applyPhysicalCardSuccessMessage } = useConfigs([
    'cardReceiveService',
    'cardActivationNotice',
    'applyPhysicalCardSuccessMessage',
  ])

  const onApplyClick = () => {
    const { cardId } = props.actionProps
    setLoading(true)

    applyForPhysicalCard(cardId)
      .then(({orderPhysicalCard}) => {
        setLoading(false)
        const { card } = orderPhysicalCard;
        updateCardholder(card)
        customToast.success(applyPhysicalCardSuccessMessage)
        onClose();
      })
      .catch((error) => {
        setLoading(false)
        showErrorNotification(error)
      })

  }

  return (
    <>
      <SectionHeader className="mb-4" title={applyPhysicalCardLabel} />

      <div className="mat-modal-body text-center">
        <div className="mb-5 label" style={{ color: titleColor }}>
          <p className="weight-600">{verifyAddressLabel}</p>
          <p>{verifyAddressDescription}</p>
        </div>

        <div className="mb-4">
          <p className="weight-500 label" style={{ color: titleColor }}>
            {shippingAddressLabel}
          </p>
          <div className="d-flex">
            <p
              className="ml-auto mr-auto text-left"
              style={{ color: subTitleColor }}
            >
              {address1}
              <br />
              {`${city}, ${region} ${postalCode}, ${country}`}
            </p>
          </div>
        </div>

        <p
          className="mb-2 text-xs ml-auto mr-auto width-65"
          style={{ color: titleColor }}
        >
          {cardReceiveService}
        </p>

        <p className="text-xs" style={{ color: '#B75151' }}>
          {cardActivationNotice}
        </p>
      </div>

      {loading && <Loader className="custom-loader" />}

      <FlexButtons className="layout-buttons mt-4 mb-5">
        <CustomButton
          type="outline"
          text="Cancel"
          className="transparent-background weight-500"
          onClick={onClose}
        />
        <CustomButton type="primary" text="Request" onClick={onApplyClick} />
      </FlexButtons>
    </>
  )
}

export default ApplyPhysicalModalContent
