import useStyles from '../../../hooks/use-styles'
import ContentContainer from '../content-container'
import PaperHeader from '../paper-header'

const MaterialModalHeader = (props: any) => {
  const { title, description, subtileClass, titleColor, classMain } = props
  const { borderBottomColor } = useStyles(['borderBottomColor'])

  return (
    <ContentContainer
      className={`header-br-bottom d-flex justify-between align-center ${classMain!}`}
      style={{
        borderColor: borderBottomColor
      }}
    >
      <PaperHeader title={title} subTitle={description} subtitleClass={subtileClass} titleColor={titleColor} />
      
    </ContentContainer>
  )
}

export default MaterialModalHeader