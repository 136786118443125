import { useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { changePassword } from '../../../../apis/auth_api'
import useConfigs from '../../../../hooks/use-config'
import { showErrorNotification } from '../../../../utils/common-utils'
import customToast from '../../../shared/services/toaster-service'
import ResetPasswordForm from './ResetPasswordForm'

interface ResetPasswordContentProps {
  onClose: () => void
}

const ResetPasswordContent = (props: ResetPasswordContentProps) => {
  const { onClose } = props

  const [loading, setLoading] = useState(false)

  const { activeUser } = useSelector((state: RootStateOrAny) => state.users)

  const { resetPasswordSuccessMessage } = useConfigs([
    'resetPasswordSuccessMessage'
  ])

  const onResetPassword = (formData: any) => {
    const { oldPassword: old_password, password: new_password } = formData

    setLoading(true)

    changePassword({
      old_password,
      new_password,
      token: activeUser.access_token
    })
      .then(() => {
        setLoading(false)
        customToast.success(resetPasswordSuccessMessage)
        onClose();
      })
      .catch((error) => {
        setLoading(false)
        showErrorNotification(error)
      })
  }

  return (
    <ResetPasswordForm
      onClose={onClose}
      onFormSubmit={onResetPassword}
      loading={loading}
    />
  )
}

export default ResetPasswordContent
