import { Grid } from '@mui/material'
import moment from 'moment'
import { Fragment, useState } from 'react'
import { fieldValidator } from '../../../../utils/validation'

import CustomButton from '../../../../components/custom-button'
import FlexButtons from '../../../../components/flex-button'
import FormField from '../../../../components/form/form-field'
import PasswordFields from '../../../../components/form/password-fields'
import Loader from '../../../../components/shared/loader/loader'
import { PreviewContent } from '../../../../components/signup-form/components/detailsPreview/preview-content'
import SectionHeader from '../../../../components/signup-form/header/section-header'
import useLabels from '../../../../hooks/use-labels'
import useStyles from '../../../../hooks/use-styles'
import { formatPhoneNumber } from '../../../../utils/common-utils'

import { personalAddressFields, setPasswordFields } from '../../../../utils/fields.utils'
import { errorMessage } from '../../../../utils/validation'
import useConfigs from '../../../../hooks/use-config'
import FormButtons from '../../../../components/signup-form/form-buttons'

const SetAddressView = (props: any) => {

  const { onFormSubmit, formData } = props
  const [fieldValues, setFieldValues] = useState({
    address: formData.address || '',
    address2: formData.address2 || '',
    city: formData.city || '',
    region: formData.region || '',
    postalCode: formData.postalCode || '',
    country: 'USA',
    addressType: 'PRIMARY'
  }) as any


  const [fieldErrors, setFieldErrors] = useState({}) as any
  const {
    personalInformationTitle,
    personalAddressDescription,
    addressLabel,
    address2,
    cityLabel,
    stateLabel,
    zipCodeLabel,
    countryLabel
  } = useLabels([
    'personalInformationTitle',
    'personalAddressDescription',
    'addressLabel',
    'address2',
    'cityLabel',
    'stateLabel',
    'zipCodeLabel',
    'countryLabel'
  ])

  const { states, addressLength, cityLength } = useConfigs([
    'states',
    'addressLength',
    'cityLength'
  ])

  const fieldProps = {
    addressLabel,
    address2,
    cityLabel,
    stateLabel,
    zipCodeLabel,
    countryLabel,
    states,
    addressLength,
    cityLength
  }
  const validate = (key: string, value: string, options?: any) => {
    let errors = { ...fieldErrors }
    const keyValidator = fieldValidator[key]
    if (!value.trim() && options.required) {
      errors[key] = errorMessage.required
    } else if (key === 'postalCode' && !keyValidator(value)) {
      errors[key] = errorMessage.postalCode
    } else if (key === 'postalCode' && value.length < 5) {
      errors[key] = errorMessage.postalCodeLength
    } else if (keyValidator && !keyValidator(value)) {
      errors[key] = errorMessage[key]
    } else {
      delete errors[key]
    }

    setFieldErrors(errors)
  }


  const [errors, setErrors] = useState({})

  const { profile, email } = props.formData

  const {
    first_name,
    last_name,
    date_of_birth,
    phone_details,
    token,
    identification_docs,
    citizenship_country_code
  } = JSON.parse(profile)

  const { country_dialing_code, phone_number } = phone_details[0]
  const { identification_value } = identification_docs[0]

  const {
    SetPasswordTitle,
    setPasswordDescription,
    passwordLabel,
    confirmPasswordLabel,
    submitButtonText
  } = useLabels([
    'SetPasswordTitle',
    'setPasswordDescription',
    'passwordLabel',
    'confirmPasswordLabel',
    'submitButtonText'
  ])

  const { titleColor } = useStyles(['titleColor'])

  const item = {
    content: [
      {
        label: 'First Name',
        value: first_name
      },
      {
        label: 'Last Name',
        value: last_name
      },
      {
        label: 'Email',
        value: email
      },
      {
        label: 'Mobile',
        value: formatPhoneNumber(phone_number, country_dialing_code)
      },
      {
        label: 'Date of Birth',
        value: moment(date_of_birth).format('MM / DD / YYYY')
      },

      {
        label: 'SSN',
        value: `***-**-${identification_value.substring(
          identification_value.length - 4
        )}`
      }
    ]
  }
  const onFieldChange = (key: string, value: string, options?: any) => {
    validate(key, value, options)

    setFieldValues((prev: any) => ({ ...prev, [key]: value }))
  }

  const validFields = () => {
    const errors = { ...fieldErrors }
    const requiredFields = [
      'address',
      'country',
      'city',
      'region',
      'postalCode'
    ]
    let valid = true
    requiredFields.forEach((field) => {
      if (field === 'address') {
        const regex = /(P\.O\.|APO|FPO)/i;
        const containsPOorAPOorFPO = regex.test(fieldValues['address']);
        if (containsPOorAPOorFPO) {
          errors[field] = errorMessage.streetAddresss;
          valid = false
        }
      }
      if (!fieldValues[field]) {
        errors[field] = errorMessage.required
        valid = false
      }
    })

    setFieldErrors(errors)
    return valid
  }




  const onLoginClick = () => {
    if (validFields()) {
      props.onFormSubmit({
        address: fieldValues.address,
        address2: fieldValues.address2,
        city: fieldValues.city,
        region: fieldValues.region,
        postalCode: fieldValues.postalCode,
        country: fieldValues.country,
        addressType: fieldValues.addressType,
      })
    }
  }



  return (
    <div>
      <SectionHeader
        title={"Add Shipping Address"}
        subTitle={"Please add where you like to ship the card"}
        subTitleColor={titleColor}
      />


      <Grid container rowSpacing={3} columnSpacing={8} className="mb-3">
        {item.content.map((contentItem) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            className="content-columns text-left"
            key={contentItem.label}
          >
            <PreviewContent
              label={contentItem.label}
              labelClass="weight-600"
              value={contentItem.value}
              labelColor={titleColor}
              textColor={titleColor}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {personalAddressFields(fieldProps).map((fieldItem) => {
          const { name, required } = fieldItem.elementProps

          return (
            <Fragment key={name}>
              {!fieldItem.multiple ? (
                <Grid item sm={12} xs={12} key={name}>
                  <FormField
                    {...fieldItem}
                    value={fieldValues[name]}
                    onChange={(name: string, value: string) =>
                      onFieldChange(name, value, { required })
                    }
                    error={fieldErrors[name]}
                  />
                </Grid>
              ) : (
                fieldItem.nestedFields.map((nestedItem) => {
                  const { name, required } = nestedItem.elementProps
                  return (
                    <Grid item sm={6} xs={12} key={name}>
                      <FormField
                        {...nestedItem}
                        value={fieldValues[name]}
                        onChange={(name: string, value: string) =>
                          onFieldChange(name, value, { required })
                        }
                        error={fieldErrors[name]}
                      />
                    </Grid>
                  )
                })
              )}
            </Fragment>
          )
        })}
      </Grid>





      {props.loading && <Loader className="custom-loader" />}
      <FlexButtons className="mt-5 mb-3 layout-buttons">

        <FormButtons
          type="primary"
          handleNext={onLoginClick}
          disableNext={Object.keys(fieldErrors).length}
        />
      </FlexButtons>
    </div>
  )
}

export default SetAddressView