import { useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import ActivateCardModal from '../../../../../../../components/Modal/ActivateCardModal'
import ApplyPhysicalModal from '../../../../../../../components/Modal/ApplyPhysicalModal'
import BillPayment from '../../../../../../../components/Modal/BillPaymentModal'
import CardStatusModal from '../../../../../../../components/Modal/CardStatusModal'
import ChangePinModal from '../../../../../../../components/Modal/ChangePinModal'
import DirectDepositModal from '../../../../../../../components/Modal/DirectDepositModal'
import ShipmentTrackingModal from '../../../../../../../components/Modal/ShipmentTrackingModal'
import useLabels from '../../../../../../../hooks/use-labels'
import { setCardholder } from '../../../../../../../store/user/user-reducer'
import CardDetailTable from './CardDetailTable'
import SignupModal from '../../../../../../../components/Modal/SIgnupInvitationModal'

import './table-style.css'

const GetLabels = () => {
  return useLabels([
    'lastFourLabel',
    'cardTypeLabel',
    'nameOnCardLabel',
    'stateLabel',
    'statusLabel',
    'actionLabel',
    'profileTitle',
    'myCardTitle',
    'secondaryCardLabel'
  ])
}

const initialAction = {
  actionType: '',
  actionProps: {}
}

const CardDetailList = (props: any) => {
  const { cards, updateCard } = props
  const [action, setAction] = useState(initialAction)
  const { cardholder } = useSelector((state: RootStateOrAny) => state.users)

  const dispatch = useDispatch()

  const updateCardholder = (card: any) => {
    const cardholderDetail = { ...cardholder }
    
    const cardholderCards = [...cardholderDetail.cards]

    const cardIndex = cardholder.cards.findIndex(
      (cardItem: any) => cardItem.cardId === card.cardId
    )

    cardholderCards[cardIndex] = { ...card }
    cardholderDetail.cards = [...cardholderCards]

    dispatch(setCardholder(cardholderDetail))
    updateCard(card)
  }

  const {
    cardTypeLabel,
    stateLabel,
    statusLabel,
    actionLabel,
    profileTitle,
    myCardTitle,
    secondaryCardLabel
  } = GetLabels()

  const tableHeaders = [
    cardTypeLabel,
    stateLabel,
    statusLabel,
    actionLabel,
  ]

  const onActionClick = (action: any) => {
    setAction(action)
  }

  const onModalClose = () => {
    setAction(initialAction)
  }

  const renderActionModal = () => {
    if (!action.actionType) return null

    const { actionType, actionProps } = action

    const actionModals: any = {
      payBill: (
        <BillPayment
          isOpen={true}
          // cardId={actionProps['cardId' as keyof typeof actionProps]}
          onClose={onModalClose}
          actionProps={actionProps}

        />
      ),
      changePin: (
        <ChangePinModal
          isOpen={true}
          onClose={onModalClose}
          actionProps={actionProps}
        />
      ),
      signupInvitation: (
        <SignupModal
          isOpen={true}
          onClose={onModalClose}
          cardId={actionProps['cardId' as keyof typeof actionProps]}
          
        />
      ),
      directDeposit: (
        <DirectDepositModal
          isOpen={true}
          onClose={onModalClose}
          actionProps={actionProps}
        />
      ),
      cardStatus: (
        <CardStatusModal
          isOpen={true}
          onClose={onModalClose}
          actionProps={actionProps}
          updateCardholder={updateCardholder}
        />
      ),
      applyPhysicalCard: (
        <ApplyPhysicalModal
          isOpen={true}
          onClose={onModalClose}
          actionProps={actionProps}
          updateCardholder={updateCardholder}
        />
      ),
      activateCard: (
        <ActivateCardModal
          isOpen={true}
          onClose={onModalClose}
          actionProps={actionProps}
          updateCardholder={updateCardholder}
        />
      ),
      cardShipment: (
        <ShipmentTrackingModal
          isOpen={true}
          onClose={onModalClose}
          actionProps={actionProps}
        />
      ),
      orderSecondaryCard: (
        <Navigate
          to="/cards/secondary-card"
          state={{
            cardId: actionProps['cardId' as keyof typeof actionProps],
            balance: actionProps['balance' as keyof typeof actionProps],
            pageCrumbs: [
              { title: myCardTitle, link: '/cards' },
              { title: secondaryCardLabel }
            ]
          }}
        />
      ),
      updateProfile: (
        <Navigate
          to="/update-profile"
          state={{
            secondaryCardholder: actionProps['cardholder' as keyof typeof actionProps],
            pageCrumbs: [{ title: profileTitle }]
          }}
        />
      )
    }

    return actionModals[actionType] && actionModals[actionType]
  }

  return (
    <>
      {renderActionModal()}
      <CardDetailTable
        tableHeaders={tableHeaders}
        rows={cards}
        onActionClick={onActionClick}
      />
    </>
  )
}

export default CardDetailList
