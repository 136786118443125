import { Grid } from '@mui/material'
import useConfigs from '../../../hooks/use-config'
import { errorMessage, fieldValidator } from '../../../utils/validation'
import FormField from '../form-field'

const PasswordFields = (props: any) => {
  const { fields,  formValues ,setFormValues, errors, setErrors } = props

  const { strongPasswordOptions } = useConfigs(['passwordOptions'])

  const validate = (key: string, value: string, options?: any) => {
    let fieldErrors = { ...errors }
    const { password, confirmPassword } = formValues

    if (!value.trim() && options.required) {
      fieldErrors[key] = errorMessage.required
    } else if (value.length < 8) {
      fieldErrors[key] = errorMessage.shortPassword
    } else if (!fieldValidator.strongPassword(value, strongPasswordOptions)) {
      fieldErrors[key] = errorMessage.weakPassword
    } else if (
      key === 'password' &&
      !fieldValidator.matchPassword(value, confirmPassword)
    ) {
      fieldErrors[key] = errorMessage.mismatchPassword
    } else if (
      key === 'confirmPassword' &&
      !fieldValidator.matchPassword(value, password)
    ) {
      fieldErrors[key] = errorMessage.mismatchPassword
    } else if (formValues.password && formValues.confirmPassword) {
      fieldErrors = {}
    } else {
      delete fieldErrors[key]
    }

    setErrors(fieldErrors)
  }

  const onFieldChange = (key: string, value: string, options?: any) => {
    validate(key, value, options)
    setFormValues((prev: any) => ({ ...prev, [key]: value }))
  }


  return (
    <>
      {fields.map((fieldItem: any) => {
        const { elementProps } = fieldItem
        const { required } = elementProps
        return (
          <Grid item md={12} key={elementProps.name}>
            <FormField
              {...fieldItem}
              value={formValues[elementProps.name]}
              onChange={(name: string, value: string) =>
                onFieldChange(name, value, { required })
              }
              error={errors[elementProps.name]}
            />
          </Grid>
        )
      })}
    </>
  )
}

export default PasswordFields;