import React from 'react'
import useStyles from '../../../hooks/use-styles'
import './style.header.css'

interface SectionHeaderProps {
  title?: string
  titleColor?: string
  subTitle?: string
  subTitleColor?: string
  className?: string
  subTitleClass?: string
  titleClass?: string
}
const SectionHeader = (props: SectionHeaderProps): JSX.Element => {
  const { title, subTitle, className, subTitleClass, titleClass } = props
  const { titleColor, subTitleColor } = useStyles([
    'titleColor',
    'subTitleColor'
  ])

  return (
    <div className={`header mb-5 ${className!}`}>
      {title && (
        <p
          className={`section-title title weight-500 text-center ${titleClass!}`}
          style={{ color: titleColor }}
        >
          {title}
        </p>
      )}

      {subTitle && (
        <p
          className={`text-sm text-center ${subTitleClass!}`}
          style={{ color: props.subTitleColor || subTitleColor }}
        >
          {subTitle}
        </p>
      )}
    </div>
  )
}

export default SectionHeader
