import { Grid } from "@mui/material";
import React, { useState } from "react";
import useLabels from "../../hooks/use-labels";
import { errorMessage } from "../../utils/validation";
import SectionHeader from "./header/section-header";
import Loader from "../shared/loader/loader";
import FormButtons from "./form-buttons";
import FormField from "../form/form-field";
import SignupError from "./components/errorPage";
import SuccessPage from "./components/successPage";
import { submitOowAnswers } from "../../apis/b2c_api";
import { showErrorNotification } from "../../utils/common-utils";
import OwwQuestionModal from "../Modal/OwwQuestion-modal";

interface AnswerState {
  [key: string]: string;
}

const OwwQuestions = (props: any) => {
  const { pendingOutOfWallet, userDetail } = props;
  const { email, firstName, lastName, confirmationToken, password } = userDetail;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isKycFailed, setIsKycFailed] = useState(false);
  const [isKycPassed, setIsKycPassed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({}) as any;
  const { outOfWalletHeading, outOfWalletDescription, outOfWalletSubtitle } =
    useLabels([
      "outOfWalletHeading",
      "outOfWalletDescription",
      "outOfWalletSubtitle",
    ]);

  const oowQuestions = pendingOutOfWallet?.oowQuestions;
  const [answers, setAnswers] = useState<AnswerState>({});

  const handleSelectChange = (questionId: any, answer: any) => {
    setFieldErrors((prevErrors: any) => ({
      ...prevErrors,
      [`question_${questionId}`]: "",
    }));
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [`question_${questionId}`]: answer,
    }));
    const isAnswerMissing = !answer;
    setFieldErrors((prevErrors: any) => ({
      ...prevErrors,
      [`question_${questionId}`]: isAnswerMissing ? errorMessage.required : "",
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const questionsLength = oowQuestions.length;
    const answersLength = Object.keys(answers).length;
    let error = 0;

    let skipAnswers: any = {};
    oowQuestions.forEach((question: any) => {
      question.answers.forEach((answers: any) => {
        if (answers?.answer === "Skip Question") {
          skipAnswers["question_" + question.id] = answers.id.toString();
        }
      });
    });

    Object.entries(skipAnswers).forEach(([key1, value1]) => {
      Object.entries(answers).forEach(([key, value]) => {
        if (key1 === key) {
          if (value1 === value) {
            error = error + 1;
          }
        }
      });
    });
    

    if (answersLength === 0 || answersLength < questionsLength - 1) {
      toggleModal();
      return;
    } else if (answersLength === questionsLength - 1) {
      if (error === 0 || error === 1) {
        toggleModal();
      }
    } else {
      if (error === 0 || error > 1) {
        toggleModal();
      } else if (error === 1) {
        const formData = {
          cardholderId: pendingOutOfWallet.cardholderId,
          email,
          emailConfirmationToken: confirmationToken,
          firstName,
          lastName,
          oowAnswers:
            oowQuestions?.map((question: { id: any }) => ({
              answerId: answers[`question_${question.id}`],
              questionId: question.id.toString(),
            })) || [],
            password,
        };

        submitOowAnswersAPI(formData);
      }
    }
  };

  const submitOowAnswersAPI = (formData: any) => {
    setLoading(true);
    submitOowAnswers(formData)
      .then(({ submitOowAnswers }: any) => {
        if (!!submitOowAnswers) {
          setLoading(false);
          if (submitOowAnswers?.verified) {
            setIsKycPassed(true);
          }
        }
      })
      .catch((error: any) => {
        setLoading(false);
        setIsKycFailed(true);
        showErrorNotification(error);
      });
  };

  const toggleModal = () => {
    setShowModal((previousValue) => !previousValue);
  };

  const renderOwwModal = () => {
    return <OwwQuestionModal isOpen={showModal} onClose={toggleModal} />;
  };

  return (
    <>
      {renderOwwModal()}

      {isKycFailed && <SignupError />}
      {!isKycFailed && !isKycPassed && (
        <>
          <SectionHeader
            className="mb-0"
            subTitleClass="mt-3 text-dark"
            title={outOfWalletHeading}
            subTitle={outOfWalletDescription}
          />
          <SectionHeader subTitle={outOfWalletSubtitle} />
          <Grid item md={12}>
            {pendingOutOfWallet?.kycStatus === "PENDING_OOW_QUESTIONS" && (
              <form onSubmit={handleSubmit}>
                {pendingOutOfWallet.oowQuestions.map(
                  (question: {
                    id: React.Key | null | undefined;
                    question: any;
                    answers: any[];
                  }) => (
                    <div key={question.id}>
                      <FormField
                        className="pt-2 pl-1 weight-200"
                        type="select"
                        label={question.question}
                        elementProps={{
                          name: `question_${question.id}`,
                          options: question.answers.map((answer) => ({
                            value: answer.id.toString(),
                            label: answer.answer,
                          })),
                          valueKey: "value",
                          nameKey: "label",
                          label: `${question.question}`,
                          required: true,
                        }}
                        onChange={(name: any, value: string) =>
                          handleSelectChange(question.id, value)
                        }
                        error={error}
                      />
                    </div>
                  )
                )}
              </form>
            )}
          </Grid>
          {loading && <Loader className="custom-loader" />}
          <FormButtons
            handleNext={handleSubmit}
            nextButtonText={"Submit"}
            customClass={"mt-4"}
          />
        </>
      )}
      {isKycPassed && <SuccessPage />}
    </>
  );
};

export default OwwQuestions;
