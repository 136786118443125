import { FC, useState } from 'react'

import IcActivate from '../../../../assets/ic_activate.svg'

import useStyles from '../../../../hooks/use-styles'
import { showErrorNotification } from '../../../../utils/common-utils'
import useLabels from '../../../../hooks/use-labels'
import customToast from '../../../shared/services/toaster-service'
import ConfirmationModal from '../../ConfirmationModal'
import useConfigs from '../../../../hooks/use-config'
import { activatePhysicalCard, getShipmentInfo } from '../../../../apis/b2c_api'

interface ActivateCardModalContentProps {
  onClose: () => void
  actionProps: any
  updateCardholder: any
}

const ActivateCardModalContent: FC<ActivateCardModalContentProps> = ({
  onClose,
  actionProps,
  updateCardholder
}) => {
  const [loading, setLoading] = useState(false)
  const { titleColor } = useStyles(['titleColor'])
  const {
    confirmActivationTitle,
    cardActivationconfirmationTitle,
    activateLabel,
    cancelLabel
  } = useLabels([
    'confirmActivationTitle',
    'cardActivationconfirmationTitle',
    'activateLabel',
    'cancelLabel'
  ])

  const { activateSuccessMessage } = useConfigs(['activateSuccessMessage'])

  const onConfirm = () => {
    const { cardId } = actionProps

    setLoading(true)
    getShipmentInfo(cardId)
      .then(({ card: { shipmentInfo } }) => {
        if (shipmentInfo) {
          return activatePhysicalCard(cardId).then(
            ({ activatePhysicalCard }) => {
              setLoading(false)
              customToast.success(activateSuccessMessage)
              updateCardholder(activatePhysicalCard.card)
              onClose()
            }
          )
        } else {
          return Promise.reject({ error_code: 'CARD_NOT_DELIVERED' })
        }
      })
      .catch((error) => {
        setLoading(false)
        showErrorNotification(error)
      })
  }

  const modalProps = {
    confirmationImage: IcActivate,
    confirmationTitle: confirmActivationTitle,
    confirmationText: cardActivationconfirmationTitle,
    closeLabel: cancelLabel,
    confirmButtonText: activateLabel,
    onClose,
    titleColor,
    onConfirm,
    loading
  }

  return <ConfirmationModal modalProps={modalProps} />
}

export default ActivateCardModalContent
