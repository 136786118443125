import { Paper } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'

import useLabels from '../../../../../hooks/use-labels'
import useStyles from '../../../../../hooks/use-styles'
import { formatCurrency, padLastFour } from '../../../../../utils/common-utils'
import CustomButton from '../../../../custom-button'
import FlexButtons from '../../../../flex-button'

const TransactionReceipt = (props: any) => {
  const { recipient, account, amount, onClose, makeAnother   ,SecondaryTransfer,  secondaryAccount } = props
  const { currencyCode, availableBalance } = account.balance

  const {
    transferFromLabel,
    transferToLabel,
    transferAmountLabel,
    transferDateLabel,
    transferSuccessLabel,
    makeAnotherLabel,
    remainingAmountLabel,
    doneLabel,
    successfulTransferFundText
  } = useLabels([
    'transferFromLabel',
    'transferToLabel',
    'transferAmountLabel',
    'transferDateLabel',
    'transferSuccessLabel',
    'makeAnotherLabel',
    'remainingAmountLabel',
    'doneLabel',
    'successfulTransferFundText'
  ])

  const { labelColor, listBorderColor, lightTitleColor } = useStyles([
    'labelColor',
    'listBorderColor',
    'lightTitleColor'
  ])

  const recieptPreview = [
    {
      label: transferFromLabel,
      content: `${account.nameOnCard} (${padLastFour(account.lastFour)})`
    },
    {
      label: transferToLabel,
      content: SecondaryTransfer ? `${secondaryAccount.nameOnCard} (${padLastFour(secondaryAccount.lastFour)})`: recipient.nickName
    },
    {
      label: transferAmountLabel,
      content: formatCurrency(+amount, currencyCode)
    },
    {
      label: remainingAmountLabel,
      content: formatCurrency(
        _.round(availableBalance - +amount, 2),
        currencyCode
      )
    },
    {
      label: transferDateLabel,
      content: moment().format('MM/DD/YYYY')
    }
  ]

  return (
    <div>
      <p className="weight-600 text-2sm text-center" style={{ color: labelColor }}>
        {transferSuccessLabel}
      </p>
      <p className="weight-400 text-center text-2sm" style={{ color: labelColor }}>
        {successfulTransferFundText}
      </p>

      <Paper
        className="text-sm mt-3 mr-auto ml-auto"
        variant="outlined"
        elevation={4}
        sx={{
          p: '1rem 2rem',
          boxShadow: '0 4px 16px 0 rgba(0,0,0,0.04)',
          width: 330,
          minHeight: 230,
          borderRadius: '8px',
          border: 'unset'
        }}
      >
        {recieptPreview.map((item, index) => (
          <div
            className={`pb-2 ${index > 0 && 'mt-2'}`}
            key={index}
            style={{
              borderBottom:
                index < recieptPreview.length - 1
                  ? `1px solid ${listBorderColor}`
                  : 'unset'
            }}
          >
            <div className="text-left">
              <p className="text-sm weight-600" style={{ color: lightTitleColor }}>
                {item.label}
              </p>
              <p className="text-sm weight-400" style={{ color: labelColor }}>
                {item.content}
              </p>

          </div>
        </div>
))}
        
      </Paper>

      <FlexButtons className="mt-5 mb-5 layout-buttons">
        <CustomButton
          className="weight-500 transparent-background"
          type="previous"
          text={makeAnotherLabel}
          onClick={makeAnother}
          style={{ width: '10rem' }}
        />
        <CustomButton
          type="primary"
          text={doneLabel}
          style={{ width: '10rem' }}
          onClick={onClose}
        />
      </FlexButtons>
    </div>
  )
}
export default TransactionReceipt
