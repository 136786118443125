import { Grid } from '@mui/material'
import { Fragment, useState } from 'react'
import useConfigs from '../../../../hooks/use-config'
import useLabels from '../../../../hooks/use-labels'
import { personalAddressFields } from '../../../../utils/fields.utils'
import { errorMessage, fieldValidator } from '../../../../utils/validation'
import FormField from '../../../form/form-field'
import FormButtons from '../../form-buttons'
import SectionHeader from '../../header/section-header'

const PersonalAddressInputs = (props: any) => {
  const { onFormSubmit, formData } = props
  const [fieldValues, setFieldValues] = useState({
    address: formData.address || '',
    address2: formData.address2 || '',
    city: formData.city || '',
    region: formData.region || '',
    postalCode: formData.postalCode || '',
    country: 'USA',
    addressType: 'PRIMARY'
  }) as any

  const [fieldErrors, setFieldErrors] = useState({}) as any

  const {
    personalInformationTitle,
    personalAddressDescription,
    addressLabel,
    address2,
    cityLabel,
    stateLabel,
    zipCodeLabel,
    countryLabel
  } = useLabels([
    'personalInformationTitle',
    'personalAddressDescription',
    'addressLabel',
    'address2',
    'cityLabel',
    'stateLabel',
    'zipCodeLabel',
    'countryLabel'
  ])

  const { states, addressLength, cityLength } = useConfigs([
    'states',
    'addressLength',
    'cityLength'
  ])

  const fieldProps = {
    addressLabel,
    address2,
    cityLabel,
    stateLabel,
    zipCodeLabel,
    countryLabel,
    states,
    addressLength,
    cityLength
  }

  const validate = (key: string, value: string, options?: any) => {
    let errors = { ...fieldErrors }
    const keyValidator = fieldValidator[key]
    if (!value.trim() && options.required) {
      errors[key] = errorMessage.required
    }
   
    if (key === 'postalCode' && !keyValidator(value)) {
      errors[key] = errorMessage.postalCode
    } else if (key === 'postalCode' && value.length < 5) {
      errors[key] = errorMessage.postalCodeLength
    } else if (keyValidator && !keyValidator(value)) {
      errors[key] = errorMessage[key]
    } else {
      delete errors[key]
    }

    setFieldErrors(errors)
  }

  const onFieldChange = (key: string, value: string, options?: any) => {
    validate(key, value, options)


    setFieldValues((prev: any) => ({ ...prev, [key]: value }))
  }

  const validFields = () => {
    const errors = { ...fieldErrors }
    const requiredFields = [
      'address',
      'country',
      'city',
      'region',
      'postalCode'
    ]
    let valid = true
    requiredFields.forEach((field) => {
      if (field === 'address') {
        const regex = /(P\.O\.|APO|FPO)/i;
        const containsPOorAPOorFPO = regex.test(fieldValues['address']);
        if (containsPOorAPOorFPO) {
          errors[field] = errorMessage.streetAddresss;
          valid = false
        }
      }

      if (!fieldValues[field]) {
        valid = false
        errors[field] = errorMessage.required
        
      }
    })

    setFieldErrors(errors)


    return valid
  }

  const onSubmit = () => {
    if (validFields()) {
      onFormSubmit({ ...fieldValues, gender: '' })
    }
  }
  return (
    <>
      <SectionHeader
        title={personalInformationTitle}
        subTitle={personalAddressDescription}
      />

      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {personalAddressFields(fieldProps).map((fieldItem) => {
          const { name, required } = fieldItem.elementProps

          return (
            <Fragment key={name}>
              {!fieldItem.multiple ? (
                <Grid item sm={12} xs={12} key={name}>
                  <FormField
                    {...fieldItem}
                    value={fieldValues[name]}
                    onChange={(name: string, value: string) =>
                      onFieldChange(name, value, { required })
                    }
                    error={fieldErrors[name]}
                  />
                </Grid>
              ) : (
                fieldItem.nestedFields.map((nestedItem) => {
                  const { name, required } = nestedItem.elementProps
                  return (
                    <Grid item sm={6} xs={12} key={name}>
                      <FormField
                        {...nestedItem}
                        value={fieldValues[name]}
                        onChange={(name: string, value: string) =>
                          onFieldChange(name, value, { required })
                        }
                        error={fieldErrors[name]}
                      />
                    </Grid>
                  )
                })
              )}
            </Fragment>
          )
        })}
      </Grid>

      <FormButtons
        handleNext={onSubmit}
        handlePrevious={props.handlePrevious}
        disableNext={Object.keys(fieldErrors).length}
        customClass={'mt-4'}
      />
    </>
  )
}

export default PersonalAddressInputs
