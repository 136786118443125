import { FC } from 'react'
import { Box, BoxProps } from '@mui/material'

const MaterialBox: FC<BoxProps> = (props) => {

  return (
    <Box
      className={`material-box ${props.className}`}
      sx={{ ...props.sx, bgcolor: "#fff" }}
    >
      {props.children}
    </Box>
  )
}

export default MaterialBox
