import { useState } from 'react'
import { sendCodeOnEmail } from '../../../../../apis/auth_api'
import useConfigs from '../../../../../hooks/use-config'
import useLabels from '../../../../../hooks/use-labels'
import useStyles from '../../../../../hooks/use-styles'
import { showErrorNotification } from '../../../../../utils/common-utils'
import { errorMessage, fieldValidator } from '../../../../../utils/validation'
import CustomButton from '../../../../custom-button'
import FlexButtons from '../../../../flex-button'
import FormField from '../../../../form/form-field'
import Loader from '../../../../shared/loader/loader'
import SectionHeader from '../../../../signup-form/header/section-header'

interface RegisteredEmailProps {
  formData: any
  onFormSubmit: (values: any) => void
  onCancel: () => void
}

const RegisteredEmail = (props: RegisteredEmailProps) => {
  const { formData, onFormSubmit, onCancel } = props

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(formData.email || '')
  const [error, setError] = useState('')

  const { resetPasswordReasonType } = useConfigs(['resetPasswordReasonType'])

  const {
    forgotPasswordLabel,
    registeredEmailTitle,
    registeredEmailLabel,
    registeredEmailDescription,
    registeredEmailPlaceholder,
    cancelLabel,
    continueLabel
  } = useLabels([
    'forgotPasswordLabel',
    'registeredEmailTitle',
    'registeredEmailLabel',
    'registeredEmailDescription',
    'registeredEmailPlaceholder',
    'cancelLabel',
    'continueLabel'
  ])

  const { titleColor } = useStyles(['titleColor'])

  const onFieldChange = (key: string, value: string, options?: any) => {
    if (!value.trim() && options.required) {
      setError(errorMessage.required)
    } else if (!fieldValidator.email(value)) {
      setError(errorMessage[key])
    } else {
      setError('')
    }
    setEmail(value)
  }

  const sendCode = () => {
    setLoading(true)
    sendCodeOnEmail(email, resetPasswordReasonType)
      .then(() => onFormSubmit({ email }))
      .catch((error) => {
        setLoading(false)
        showErrorNotification(error)
      })
  }

  const handleNext = () => {
    if (!email) {
      setError(errorMessage.required)
    } else {
      sendCode()
    }
  }

  return (
    <>
      <SectionHeader title={forgotPasswordLabel} />

      <div className="mb-5 text-center" style={{color:titleColor}}>
        <p className="weight-500 text-sm">{registeredEmailTitle}</p>
        <p className="text-sm">{registeredEmailDescription}</p>
      </div>

      <FormField
        type="text"
        className='pb-3 pl-2 pr-2'
        elementProps={{
          label: registeredEmailLabel,
          name: 'email',
          value: email,
          placeholder: registeredEmailPlaceholder,
          required: true
        }}
        onChange={(name: string, value: string) =>
          onFieldChange(name, value, { required: true })
        }
        error={error}
      />

      {loading && <Loader className='custom-loader'/>}

      <FlexButtons className="layout-buttons mb-5 pt-5">
        <CustomButton
          type="outline"
          className="transparent-background weight-500"
          text={cancelLabel}
          onClick={onCancel}
        />

        <CustomButton
          type="next"
          className="weight-500"
          text={continueLabel}
          onClick={handleNext}
        />
      </FlexButtons>
    </>
  )
}

export default RegisteredEmail
