import { FC, useState } from 'react'

import { changeCardStatus } from '../../../../apis/b2c_api'

import IcBlock from '../../../../assets/ic_block.svg'
import IcActivate from '../../../../assets/ic_activate.svg'

import useStyles from '../../../../hooks/use-styles'
import { showErrorNotification } from '../../../../utils/common-utils'
import useLabels from '../../../../hooks/use-labels'
import customToast from '../../../shared/services/toaster-service'
import ConfirmationModal from '../../ConfirmationModal'
import useConfigs from '../../../../hooks/use-config'

interface CardStatusModalContentProps {
  onClose: () => void
  actionProps: any
  updateCardholder: any
}

const CardStatusModalContent: FC<CardStatusModalContentProps> = ({
  onClose,
  actionProps,
  updateCardholder
}) => {
  const [loading, setLoading] = useState(false)
  const { titleColor } = useStyles(['titleColor'])
  const {
    confirmBlockTitle,
    confirmUnblockTitle,
    cardStatusconfirmationTitle,
    blockLabel,
    unblockLabel,
    cancelLabel
  } = useLabels([
    'confirmBlockTitle',
    'confirmUnblockTitle',
    'cardStatusconfirmationTitle',
    'blockLabel',
    'unblockLabel',
    'cancelLabel'
  ])

  const { blockSuccessMessage, unblockSuccessMessage } = useConfigs([
    'blockSuccessMessage',
    'unblockSuccessMessage'
  ])

  const { status } = actionProps
  const cardStatus = status.toLowerCase()

  const modifyStatus = () => {
    setLoading(true)
    const { cardId } = actionProps

    const status = {
      active: 'BLOCK',
      block: 'ACTIVE'
    }

    changeCardStatus(
      cardId,
      status[cardStatus as keyof typeof status],
      'For personal reasons'
    )
      .then(({ changeStatus }) => {
        const { card } = changeStatus

        customToast.success(
          card.status.toLowerCase() === 'active'
            ? unblockSuccessMessage
            : blockSuccessMessage
        )

        updateCardholder(card)
        setLoading(false)
        onClose()
      })
      .catch((error) => {
        setLoading(false)
        showErrorNotification(error)
      })
  }

  const isActive = cardStatus === 'active'
  
  const modalProps = {
    confirmationImage: isActive ? IcBlock : IcActivate,
    titleColor,
    confirmationTitle: isActive ? confirmBlockTitle : confirmUnblockTitle,
    confirmationText: cardStatusconfirmationTitle,
    closeLabel: cancelLabel,
    onClose,
    confirmButtonText: isActive ? blockLabel : unblockLabel,
    onConfirm: modifyStatus,
    loading
  }

  return <ConfirmationModal modalProps={modalProps} />
}

export default CardStatusModalContent
