import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import VerifyCode from '../../components/signup-form/components/verifyCode/verify-code'
import openCPImage from '../../assets/my529Logo.png'
import useStyles from '../../hooks/use-styles'
import useConfigs from '../../hooks/use-config'
import SetPasswordView from './components/set-password/set-password'
import SupplementaryEmailInput from './components/supplementary-email'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { showErrorNotification } from '../../utils/common-utils'
import { createAccount, DeleteAccount } from '../../apis/auth_api'
import { login } from '../../store/user/user-action'
import { logoutUser, setLoginUser } from '../../store/user/user-reducer'
import { completeSupplementarySignup } from '../../apis/b2c_api'
import customToast from '../../components/shared/services/toaster-service'
import useLabels from '../../hooks/use-labels'
import DOMPurify from 'dompurify'
import SetAddressView from './components/set-address/set-address'

const SuplementarySignupView = () => {
  const { activeUser } = useSelector((state: RootStateOrAny) => state.users)

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [step, setStep] = useState(1)
  const [formData, setFormDetails] = useState({}) as any

  const { subTitleColor, blackColor} = useStyles(['subTitleColor', 'blackColor'])
  const { cardIssuanceInformation } = useConfigs(['cardIssuanceInformation'])
  const { cardholderLinkSuccessMessage } = useLabels(['cardholderLinkSuccessMessage']);

  useEffect(() => {
    if (activeUser) {
      dispatch(logoutUser())
    }
  }, [])

  const onFormSubmit = (details: any) => {
    setFormDetails((prev: any) => ({ ...prev, ...details }))
    handleNext()
  }

  const handleNext = () => {
    setStep((prev) => prev + 1)
  }

  const handlePrevious = () => {
    setStep((prev) => prev - 1)
  }

  const createSupplementaryUser = (formValues: any) => {
    const { first_name, last_name, password} = formValues
    setLoading(true)
    const { email, confirmationToken, token,address,address2,addressType,city,postalCode,country,region  } = formData
    const SignupData = {
      addresses: 
        {
          address1: address,
          address2,
          addressType,
          postalCode,
          region,
          country,
          city
        }, token,
        emailConfirmationToken: confirmationToken,
        password
    }

  
    completeSupplementarySignup(SignupData)
      .then(() => {
        
        customToast.success(cardholderLinkSuccessMessage)
        dispatch(logoutUser())
        navigate('/login')
        setLoading(false);
      })
      .catch((error) => {
        showErrorNotification(error)
        setLoading(false);
      })
  }




  // const Login  = async (formValues: any) => {
  //   setLoading(true)

  //   const {email, password} = formValues
  //   // if (!activeUser.access_token) {
  //   //   const accountRequest = {
  //   //     email,
  //   //     password,
  //   //     first_name,
  //   //     last_name,
  //   //     email_confirmation_token: confirmationToken,
  //   //     password_reset_url: 'https://www.google.com/'
  //   //   }

  //   //   const resp = await createAccount(accountRequest)
  //   //   localStorage.setItem("auth_id", resp?.data?.auth_id)
      
  //   // }
  //   const userDetails = await login(email, password)
  //   dispatch(setLoginUser(userDetails))

  //   // Link here and return

  //   // return completeSupplementarySignup(SignupData)
  // }

  const pageProps = {
    onFormSubmit,
    formData,
    handleNext,
    handlePrevious
  }

  const componentStep = {
    1: () => <SupplementaryEmailInput {...pageProps} />,
    2: () => <VerifyCode {...pageProps} />,
    3: () => (
      <SetAddressView
        {...pageProps}
        loading={loading}
        onSubmit={createSupplementaryUser}
      />
    ),
    4:()=>     <SetPasswordView
    {...pageProps}
    loading={loading}
    onSubmit={createSupplementaryUser}
  />
  }

  return (
    <div className="d-flex-center signup-container">
      <div style={{ flexGrow: 1 }} className="pt-5 pb-5 d-flex-center">
        <img className="header-img" src={openCPImage} alt="headerImg" />
        <Grid
          container
          direction="row"
          justifyContent={'center'}
          className="steps-container pt-4 pb-4 mt-4 text-center"
        >
          {componentStep[step as keyof typeof componentStep]()}
        </Grid>
      </div>

      <p className="signup-footer pt-5 pb-3" style={{ color: blackColor }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(cardIssuanceInformation, { ADD_ATTR: ['target'] }),
        }}
      >
      </p>
    </div>
  )
}

export default SuplementarySignupView
