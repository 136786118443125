import { FC } from 'react'

interface Option {
  name: string
  value: string
}

interface CustomSelectProps {
  options: Array<Option>
  className?: string
  onSelect: (pageLimit: string) => void
  value: string
}

const CustomSelect: FC<CustomSelectProps> = ({
  options,
  className,
  onSelect,
  value
}) => {
  return (
    <select
      className={`pointer p-1 ${className!}`}
      onChange={({ target }) => onSelect(target.value)}
      value={value}
    >
      {options.map(({ name, value }) => (
        <option key={name} value={value}>
          {name}
        </option>
      ))}
    </select>
  )
}
export default CustomSelect
