import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import "./dashboard.style.css";
import { useDispatch } from "react-redux";
import { getCardholder } from "../../apis/b2c_api";
import { showErrorNotification } from "../../utils/common-utils";
import Loader from "../../components/shared/loader/loader";
import { logoutUser, setCardholder } from "../../store/user/user-reducer";
import useConfigs from "../../hooks/use-config";
import { useNavigate } from "react-router";

const DashboardView = (props: { children?: JSX.Element }) => {
  const navigate = useNavigate();
  const collapsed = window.innerWidth < 769 || window.outerWidth < 769;
  const [isCollapsed, setCollapsed] = useState(collapsed);
  const [loading, setLoading] = useState(true);
  const { copyrightText } = useConfigs(["copyrightText"]);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      const collapsed = window.innerWidth < 769 || window.outerWidth < 769;
      setCollapsed(collapsed);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const setCardholderStatus = (cardholder: any) => {
    const { cards } = cardholder;
    if (!cards.length) {
      showErrorNotification(
        "We are unable to verify your identity and cannot approve your application at this time."
      );
      dispatch(logoutUser());
      navigate(`/`);
    } else {
      dispatch(setCardholder(cardholder));
    }
  };

  useEffect(() => {
    getCardholder()
      .then(({ cardholder }) => {
        setCardholderStatus(cardholder);
        setLoading(false);
      })
      .catch(showErrorNotification);
  }, [dispatch]);

  if (loading) return <Loader />;

  return (
    <div className="dashboard-col">
      <Sidebar collapsed={isCollapsed} />

      <div className={`content-${isCollapsed ? "collapse" : "expand"}`}>
        <TopBar onToggle={() => setCollapsed((prev): boolean => !prev)} />
        <div className="dashboard-container pt-3">
          <div>{props.children && props.children}</div>
          <p className="text-right mt-5 text-sm"> {copyrightText}</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardView;
