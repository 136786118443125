import { FC, useEffect, useState } from 'react'

import Loader from '../../../components/shared/loader/loader'
import useStyles from '../../../hooks/use-styles'

import TransferFundsModal from '../../../components/Modal/TransferFundsModal'
import AddRecipientModal from '../../../components/Modal/AddRecipientModal'
import MaterialDataTable from '../../../components/materialDataTable'
import { prepareCardsData } from './recipient.utils'
import RecipientHeader from './RecipientHeader'
import useLabels from '../../../hooks/use-labels'
import { RootStateOrAny, useSelector } from 'react-redux'

const recipientColumns = ['Name', 'Type', 'Card last 4','Email', 'Action']

const Recipients: FC = (props: any) => {
  const [loading, setLoading] = useState(true)
  const { cardholder } = useSelector((state: RootStateOrAny) => state.users)
  const [recipients, setRecipients] = useState([]) as any
  const [filteredRecipients, setFilteredRecipients] = useState(null) as any
  const { cards } = cardholder;

  const [transferRecipient, setTransferRecipient] = useState(null) as any

  const { transferFundsTipText } = useLabels(['transferFundsTipText'])
  const [transfer, setTransfer] = useState(false)
  const [addRecipient, setAddRecipient] = useState(false)

  const { lightBlueColor } = useStyles(['lightBlueColor'])

  useEffect(() => {
    getRecipients()

  }, [])


  const getRecipients = () => {
    const cardProps = {
            cards,
            onDelete: () => console.log('delete'),
            onTransfer: (card: any) => toggleTransfer(card),
            iconColor: lightBlueColor,
            transferFundsTipText
          }
    const preparedRecipients = prepareCardsData(cardProps)
    setRecipients(preparedRecipients)
    setLoading(false)
   
  }

  const toggleTransfer = (recipient?: any) => {
    setTransfer((previouseValue) => !previouseValue)
    setTransferRecipient(recipient || null)
  }

  const renderTransferFundsModal = () => {
    if (!transfer) return null

    return (
      <TransferFundsModal
        isOpen={transfer}
        onClose={() => toggleTransfer()}
        recipient={transferRecipient}
        SecondaryTransfer ={true}
      />
    )
  }

  const toggleRecipient = () => {
    setAddRecipient((previouseValue) => !previouseValue)
  }

  const onAddRecipientSuccess = () => {
    toggleRecipient()
    getRecipients()
  }

  const renderAddRecipientModal = () => {
    return (
      <AddRecipientModal
        isOpen={addRecipient}
        onClose={toggleRecipient}
        onSuccess={onAddRecipientSuccess}
      />
    )
  }

  const onFilterRecipient = (filteredRecipients: any) => {
    setFilteredRecipients(filteredRecipients)
  }

  return (
    <div>
      {renderTransferFundsModal()}
      {renderAddRecipientModal()}

      <RecipientHeader
        onAddRecipient={toggleRecipient}
        onSecondaryTransfer ={toggleTransfer}
        recipients={recipients}
        setFilteredRecipients={onFilterRecipient}
      />

      {loading ? (
        <Loader className="custom-loader" />
      ) : (
        <MaterialDataTable
          rows={recipients}
          columns={recipientColumns}
          noDataText={'There are currently no recipients'}
        />
       )} 
    </div>
  )
}

export default Recipients
