import { useEffect, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SignupForm from "../components/signup-form/signup-form";
import SuplementarySignupView from "./supplementary-signup";
import LoginView from "./login";
import RefreshAuth from "./RefreshAuth";

import Recipients from "./dashboard/Recipients";

import AuthWrapper from "./AuthWrapper";
import Cards from "./dashboard/Content/Cards";
import TransactionDetail from "./dashboard/Content/Cards/CardContent/TransactionHistory/TransactionDetail";
import UserProfile from "./dashboard/UserProfile";
import SecondaryCard from "./dashboard/Content/Cards/SecondaryCard";

import SignupInvitations from "../components/Modal/SIgnupInvitationModal/SignupInvitationModalContent/SignupInvitations";
import useConfigs from "../hooks/use-config";
import { RootStateOrAny, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";
import SessionTimeoutModal from "../components/Modal/session-timeout-modal";
import TransferHistory from "./dashboard/TransferHistory/transferHistory";

const AppContainer = () => {
  const { showInvitationMenu } = useConfigs(["showInvitationMenu"]);
  const { activeUser } = useSelector((state: RootStateOrAny) => state.users);
  const [visible, setVisible] = useState(false);

  const [loggedIn, setLoggedIn] = useState(true);
  const { sessionTimeout } = useConfigs(["sessionTimeout"]);

  const sessionTime = Number(sessionTimeout);
  const checkForInactivity = () => {
    const expireTime = Number(localStorage.getItem("expireTime"));
    const loggedIn = localStorage.getItem("isLoggedIn");
    if (loggedIn === "true") {
      if (expireTime < Date.now()) {
        setLoggedIn(false);
        setVisible(true);
      }
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + sessionTime;
    localStorage.setItem("expireTime", expireTime.toString());
    if (visible) {
      setVisible(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 2000);

    return () => clearInterval(interval);
  }, []);
  
  useEffect(() => {
    updateExpireTime();

    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);
    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AuthWrapper />}>
            <Route path="/update-profile" element={<UserProfile />} />
            {showInvitationMenu && (
              <Route
                path="/invitations"
                element={<SignupInvitations cardId={activeUser.cardId} />}
              />
            )}
            <Route path="/transfer-funds" element={<Recipients />} />
            <Route path="/transfer-history" element={<TransferHistory />} />
            <Route path="/cards" element={<Cards />} />
            <Route
              path="/cards/transaction-detail"
              element={<TransactionDetail />}
            />
            <Route path="/cards/secondary-card" element={<SecondaryCard />} />
          </Route>
          <Route path="/signup" element={<SignupForm />} />

          <Route path="/login" element={<LoginView />} />
          <Route path="/complete-signup" element={<SuplementarySignupView />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <ToastContainer />

        {visible && (
          <SessionTimeoutModal
            isOpen={visible}
            onClose={() => updateExpireTime()}
            // timer={20}
          />
        )}
      </BrowserRouter>
      <RefreshAuth />
    </div>
  );
};

export default AppContainer;
