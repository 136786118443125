import { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import useLabels from '../../../hooks/use-labels'
import './dropdown.style.css'

interface CustomDropdownProps {
  onSelect: (value: string) => void
  value?: string
  placeholder?: string
  options?: any
}

export default function CustomDropdown(props: CustomDropdownProps) {
  const [isOpen, setOpen] = useState(false)

  const { fontColor } = useLabels(['fontColor'])
  const { value, placeholder, options, onSelect } = props

  return (
    <Dropdown isOpen={isOpen} toggle={() => setOpen((prev: boolean) => !prev)}>
      <DropdownToggle
        className="custom-dropdown transparent-background weight-500"
        style={{ borderColor: fontColor, color: fontColor }}
        caret
      >
        {value || placeholder}
      </DropdownToggle>
      
      <DropdownMenu className="custom-dropdown-menu">
        {options.map((option: any, index: number) => (
          <div
            className="dropdown-option"
            onClick={() => {
              setOpen(false)
              onSelect(option.value)
            }}
            key={index}
          >
            {option.name}
          </div>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}
