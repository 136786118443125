import { Grid } from '@mui/material'
import { FC } from 'react'

interface HalfGridProps {
  children: JSX.Element,
  className?:string
}

const HalfGrid: FC<HalfGridProps> = ({ children, className }) => {
  return (
    <Grid container className={className!}>
      <Grid item md={6} sm={12} xs={12}>
        {children}
      </Grid>
    </Grid>
  )
}

export default HalfGrid
