import { Avatar } from '@mui/material'
import MenuImg from '../../../assets/menu.svg'
import './topbar.style.css'
import useStyles from '../../../hooks/use-styles'
import { useLocation, useNavigate } from 'react-router'
import { RootStateOrAny, useSelector } from 'react-redux'
import MaterialCrumbs from '../../../components/material-crumbs'
import useLabels from '../../../hooks/use-labels'

interface TopBarProps {
  onToggle: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void
}

const TopBar = (props: TopBarProps) => {
  const { cardholder } = useSelector((state: RootStateOrAny) => state.users)
  const navigate = useNavigate()

  const { myCardTitle, profileTitle } = useLabels([
    'myCardTitle',
    'profileTitle'
  ])

  const { state } = useLocation()
  const { pageCrumbs = [{ title: myCardTitle }] }: any = state || {}

  const { topBarTitleColor, avatarBackgroundColor, avatarFontColor } =
    useStyles(['topBarTitleColor', 'avatarBackgroundColor', 'avatarFontColor'])

  const { firstName, lastName } = cardholder

  const routeToProfile = () => {
    navigate('/update-profile', {
      state: {
        pageCrumbs: [{ title: profileTitle }]
      }
    })
  }

  
  return (
    <div className="topbar d-flex align-center justify-between">
      <div className="d-flex gap-3 weight-400">
        <img
          src={MenuImg}
          alt="menu-img"
          className="menu-img pointer"
          onClick={props.onToggle}
        />

        <MaterialCrumbs breadCrumbs={pageCrumbs} />
      </div>

      <div
        className="user-info d-flex gap-2 align-center pointer"
        onClick={routeToProfile}
      >
        <Avatar
          className="avatar weight-500"
          sx={{
            backgroundColor: avatarBackgroundColor,
            width: 24,
            height: 24,
            color: avatarFontColor
          }}
        >
          {firstName[0]}
          {lastName[0]}
        </Avatar>
        <p
          className="weight-500 user-title text-sm"
          style={{ color: topBarTitleColor }}
        >
          {firstName} {lastName}
        </p>
      </div>
    </div>
  )
}

export default TopBar
