import useStyles from '../../../../hooks/use-styles'
import './steps-view-style.css'

const StepsView = (props: any) => {
  const { numberofSteps, className } = props
  const { grayColor, blueColor, lightBlueColor } = useStyles([
    'grayColor',
    'blueColor',
    'lightBlueColor'
  ])

  const greyStyle = {
    border: `2px solid ${grayColor}`,
    backgroundColor: grayColor
  }

  const blueStyle = {
    border: `2px solid ${blueColor}`,
    backgroundColor: blueColor
  }

  const lightBlueStyle = {
    border: `2px solid ${lightBlueColor}`,
    backgroundColor: lightBlueColor
  }

  const stepList = Array.from(Array(numberofSteps || 5).keys())
  const { listStep } = props
  const { subTitleColor } = useStyles(['subTitleColor'])

  return (
    <div className={`d-flex-center steps-view ${className!}`}>
      <ul className="step-ul d-flex">
        {stepList.map((item, index) => {
          let stepStyle = index + 1 < listStep ? blueStyle : greyStyle
          stepStyle = index + 1 === listStep ? lightBlueStyle : stepStyle

          return <li key={item} className={`step-li`} style={stepStyle} />
        })}
      </ul>
      <p
        className="text-center text-sm mt-2"
        style={{ color: subTitleColor }}
      >{`Step ${listStep}-${stepList.length}`}</p>
    </div>
  )
}

export default StepsView
