import axios from '../../utils/axios'

interface createAccountInterface {
  first_name: string
  last_name: string
  email: string
  password: string
}

export const emailAlreadyExists = async (email: string) => {
  email = email.toLowerCase();
  const { data } = await axios.get(`/identity/email/${email}/inspect`)
  return data.exist === 'true'
}

export const sendCodeOnEmail = async (email: string, reason_type: string) => {
  email = email.toLowerCase();  

  return await axios.post(`/identity/email/${email}/otp`, {
    reason_type
  })
}

export const verifyOTP = async (email: string, otp_value: string) => {
  email = email.toLowerCase();
  return await axios.get(`/identity/email/${email}/otp/${otp_value}`)
}

export const createAccount = async (apiRequest: createAccountInterface) => {
  return await axios.post(`/identity`, { ...apiRequest, reason_type: 'SU' })
}
export const DeleteAccount = async () => {
  const auth_id = localStorage.getItem("auth_id");
  return await axios.delete(`/identity/${auth_id}/delete`)
}

export const changePassword = async (apiProps: any) => {
  const { old_password, new_password, token } = apiProps
  return await axios.put(
    '/auth/password',
    { old_password, new_password },
    {
      Authorization: `Bearer ${token}`
    }
  )
}

export const resetPassword = async (apiProps: any) => {
  return await axios.post('/identity/password', { ...apiProps })
}
