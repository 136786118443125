import { Grid } from '@mui/material'
import { FC } from 'react'
import CustomButton from '../../../../../../components/custom-button'
import FlexButtons from '../../../../../../components/flex-button'
import FormField from '../../../../../../components/form/form-field'
import HalfGrid from '../../../../../../components/halfGrid'
import LinkText from '../../../../../../components/link-text'
import Loader from '../../../../../../components/shared/loader/loader'

import useLabels from '../../../../../../hooks/use-labels'
import useStyles from '../../../../../../hooks/use-styles'
import { phoneInstance } from '../../../../../../utils/common-utils'
import { reviewSecondaryFields } from '../../../../../../utils/fields.utils'
import useConfigs from '../../../../../../hooks/use-config'

const PNF = require('google-libphonenumber').PhoneNumberFormat

interface PreviewSecondaryFormProps {
  secondaryDetails: any
  onBack: () => void
  orderCard: () => void
  processing?: boolean
}

const PreviewSecondaryForm: FC<PreviewSecondaryFormProps> = ({
  secondaryDetails,
  onBack,
  orderCard,
  processing
}) => {
  const { countryDialingCode, phoneNumber, cardFee, ...restDetails } =
    secondaryDetails

  const phoneUtil = phoneInstance()

  const number = phoneUtil.parseAndKeepRawInput(phoneNumber, 'US')
  const formattedPhone = `+${countryDialingCode} ${phoneUtil.format(
    number,
    PNF.NATIONAL
  )}`


  const { labelColor, previewTextColor } = useStyles([
    'labelColor',
    'previewTextColor'
  ])

  const {
    reviewCardPageTitle,
    firstNameLabel,
    lastNameLabel,
    emailLabel,
    mobileLabel,
    dobLabel,
    cardStateLabel,
    relationLabel,
    feeLabel,
    ssnLabel,
    countryLabel
  } = useLabels([
    'reviewCardPageTitle',
    'firstNameLabel',
    'lastNameLabel',
    'emailLabel',
    'mobileLabel',
    'dobLabel',
    'cardStateLabel',
    'relationLabel',
    'feeLabel',
    'ssnLabel',
    'countryLabel'
  ])

  const fieldProps = {
    firstNameLabel,
    lastNameLabel,
    emailLabel,
    mobileLabel,
    dobLabel,
    cardStateLabel,
    relationLabel,
    ssnLabel,
    countryLabel,
    feeLabel,
    labelColor,
    textColor: previewTextColor,
    feeAmount: cardFee,
    cardState: secondaryDetails.shipPhysical ? 'Physical' : 'Virtual',
    phoneNumber: formattedPhone,
    ...restDetails
  }

  const { agreementLinks } = useConfigs(['agreementLinks'])


  return (
    <HalfGrid className="pt-2">
      <>
        <div className="mb-5">
          <p className="title weight-600">{reviewCardPageTitle}</p>
        </div>

        <Grid container columnSpacing={1}>
          {reviewSecondaryFields(fieldProps).map((item) => {
            const { md, name } = item.elementProps
            return (
              <Grid item md={md} xs={12} key={name}>
                <FormField {...item} />
              </Grid>
            )
          })}
        </Grid>

        <div>
          View the
          {agreementLinks.map((linkItem: any, index: number) => {
            return (
              <span
                key={index}
                style={{ marginRight: '2px', marginLeft: '2px' }}
              >
                {index === agreementLinks.length - 1 && 'and '}
                <LinkText {...linkItem} />
                {index < agreementLinks.length - 1 && ','}
                {index === agreementLinks.length - 1 && '.'}
              </span>
            )
          })}
        </div>

        {processing && <Loader className="custom-loader" />}
        <FlexButtons className="layout-buttons mt-5 justify-start">
          <CustomButton type="previous" text="Go Back" onClick={onBack} />
          <CustomButton
            type="primary"
            text="Save Changes"
            onClick={orderCard}
          />
        </FlexButtons>
      </>
    </HalfGrid>
  )
}

export default PreviewSecondaryForm
