import {
  capitalize,
  dateTimeFormating,
  formatPhoneNumber,
} from "../../../../../utils/common-utils";
import ResendImage from "../../../../../assets/ic_resend.svg";
import DeleteImage from "../../../../../assets/ic_trash.svg";
import MuiToolTip from "../../../../tooltip";

interface invitationDetailProps {
  signupInvitations: any;
  onResend: (token: string) => void;
  onEdit: (details: any) => void;
  onDelete: (token: string) => void;
}

export const prepareInvitationDetails = (props: invitationDetailProps) => {
  const { signupInvitations, onEdit, onResend, onDelete } = props;

  return signupInvitations.map((item: any, index: number) => {
    const { profile, email, token, status, expiryTime } = item;
    const { first_name, last_name, phone_details } = JSON.parse(profile);

    const { phone_number, country_dialing_code } = phone_details[0];
    const expiry = dateTimeFormating(expiryTime, 'MMM DD YYYY, h:mm:ss a'); 
    

    return {
      serial: index + 1,
      email,
      name: `${first_name}  ${last_name}`,
      phoneNumber: formatPhoneNumber(phone_number, country_dialing_code),
      status: capitalize(status),
      expiry,
      actions: (
        <p>
          <MuiToolTip key={index} title="Resend Invitation">
            <img
              className="pointer ml-1 mr-1"
              key="Resend Invitation"
              src={ResendImage}
              alt="Resend Invitation"
              width={22}
              height={22}
              onClick={() => onResend(token)}
            />
          </MuiToolTip>

          <MuiToolTip key={index} title="Delete Invitation">
            <img
              className="pointer ml-1 mr-1"
              key="Delete Invitation"
              src={DeleteImage}
              alt="Delete Invitation"
              width={22}
              height={22}
              onClick={() => onDelete(token)}
            />
          </MuiToolTip>
          {/* <span onClick={() => onEdit(item)}>
            Edit
          </span> */}

        </p>
      ),
    };
  });
};
