import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { withStyles } from '@mui/styles'
import { CardItem } from '../../../../../../../interfaces/card'
import CardActions from './CardActions'
import useStyles from '../../../../../../../hooks/use-styles'
import './table-style.css'
import { capitalize } from '../../../../../../../utils/common-utils'

interface CardDetailTableProps {
  tableHeaders: string[]
  rows: any
  onActionClick: (action: any) => void
}

export default function CardDetailTable(props: CardDetailTableProps) {
  const { fontColor } = useStyles(['fontColor'])

  const MuiTableCell = withStyles({
    root: {
      borderBottom: '0 !important',
      paddingTop: 10,
      paddingBottom: 10,
      color: fontColor,
    }
  })(TableCell)

  const { activeBackground, inactiveBackground } = useStyles([
    'activeBackground',
    'inactiveBackground'
  ])

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'unset', height: 150 }}>
      <Table
        stickyHeader
        sx={{ minWidth: 300, height: 'max-content' }}
        size="small"
        aria-label="borderless table"
      >
        <TableHead>
          <TableRow>
            {props.tableHeaders?.map((head) => (
              <MuiTableCell className={`subTitle weight-500`} key={head} align={'left'}>
                {head}
              </MuiTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((rowItem: CardItem) => {
            const { cardNumber, cardType, state, status } = rowItem
            return (
              <TableRow
                key={cardNumber}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {/* <MuiTableCell className={`subTitle`}>
                  {cardNumber.padStart(9, '**** ')}
                </MuiTableCell> */}
                <MuiTableCell sx={{width:'20%'}} className={`subTitle`}>{cardType}</MuiTableCell>
                {/* <MuiTableCell className={`subTitle`}>{nameOnCard}</MuiTableCell> */}
                <MuiTableCell sx={{width:'20%'}} className={`subTitle`}>{state}</MuiTableCell>
                <MuiTableCell  className={`subTitle`} sx={{width:'20%'}}>
                  <p
                    className="status"
                    style={{
                      width:'6rem',
                      backgroundColor:
                        status === 'active'
                          ? activeBackground
                          : inactiveBackground
                    }}
                  >
                    {capitalize( status === 'block' ? "Locked" : status)}
                  </p>
                </MuiTableCell>
                <MuiTableCell className={`subTitle`}>
                  <div className="d-flex action-image-div">
                    <CardActions
                      {...rowItem}
                      onActionClick={props.onActionClick}
                    />
                  </div>
                </MuiTableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
