import collapsedImg from "../../../../assets/my529Collapsed.svg";
import expandImg from "../../../../assets/my529WhiteLogo.png";
import './sidebar-logo-style.css';

interface SidebarLogoProps{
    collapsed:boolean;
}
const SidebarLogo = (props:SidebarLogoProps) => {
    const { collapsed } = props;
  return (
    <div className="sidebar-img-container d-flex-center pointer">
      <img className={collapsed ? '' : 'expandImg'} src={collapsed ? collapsedImg : expandImg} alt="collapsed" />
    </div>
  );
};

export default SidebarLogo;
