import {ApolloClient, gql, InMemoryCache} from "@apollo/client";
import {B2C_CONTEXT, B2C_KEY, B2C_REFERRER, B2C_URL} from "../credentials";
import {APIErrorResponse} from "../interfaces/api";


const headers = () => {
    const user = localStorage.getItem("activeUser");
    const headers: any = {
        "x-api-key": B2C_KEY(),
        "x-context-id": B2C_CONTEXT(),
        "x-referer": B2C_REFERRER(),
    };

    if (user && JSON.parse(user).access_token) {
        headers.authorization = `Bearer ${JSON.parse(user).access_token}`;
    }
    return headers;
};

const cache = new InMemoryCache();

const getApolloClient = () =>
    new ApolloClient({
        uri: B2C_URL(),
        cache,
        headers: headers(),

        defaultOptions: {
            watchQuery: {
                fetchPolicy: "network-only",
            },
            query:{
                fetchPolicy:'no-cache'
            }
        },
    });

const extractGraphQLError = (errorResponse: any) => {
    const errorObject = {} as APIErrorResponse;

    const {graphQLErrors, networkError} = errorResponse;

    if (graphQLErrors && graphQLErrors.length) {
        const {message, extensions} = graphQLErrors[0];

        if (extensions && extensions.response_code) {
            const {response_code, message, fieldErrors} = extensions;
            errorObject.error_code = response_code;
            errorObject.message = message;
            errorObject.field_errors = fieldErrors;
            return errorObject;
        }

        if (message) {
            errorObject.message = message;
            return errorObject;
        }
    }

    if (networkError) {
        console.error(`[Network error]: ${networkError}`);

        if (networkError.statusCode === 403) {
            localStorage.removeItem("activeUser");
            localStorage.removeItem("cardholder");
            window.location.reload();
        }

        errorObject.error_code = "NETWORK_ERROR";
        errorObject.message = "Unable to reach the server";
        return errorObject
    }

    return null;
};

const handleGraphQLError = (error: any) => {
    console.error(error);
    const graphqlError = extractGraphQLError(error);

    if (graphqlError) {
        return Promise.reject(graphqlError);
    }

    return Promise.reject({
        errorCode: "GENERAL",
        message: "Something went wrong",
    });
};

export const query = async (query: string, variables?:any) => {
    return getApolloClient()
    .query({
        query: gql`
            ${query}
        `,
        variables
    })
    .then((response) => {
        return Promise.resolve(response.data);
    })
    .catch(handleGraphQLError);
};

export const mutation = async (mutation: string, variables?: any) => {
    return getApolloClient()
    .mutate({
        mutation: gql`
            ${mutation}
        `,
        variables,
    })
    .then((response) => {
        return Promise.resolve(response.data);
    })
    .catch(handleGraphQLError);
};
