import { FC, useState } from 'react'
import { resetPassword } from '../../../../../apis/auth_api'
import useConfigs from '../../../../../hooks/use-config'
import useLabels from '../../../../../hooks/use-labels'
import { showErrorNotification } from '../../../../../utils/common-utils'
import { setPasswordFields } from '../../../../../utils/fields.utils'
import { errorMessage, fieldValidator } from '../../../../../utils/validation'
import CustomButton from '../../../../custom-button'
import FlexButtons from '../../../../flex-button'
import FormField from '../../../../form/form-field'
import Loader from '../../../../shared/loader/loader'
import customToast from '../../../../shared/services/toaster-service'
import SectionHeader from '../../../../signup-form/header/section-header'

interface SetPasswordProps {
  onFormSubmit?: (values: any) => void
  onCancel: () => void
  formData: any
}

const SetPassword: FC<SetPasswordProps> = ({ onCancel, formData }) => {
  const [formValues, setFormValues] = useState({
    password: '',
    confirmPassword: ''
  }) as any

  const [errors, setErrors] = useState({}) as any
  const [loading, setLoading] = useState(false)

  const {
    newPasswordLabel,
    reTypePasswordLabel,
    cancelLabel,
    resetLabel,
    newPasswordPlaceholder,
    reTypePasswordPlaceholer,
    resetPasswordSuccessMessage
  } = useLabels([
    'newPasswordLabel',
    'reTypePasswordLabel',
    'cancelLabel',
    'resetLabel',
    'newPasswordPlaceholder',
    'reTypePasswordPlaceholer',
    'resetPasswordSuccessMessage'
  ])

  let { strongPasswordOptions } = useConfigs(['strongPasswordOptions'])

  const fieldProps = {
    passwordLabel: newPasswordLabel,
    confirmPasswordLabel: reTypePasswordLabel,
    passwordPlaceholder: newPasswordPlaceholder,
    confirmPasswordPlaceholder: reTypePasswordPlaceholer
  }

  const validate = (key: string, value: string, options?: any) => {
    let fieldErrors = { ...errors }
    const { password, confirmPassword } = formValues

    if (!value.trim() && options.required) {
      fieldErrors[key] = errorMessage.required
    } else if (value.length < 8) {
      fieldErrors[key] = errorMessage.shortPassword
    } else if (!fieldValidator.strongPassword(value, strongPasswordOptions)) {
      fieldErrors[key] = errorMessage.weakPassword
    } else if (
      key === 'password' &&
      !fieldValidator.matchPassword(value, confirmPassword)
    ) {
      fieldErrors[key] = errorMessage.mismatchPassword
    } else if (
      key === 'confirmPassword' &&
      !fieldValidator.matchPassword(value, password)
    ) {
      fieldErrors[key] = errorMessage.mismatchPassword
    } else if (formValues.password && formValues.confirmPassword) {
      fieldErrors = {}
    } else {
      delete fieldErrors[key]
    }

    setErrors(fieldErrors)
  }

  const onFieldChange = (key: string, value: string, options?: any) => {
    validate(key, value, options)
    setFormValues((prev: any) => ({ ...prev, [key]: value }))
  }

  const validFields = () => {
    let fieldErrors = { ...errors }

    const requiredFields = ['password', 'confirmPassword']

    let valid = true

    requiredFields.forEach((field) => {
      if (!formValues[field]) {
        fieldErrors[field] = errorMessage.required
        valid = false
      }
    })

    setErrors(fieldErrors)
    return valid
  }

  const onResetPassword = () => {
    const { email, confirmationToken } = formData
    setLoading(true)
    resetPassword({
      email,
      email_confirmation_token: confirmationToken,
      password: formValues.password
    })
      .then(() => {
        customToast.success(resetPasswordSuccessMessage)
        onCancel()
      })
      .catch((error) => {
        setLoading(false)
        showErrorNotification(error)
      })
  }

  const onSubmit = () => {
    if (validFields()) {
      onResetPassword()
    }
  }

  return (
    <>
      <SectionHeader title="Set New Password" />

      <div className="pb-5">
        {setPasswordFields(fieldProps).map((item, index) => {
          const { name, required } = item.elementProps
          return (
            <FormField
              key={index}
              {...item}
              onChange={(name: string, value: string) =>
                onFieldChange(name, value, { required })
              }
              value={formValues[name]}
              error={errors[name]}
            />
          )
        })}
      </div>

      {loading && <Loader className="custom-loader" />}
      <FlexButtons className="layout-buttons mb-5 pt-5">
        <CustomButton
          type="outline"
          text={cancelLabel}
          className="weight-500 transparent-background"
          onClick={onCancel}
        />
        <CustomButton type="primary" text={resetLabel} onClick={onSubmit} />
      </FlexButtons>
    </>
  )
}

export default SetPassword
