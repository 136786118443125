import {useState} from "react";
import useLabels from "../../../../hooks/use-labels";
import useStyles from "../../../../hooks/use-styles";
import {showErrorNotification} from "../../../../utils/common-utils";
import {errorMessage, fieldValidator} from "../../../../utils/validation";
import FormField from "../../../form/form-field";
import Loader from "../../../shared/loader/loader";
import {emailAlreadyExists, sendCodeOnEmail} from "../../../../apis/auth_api";
import FormButtons from "../../form-buttons";
import SectionHeader from "../../header/section-header";
import { Grid } from "@mui/material";
import useConfigs from "../../../../hooks/use-config";

const VerifyEmailInput = (props: any) => {
    const { formData } = props;
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(formData.email || "");
    const [error, setError] = useState('');
    const {signupReasonType} = useConfigs(['signupReasonType']);

    const {handleLogin, onFormSubmit} = props;
    const {verificationTitle, emailLabel, verificationDescription, loginButtonLabel} = useLabels([
        "verificationTitle",
        "verificationDescription",
        "emailLabel",
        "loginButtonLabel"
    ]);

    const {titleColor} = useStyles(["titleColor"]);

    const sendCode = () => {
        setLoading(true);
        emailAlreadyExists(email)
            .then(exists => {
                if (exists) {
                    setLoading(false);
                    return Promise.reject({error_code:"EMAIL_ALREADY_EXISTS"});
                } else {
                    return sendCodeOnEmail(email,signupReasonType)
                }
            })
            .then(()=>onFormSubmit({email}))
            .catch(error => {
                setLoading(false);
                showErrorNotification(error);
            });
    };

    const onFieldChange = (key:string, value:string, options?:any) => {
        if(!value.trim() && options.required){
            setError(errorMessage.required)
        } else if(!fieldValidator.email(value)){
            setError(errorMessage[key])
        }
        else {
            setError("");
        }
        setEmail(value);
    }

    const handleNext = () => {
        if(!email){
            setError(errorMessage.required);
        }else {
            sendCode();
        }
    }
    return (
        <>
            <SectionHeader
                title={verificationTitle}
                subTitle={verificationDescription}
                subTitleColor={titleColor}
            />

            
                <Grid item md={12}>
                    <FormField
                        type="text"
                        elementProps={{
                            label: emailLabel,
                            name: "email",
                            value: email,
                            required: true,
                        }}
                        onChange={(name: string, value: string) =>
                            onFieldChange(name, value, {required: true})
                        }
                        error={error}
                    />
                </Grid>

            {loading && <Loader className="custom-loader"/>}

            <FormButtons
                handleNext={handleNext}
                handleLogin={handleLogin}
                disableNext={error || loading}
                customClass={"mt-5"}
                outlineButtonText={loginButtonLabel}
            />
        </>
    );
};

export default VerifyEmailInput;
