import MaterialModal from '..'
import TransferFundsModalContents from './TransferFundsModalContent'

interface TransferFundsModalProps {
  isOpen: boolean
  onClose: () => void
  recipient: any
  SecondaryTransfer: boolean
}

const TransferFundsModal = ({ isOpen, ...rest }: TransferFundsModalProps) => {
  return (
    <MaterialModal open={isOpen} handleClose={rest.onClose} width={'33rem'}>
      <TransferFundsModalContents {...rest } />
    </MaterialModal>
  )
}
export default TransferFundsModal
