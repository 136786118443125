import { FC } from 'react'

import CustomButton from '../../custom-button'
import FlexButtons from '../../flex-button'
import Loader from '../../shared/loader/loader'
import './confirmation-modal-style.css'

interface ConfirmationModalProps {
  modalProps: any,
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  modalProps,
}) => {
  const {
    confirmationImage,
    titleColor,
    confirmationTitle,
    confirmationText,
    closeLabel,
    onClose,
    confirmButtonText,
    onConfirm,
    loading
  } = modalProps

  
  return (
    <>
      <img
        src={confirmationImage}
        alt="HeaderImage"
        className="confirmation-header-img mb-2"
      />

      <div className="mat-modal-body text-center" style={{ color: titleColor }}>
        <p className="mb-2 weight-600 title">
          {confirmationTitle}
        </p>

        <p className="mb-2 text-lg">{confirmationText}</p>
      </div>

      {loading && <Loader className="custom-loader mb-3" />}

      <FlexButtons className=" mb-5 layout-buttons">
        <CustomButton
          className="transparent-background weight-500"
          type="outline"
          text={closeLabel}
          onClick={onClose}
        />
        <CustomButton
          type="primary"
          text={confirmButtonText}
          onClick={onConfirm}
        />
      </FlexButtons>
    </>
  )
}

export default ConfirmationModal
