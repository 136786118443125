import React from "react";
import useStyles from "../../hooks/use-styles";

const PrimaryButton = (props: any) => {
  const {
    primaryButtonLabelColor,
    primaryButtonBackground,
    primaryButtonBorder,
  } = useStyles([
    "primaryButtonLabelColor",
    "primaryButtonBackground",
    "primaryButtonBorder",
  ]);


  return (
    <button
      disabled={props.disabled}
      className={`custom-button ${props.className!}`}
      style={{
        backgroundColor: primaryButtonBackground,
        border: `1px solid ${primaryButtonBorder}`,
        color: primaryButtonLabelColor,
        ...(props.style && props.style)
      }}
      onClick={props.onClick}
      type={props.buttonType}
    >
      <span>{props.text}</span>
    </button>
  );
};

export default PrimaryButton;
