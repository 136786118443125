import { FC, useState } from 'react'
import VerifyCode from '../../../signup-form/components/verifyCode/verify-code'
import RegisteredEmail from './RegisteredEmail'
import SetPassword from './SetPassword'

interface ForgotPasswordModalContentProps {
  onClose: () => void;
}

const ForgotPasswordModalContent: FC<ForgotPasswordModalContentProps> = ({
  onClose,
}) => {
  const [forgotPasswordData, setFormData] = useState({}) as any;
  const [step, setStep] = useState(1);

  const handlePrevious = () => {
    setStep((previous) => previous - 1);
  };

  const onFormSubmit = (values: any) => {
    setFormData((prev: any) => ({ ...prev, ...values }));
    setStep((previous) => previous + 1);
  };

  const pageProps = {
    formData: forgotPasswordData,
    onFormSubmit,
    handlePrevious,
    onCancel: onClose,
  };

  return (
    <div>
      {step === 1 && <RegisteredEmail {...pageProps} />}
      {step === 2 && (
        <div className="mb-5 text-center">
          <VerifyCode {...pageProps} resetPassword />
        </div>
      )}
      {step === 3 && <SetPassword {...pageProps} />}
    </div>
  );
};

export default ForgotPasswordModalContent;
