import { useState } from 'react'
import useLabels from '../../../../hooks/use-labels'
import useStyles from '../../../../hooks/use-styles'
import { showErrorNotification } from '../../../../utils/common-utils'
import { errorMessage, fieldValidator } from '../../../../utils/validation'
import { emailAlreadyExists, sendCodeOnEmail } from '../../../../apis/auth_api'
import { Grid } from '@mui/material'
import useConfigs from '../../../../hooks/use-config'
import FormField from '../../../../components/form/form-field'
import Loader from '../../../../components/shared/loader/loader'
import SectionHeader from '../../../../components/signup-form/header/section-header'
import FlexButtons from '../../../../components/flex-button'
import CustomButton from '../../../../components/custom-button'
import { getSignupInvitation } from '../../../../apis/b2c_api'

const SupplementaryEmailInput = (props: any) => {
  const { formData } = props
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(formData.email || '')
  const [error, setError] = useState('')
  const { signupReasonType } = useConfigs(['signupReasonType'])

  const { onFormSubmit } = props
  const { verificationTitle, emailLabel, verificationDescription } = useLabels([
    'verificationTitle',
    'verificationDescription',
    'emailLabel',
    'loginButtonLabel'
  ])

  const { titleColor } = useStyles(['titleColor'])

  const verifySupplementaryEmail = () => {
    setLoading(true)

    let supplementaryInformation = {}

    emailAlreadyExists(email)
      .then((exists) => {
        if (exists) {
          setLoading(false)
          return Promise.reject({ error_code: 'EMAIL_ALREADY_EXISTS' })
        } else {
          return getSignupInvitation(email).then(
            ({ getActiveSignupInvitation }) => {
              if (!getActiveSignupInvitation) {
                return Promise.reject({
                  error_code: 'INVALID_INVITATION_EMAIL'
                })
              } else if (
                getActiveSignupInvitation.status.toLowerCase() !== 'pending'
              ) {
                return Promise.reject({
                  error_code: 'USED_SUPPLEMENTARY_EMAIL'
                })
              } else {
                supplementaryInformation = getActiveSignupInvitation;
                return sendCodeOnEmail(email, signupReasonType)
              }
            }
          )
        }
      })
      .then(() => onFormSubmit({ email, ...supplementaryInformation }))
      .catch((error) => {
        setLoading(false)
        showErrorNotification(error)
      })
  }

  const onFieldChange = (key: string, value: string, options?: any) => {
    if (!value.trim() && options.required) {
      setError(errorMessage.required)
    } else if (!fieldValidator.email(value)) {
      setError(errorMessage[key])
    } else {
      setError('')
    }
    setEmail(value)
  }

  const handleNext = () => {
    if (!email) {
      setError(errorMessage.required)
    } else {
      verifySupplementaryEmail()
    }
  }

  return (
    <>
      <SectionHeader
        title={verificationTitle}
        subTitle={verificationDescription}
        subTitleColor={titleColor}
      />

      <Grid item md={12}>
        <FormField
          type="text"
          elementProps={{
            label: emailLabel,
            name: 'email',
            value: email,
            required: true
          }}
          onChange={(name: string, value: string) =>
            onFieldChange(name, value, { required: true })
          }
          error={error}
        />
      </Grid>

      {loading && <Loader className="custom-loader text-center" />}

      <FlexButtons className="layout-buttons mt-4">
        <CustomButton
          type="next"
          text={'Next'}
          disabled={!!(error || loading)}
          onClick={handleNext}
        />
      </FlexButtons>
    </>
  )
}

export default SupplementaryEmailInput
