import axios from "../../utils/axios";
import AuthResponse from "../../interfaces/user";

export const login = async (email: string, password: string) => {
  const response = await axios.post(`/auth/login`, {
    username: email,
    password,
  });

  return response.data as AuthResponse;
};

export const refreshToken = async (refresh_token: string) => {
  const response = await axios
    .get(`/auth/token`, {
      Authorization: `Bearer ${refresh_token}`,
    })

    return response.data as AuthResponse;
};
