import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import LoginForm from "../../components/loginForm";
import MaterialBox from "../../components/materialBox";
import SectionHeader from "../../components/signup-form/header/section-header";
import { logoutUser, setLoginUser } from "../../store/user/user-reducer";
import OpenCP from "../../assets/my529Logo.png";
import "./login-style.css";
import useLabels from "../../hooks/use-labels";
import useConfigs from "../../hooks/use-config";
import useStyles from "../../hooks/use-styles";
import ForgotPasswordModal from "../../components/Modal/ForgotPasswordModal";
import DOMPurify from "dompurify";

const LoginView = () => {
  const [forgotOpen, showForgot] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { from = "/" }: any = state || {};
  const { activeUser } = useSelector((state: RootStateOrAny) => state.users);
 


  const { privacyPolicyLabel, cardholderLabel, feeScheduleTitle } = useLabels([
    "privacyPolicyLabel",
    "cardholderLabel",
    "feeScheduleTitle"
  ]);
  const { titleColor, blackColor } = useStyles(["titleColor", "blackColor"]);

  const { cardIssuanceInformation, privacy_policy_link, tc_link, fee_schedule_link } = useConfigs([
    "cardIssuanceInformation",
    "privacy_policy_link",
    "tc_link",
    "fee_schedule_link"
  ]);

  useEffect(() => {
    activeUser.acces_token && dispatch(logoutUser());
  });

  const setUserDetails = (loginResponse: any) => {
    dispatch(setLoginUser(loginResponse));
    navigate(from);
  };

  const toggleForgot = () => {
    showForgot((previousValue) => !previousValue);
  };

  const renderForgotPasswordModal = () => {
    return <ForgotPasswordModal isOpen={forgotOpen} onClose={toggleForgot} />;
  };

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      className="login-container"
    >
      {renderForgotPasswordModal()}
      <Grid item sm={10} xs={10} md={9}>
        <Grid container>
          <Grid item sm={12} xs={12} md={6} className="login-content">
            <div className="d-flex-col justify-center fullHeight login-content-container">
              <img
                src={OpenCP}
                alt="openCp"
                className="login-content-logo mb-4"
              />
              <p
                className="mb-3 d-flex weight-500 gap-2"
                style={{ color: titleColor, fontSize: '0.8rem'}}
              >
                <span
                  className="pointer text-underline"
                  onClick={() =>
                    privacy_policy_link &&
                    window.open(privacy_policy_link, "_blank")
                  }
                >
                  {privacyPolicyLabel}
                </span>
                <span> | </span>
                <span
                  className="pointer text-underline"
                  onClick={() => tc_link && window.open(tc_link, "_blank")}
                >
                  {cardholderLabel}
                </span>
                <span> | </span>
                <span
                  className="pointer text-underline"
                  onClick={() => fee_schedule_link && window.open(fee_schedule_link, "_blank")}
                >
                  {feeScheduleTitle}
                </span>
              </p>

              <p className="text-2xs" style={{ color: blackColor }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(cardIssuanceInformation, { ADD_ATTR: ['target'] }),
              }}
              >
              </p>
            </div>
          </Grid>
          <Grid item sm={12} xs={12} md={6}>
            <MaterialBox className="login-box">
              <SectionHeader title={"Login"} />
              <LoginForm
                setUserDetails={setUserDetails}
                showForgot={toggleForgot}
              />
            </MaterialBox>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginView;

