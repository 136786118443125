import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { orderSecondaryCard } from '../../../../../apis/b2c_api'
import HalfGrid from '../../../../../components/halfGrid'
import customToast from '../../../../../components/shared/services/toaster-service'
import useConfigs from '../../../../../hooks/use-config'
import useLabels from '../../../../../hooks/use-labels'
import {
  formatCurrency,
  showErrorNotification
} from '../../../../../utils/common-utils'
import PreviewSecondaryForm from './PreviewSecondaryForm'
import SecondaryForm from './SecondaryForm'

const SecondaryCard = () => {
  const [secondaryDetails, setSecondaryDetails] = useState({})
  const [preview, showPreview] = useState(false)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { state } = useLocation()

  const { cardId, editMode, balance }: any = state || {}

  const { secondaryCardFee } = useConfigs(['secondaryCardFee'])

  const {
    secondaryCardTitle,
    orderSecondaryCardPageTitle,
  } = useLabels([
    'secondaryCardTitle',
    'orderSecondaryCardPageTitle',
  ])

  const { secondaryCardSuccessMessage, secondaryCardEmailExistMessage } =
    useConfigs([
      'secondaryCardSuccessMessage',
      'secondaryCardEmailExistMessage'
    ])

  const onSecondarySubmit = (details: any) => {
    details.cardFee = formatCurrency(+secondaryCardFee, balance.currencyCode);
    setSecondaryDetails(details)
    showPreview(true)
  }

  const onBack = () => {
    showPreview(false)
  }

  const orderCard = () => {
    setLoading(true)
    orderSecondaryCard({ ...secondaryDetails, cardId })
      .then(({ createSupplementaryCardholder }: any) => {
        const { token } = createSupplementaryCardholder.invitation

        setLoading(false)

        if (!token) {
          customToast.success(secondaryCardSuccessMessage)
          navigate('/cards')
        } else {
          customToast.error(secondaryCardEmailExistMessage)
        }
      })
      .catch((error: any) => {
        setLoading(false)
        showErrorNotification(error)
      })
  }

  if (preview)
    return (
      <PreviewSecondaryForm
        secondaryDetails={secondaryDetails}
        onBack={onBack}
        orderCard={orderCard}
        processing={loading}
      />
    )
 

  return (
    <HalfGrid className="pt-2">
      <>
        <div className="mb-5">
          <p className="title weight-600">
            {editMode ? secondaryCardTitle : orderSecondaryCardPageTitle}
          </p>
        </div>

        <HalfGrid>
          <SecondaryForm
            onSubmit={onSecondarySubmit}
            cardDetails={secondaryDetails}
            editMode={editMode}
          />
        </HalfGrid>
      </>
    </HalfGrid>
  )
}

export default SecondaryCard
