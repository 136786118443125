import MaterialModal from '..'
import SignupInvitationModalContent from './SignupInvitationModalContent'


interface SignupModalProps {
  isOpen: boolean
  onClose: () => void
  cardId: string
}

const SignupModal = ({
  isOpen,
  onClose,
  cardId
}: SignupModalProps) => {
  return (
    <MaterialModal open={isOpen} handleClose={onClose} width={'60rem'}>
      <SignupInvitationModalContent onClose={onClose} cardId={cardId} />
    </MaterialModal>
  )
}

export default SignupModal