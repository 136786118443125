import validator from 'validator'
export const fieldValidator: any = {
  email: (value: string) => validator.isEmail(value),
  phoneNumber: (value: string) => {
    return validator.isMobilePhone(value.replace(/-|\s/g, ''), 'any', {
      strictMode: true
    })
  },
  postalCode: (value: string) => validator.isNumeric(value),
  strongPassword: (value: string, options: object) =>
    validator.isStrongPassword(value, options),
  matchPassword: (value: string, comparedValue: String) =>
    comparedValue ? comparedValue === value : true,
  ssn: (value: string) => validator.isNumeric(value),
  city: (value: string) => /^[a-zA-Z ]*$/.test(value),
  numeric: (value:string)=> validator.isNumeric(value),
};

export const errorMessage: any = {
  
  email: 'INVALID_EMAIL',
  emptyEmail: 'EMPTY_EMAIL',
  phoneNumber: 'INVALID_PHONE',
  weakPassword: 'WEAK_PASSWORD',
  mismatchPassword: 'MISMATCH_PASSWORD',
  shortPassword: 'SHORT_PASSWORD',
  postalCode: 'INVALID_POSTAL',
  postalCodeLength: 'SHORT_POSTAL',
  ssn: 'INVALID_SSN',
  ssnLength:'Min_SSN_Length',
  confirmSsnLength:'Min_CONFIRM_SSN_Length',
  city: 'INVALID_CITY',
  required: 'REQUIRED',
  streetAddresss: 'STREET_ADDRESS',
  maxAgeLimit:'MAX_AGE_LIMIT',
  minAgeLimit:'MIN_AGE_LIMIT',
  secMinAgeLimit:'SEC_MIN_AGE_LIMIT',
  maxAmountLimit:'MAX_AMOUNT_LIMIT',
  minAmountLimit:'MIN_AMOUNT_LIMIT',
  invalidNumber:'INVALID_NUMBER',
  invalidDecimal:'INVALID_DECIMAL',
  ssnMismatch:'SSN_MISMATCH',
  confirmSsn: 'INVALID_CONFIRM_SSN',
}
