import React, { useState } from 'react'
import PinField from 'react-pin-field'
import useLabels from '../../../../hooks/use-labels'
import ResendTimer from '../../../resend-timer'
import Loader from '../../../shared/loader/loader'
import { verifyOTP } from '../../../../apis/auth_api'
import FormButtons from '../../form-buttons'
import SectionHeader from '../../header/section-header'
import './style.verify-code.css'
import { showErrorNotification } from '../../../../utils/common-utils'
import { Grid } from '@mui/material'
import useConfigs from '../../../../hooks/use-config'
import useStyles from '../../../../hooks/use-styles'

const VerifyCode = (props: any): JSX.Element => {
  const { formData, handlePrevious, onFormSubmit, resetPassword } = props
  const [loading, setLoading] = useState(false)
  const [code, setPinCode] = useState('')

  const {
    checkEmailTitle,
    checkEmailDescription,
    backLabel,
    continueLabel,
    nextButtonLabel,
    previousButtonLabel,
    verificationTitle
  } = useLabels([
    'checkEmailTitle',
    'checkEmailDescription',
    'emailLabel',
    'backLabel',
    'continueLabel',
    'nextButtonLabel',
    'previousButtonLabel',
    'verificationTitle'
  ])

  const { titleColor } = useStyles(['titleColor'])

  const { resendCodeTime, signupReasonType, resetPasswordReasonType } =
    useConfigs([
      'resendCodeTime',
      'signupReasonType',
      'resetPasswordReasonType'
    ])

  const verifyEmailOTP = () => {
    setLoading(true)

    verifyOTP(formData.email, code)
      .then(({ data }) => {
        setLoading(false)
        const { confirmation_token: confirmationToken } = data
        onFormSubmit({ code, confirmationToken })
      })
      .catch((error) => {
        setLoading(false)
        showErrorNotification(error)
      })
  }

  return (
    <>
      {!resetPassword ? (
        <SectionHeader
          title={checkEmailTitle}
          subTitle={`${checkEmailDescription} ${formData.email}`}
        />
      ) : (
        <>
          <SectionHeader className='mt-3' title={verificationTitle} />
          <div className="mb-5 text-center" style={{ color: titleColor }}>
            <p className="weight-600 text-sm">{checkEmailTitle}</p>
            <p className="text-sm">{`${checkEmailDescription} ${formData.email}`}</p>
          </div>
        </>
      )}

      <Grid item md={12} className="pin-fields-container">
        <PinField
          className="pin-field"
          length={6}
          validate={/^[0-9]$/}
          type="number"
          onChange={(code) => setPinCode(code)}
        />
      </Grid>

      <ResendTimer
        seconds={resendCodeTime}
        resendEmail={formData.email}
        reasonType={resetPassword ? resetPasswordReasonType : signupReasonType}
      />

      {loading && <Loader className="custom-loader mt-3" />}

      <FormButtons
        handleNext={verifyEmailOTP}
        handlePrevious={handlePrevious}
        nextButtonText={resetPassword ? continueLabel : nextButtonLabel}
        previousButtonText={resetPassword ? backLabel : previousButtonLabel}
        disablePrevious={loading}
        disableNext={code.length < 6 || loading}
        customClass={'mt-5'}
      />
    </>
  )
}

export default VerifyCode
