import MuiToolTip from '../../../components/tooltip'
import TransferIcon from '../../../assets/ic_transfer.svg'
import { padLastFour } from '../../../utils/common-utils'
import Detail from '../Content/Cards/assets/ic_newDetails.svg'


export const prepareRecipientData = (recipientsProps: any) => {
  const { recipients, onTransfer, transferFundsTipText } = recipientsProps

  return recipients.map((item: any) => {
    const { firstName, lastName, email, mobileNo } = item
    return {
      name: `${firstName} ${lastName}`,
      email,
      mobileNo,
      action: (
        <p>
          <span
            className="pointer text-underline ml-1"
            onClick={() => onTransfer(item)}
          >
            <MuiToolTip title={transferFundsTipText}>
              <img
                src={TransferIcon}
                alt="Transfer Icon"
                style={{ width: '1.5rem' }}
              />
            </MuiToolTip>
          </span>
        </p>
      )
    }
  })
}
export const prepareCardsData = (cardProps: any) => {
  const {cards , onTransfer , transferFundsTipText}  = cardProps
  return cards.filter(({ cardholder }: any) => cardholder.primaryCardholderId ).map((item: any) => {
    const { cardholder, lastFour } = item;
    const {firstName, lastName, email} = cardholder
    return {
      name: `${firstName} ${lastName}`,
      type: 'Secondary',
      lastFour: padLastFour(lastFour),
      email,

      action: (
        <p>
          <span
            className="pointer text-underline ml-1"
            onClick={() => onTransfer(item)}
          >
            <MuiToolTip title={transferFundsTipText}>
              <img
                src={Detail}
                alt="Transfer Icon"
                style={{ width: '1rem' }}
              />
            </MuiToolTip>
          </span>
        </p>
      )
    }
  })
}



