import { dateTimeFormating, formatCurrency } from '../../../utils/common-utils'

export const prepareTransferData = (transfers: any) => {
  
  return transfers.map((item: any) => {
    const { beneficiaryCardLastFour, beneficiaryName , amount, currencyCode, transferType, transferDatetime } = item;
    
    const date = dateTimeFormating(transferDatetime, 'MMM DD YYYY');
    return {
      date : date || '-' , 
      name: beneficiaryName || '-',
      lastFour: beneficiaryCardLastFour ? '****' + beneficiaryCardLastFour :  '-',
      transferType: transferType ?  transferType : '-',
      amount: formatCurrency(amount, currencyCode)
    }
  })
}



