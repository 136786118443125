import { Grid } from "@mui/material";
import { useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useConfigs from "../../../../hooks/use-config";
import useLabels from "../../../../hooks/use-labels";
import { accountFields } from "../../../../utils/fields.utils";
import { errorMessage, fieldValidator } from "../../../../utils/validation";
import FormField from "../../../form/form-field";
import FormButtons from "../../form-buttons";
import SectionHeader from "../../header/section-header";

const AccountInputs = (props: any) => {
  const { formData, handlePrevious, onFormSubmit } = props;
  const { activeUser } = useSelector((state:RootStateOrAny) => state.users);

  const [errors, setFieldErrors] = useState({}) as any;

  const [fieldValues, setFieldValues] = useState({
    password: formData.password || "",
    confirmPassword: formData.confirmPassword || "",
    email: formData.email,
  }) as any;

  const {
    createAccountTitle,
    createAccountDescription,
    emailLabel,
    passwordLabel,
    confirmPasswordLabel,
  } = useLabels([
    "createAccountTitle",
    "createAccountDescription",
    "emailLabel",
    "passwordLabel",
    "confirmPasswordLabel",
  ]);

  let { strongPasswordOptions } = useConfigs(["strongPasswordOptions"]);

  const fieldProps = {
    emailLabel,
    passwordLabel,
    confirmPasswordLabel,
    passwordDisable:Boolean(activeUser.access_token)
  };

  const validate = (key: string, value: string, options?: any) => {
    let fieldErrors = { ...errors };
    const { password, confirmPassword } = fieldValues;

    if (!value.trim() && options.required) {
      fieldErrors[key] = errorMessage.required;
    } else if (value.length < 8) {
      fieldErrors[key] = errorMessage.shortPassword;
    } else if (!fieldValidator.strongPassword(value, strongPasswordOptions)) {
      fieldErrors[key] = errorMessage.weakPassword;
    } else if (
      key === "password" &&
      !fieldValidator.matchPassword(value, confirmPassword)
    ) {
      fieldErrors[key] = errorMessage.mismatchPassword;
    } else if (
      key === "confirmPassword" &&
      !fieldValidator.matchPassword(value, password)
    ) {
      fieldErrors[key] = errorMessage.mismatchPassword;
    } else if (fieldValues.password && fieldValues.confirmPassword) {
      fieldErrors = {};
    } else {
      delete fieldErrors[key];
    }

    setFieldErrors(fieldErrors);
  };

  const onFieldChange = (key: string, value: string, options?: any) => {
    validate(key, value, options);
    setFieldValues((prev: any) => ({ ...prev, [key]: value }));
  };

  const validFields = () => {
    let fieldErrors = { ...errors };
    const requiredFields = ["password", "confirmPassword"];
    let valid = true;
    requiredFields.forEach((field) => {
      if (!fieldValues[field]) {
        fieldErrors[field] = errorMessage.required;
        valid = false;
      }
    });

    setFieldErrors(fieldErrors);
    return valid;
  };

  const onSubmit = () => {
    if (validFields()) {
      onFormSubmit(fieldValues);
    }
  };

  return (
    <>
      <SectionHeader
        title={createAccountTitle}
        subTitle={createAccountDescription}
      />

      {accountFields(fieldProps).map((fieldItem) => {
        const { elementProps, dependantKey, type } = fieldItem;
        const { required } = elementProps;
        return (
          <Grid item md={12} key={elementProps.name}>
            <FormField
              {...fieldItem}
              value={fieldValues[elementProps.name]}
              onChange={(name: string, value: string) =>
                onFieldChange(name, value, { dependantKey, required, type })
              }
              error={errors[elementProps.name]}
            />
          </Grid>
        );
      })}

      <FormButtons
        handleNext={onSubmit}
        handlePrevious={handlePrevious}
        disableNext={Object.keys(errors).length}
        customClass={"mt-5"}
      />
    </>
  );
};

export default AccountInputs;
