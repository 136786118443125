import { Col } from "reactstrap";
import CustomButton from "../../../custom-button";
import SectionHeader from "../../header/section-header";
import successImg from "../../assets/checkSuccess.svg";
import useStyles from "../../../../hooks/use-styles";
import "./card-success-style.css";
import { useNavigate } from "react-router";

const SignupSuccess = (props:any) => {
  const { titleColor, subTitleColor } = useStyles([
    "titleColor",
    "subTitleColor",
  ]);
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate("/login");
    };

  return (
    <div className="d-flex-center mb-4">
      <SectionHeader
        title="Congratulations!"
        subTitle="Your application is approved! Please click login to begin using your card."
      />

      <Col md={12} className="content-section">
        <img src={successImg} className="mb-5" alt="card-success" />

        <p className="question-text" style={{ color: subTitleColor }}>
          Any questions?
        </p>
        <p className="support-text pointer" style={{ color: titleColor }}>
          Contact Support
        </p>
      </Col>

        <CustomButton text={"Login"} type="primary" style={{ width: "50%" }} onClick={handleLoginClick} />
    </div>
  );
};

export default SignupSuccess;
