import { FC } from 'react'
import useStyles from '../../../hooks/use-styles'

interface PaperHeaderProps {
  title: string
  subTitle?: string
  headerClass?: string
  titleClass?: string
  subtitleClass?: string
  titleColor?: string
}

const PaperHeader: FC<PaperHeaderProps> = (props) => {
  const { headerClass, title, subTitle, titleClass, subtitleClass, titleColor } = props

  const { greyBlackColor, headerGreyColor } = useStyles([
    'greyBlackColor',
    'headerGreyColor'
  ])
  return (
    <div className={headerClass!}>
      <p
        className={`${titleClass ? titleClass :  'text-base weight-500'}`}
        style={{ color: titleColor ? titleColor : greyBlackColor }}
      >
        {title}
      </p>

      {subTitle && (
        <p
          className={`${subtitleClass ? subtitleClass : 'text-sm'}`}
          style={{ color: headerGreyColor, opacity: subtitleClass ? 0.7 :'inherit' }}
        >
          {subTitle}
        </p>
      )}
    </div>
  )
}

export default PaperHeader