import { Grid } from "@mui/material";
import { useState } from "react";
import useLabels from "../../../../hooks/use-labels";
import { ssnDOBFields } from "../../../../utils/fields.utils";
import { errorMessage, fieldValidator } from "../../../../utils/validation";
import FormField from "../../../form/form-field";
import FormButtons from "../../form-buttons";
import SectionHeader from "../../header/section-header";
import moment from "moment";
import { RootStateOrAny, useSelector } from "react-redux";

let hideAll = setTimeout(() => {}, 0);

const PersonalDobSSNInputs = (props: any) => {
  const { onFormSubmit, formData } = props;
  const [fieldValues, setFieldValues] = useState({
    dob: formData.dob || null,
    ssn: formData.ssn || "",
    confirmSsn: formData.confirmSsn || "",
    maskedSSN: formData.maskedSSN || "",
    maskedConfirmSSN: formData.maskedConfirmSSN || "",

  }) as any;

  const [fieldErrors, setFieldErrors] = useState({}) as any;

  const {
    personalInformationTitle,
    personalDobSSNDescription,
    dobLabel,
    ssnLabel,
    confirmSsnLabel,
  } = useLabels([
    "personalInformationTitle",
    "personalDobSSNDescription",
    "dobLabel",
    "ssnLabel",
    "confirmSsnLabel",
  ]);

  const fieldProps = {
    dobLabel,
    ssnLabel,
    confirmSsnLabel,
  };

  const validate = (key: string, value: string, options?: any) => {
    const errors: any = { ...fieldErrors };
    if (typeof value === "string" && !value.trim() && options.required) {
      errors[key] = errorMessage.required;
    } else if (key === "dob") {
      var date1 = new Date(value);
      var date2 = moment().format("MM/DD/YYYY");
      var date3 = new Date(date2);

      var Difference_In_Time = date3.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      // if (Difference_In_Days > 10957.5) {
      //   errors[key] = errorMessage.maxAgeLimit;
      // } else
      if (Difference_In_Days < 6575) {
        errors[key] = errorMessage.minAgeLimit;
      } else {
        delete errors[key];
      }
    } else if (key === "confirmSsn" && value !== fieldValues.ssn) {
      errors[key] = errorMessage.ssnMismatch;
    } else {
      delete errors[key];
    }

    setFieldErrors(errors);
  };

  const onFieldChange = (key: string, value: string, options?: any) => {
    validate(key, value, options);
    setFieldValues((prev: any) => ({ ...prev, [key]: value }));
  };

  const onSSNChange = (e: any) => {
    const errors = { ...fieldErrors };
    const maskedSSN = e.target.value;
    const hiddenPassValue = fieldValues.ssn;

    let ssn = "";

    let showLength = 1;

    let offset = maskedSSN.length - hiddenPassValue.length;

    if (offset > 0) {
      ssn =
        hiddenPassValue +
        maskedSSN.substring(
          hiddenPassValue.length,
          hiddenPassValue.length + offset
        );
        setFieldValues((prev: any) => ({ ...prev, ssn }));
    } else if (offset < 0) {
      ssn = hiddenPassValue.substring(0, hiddenPassValue.length + offset);
      setFieldValues((prev: any) => ({ ...prev, ssn }));
    }

    setFieldValues((prev: any) => ({
      ...prev,
      maskedSSN:
        maskedSSN
          .substring(0, maskedSSN.length - showLength)
          .replace(/./g, "*") +
        maskedSSN.substring(maskedSSN.length - showLength, maskedSSN.length),
    }));

    if (!maskedSSN.trim()) {
      errors[e.target.name] = errorMessage.required;
    } else if (!fieldValidator.numeric(fieldValues.ssn)) {
      errors[e.target.name] = errorMessage.ssn
    } else {
      delete errors[e.target.name];
    }

    setFieldErrors(errors);

    clearTimeout(hideAll);
    hideAll = setTimeout(() => {
      setFieldValues((prev: any) => ({
        ...prev,
        maskedSSN:
            ("" + maskedSSN).slice(0, -1).replace(/./g, "*") +
            ("" + maskedSSN).slice(-1).replace(/./g, "*"),
      }));

    }, 2000);
  };

  const onConfirmSSNChange = (e: any) => {
    const errors = { ...fieldErrors };
    const maskedSSN = e.target.value;
    const hiddenPassValue = fieldValues.confirmSsn;

    let ssn = "";

    let showLength = 1;

    let offset = maskedSSN.length - hiddenPassValue.length;

    if (offset > 0) {
      ssn =
          hiddenPassValue +
          maskedSSN.substring(
              hiddenPassValue.length,
              hiddenPassValue.length + offset
          );
      setFieldValues((prev: any) => ({ ...prev, confirmSsn: ssn }));
    } else if (offset < 0) {
      ssn = hiddenPassValue.substring(0, hiddenPassValue.length + offset);
      setFieldValues((prev: any) => ({ ...prev, confirmSsn: ssn }));
    }

    setFieldValues((prev: any) => ({
      ...prev,
      maskedConfirmSSN:
          maskedSSN
              .substring(0, maskedSSN.length - showLength)
              .replace(/./g, "*") +
          maskedSSN.substring(maskedSSN.length - showLength, maskedSSN.length),
    }));

    if (!maskedSSN.trim()) {
      errors[e.target.name] = errorMessage.required;
    } else if (!fieldValidator.numeric(fieldValues.confirmSsn)) {
      errors[e.target.name] = errorMessage.confirmSsn
    } else {
      delete errors[e.target.name];
    }

    setFieldErrors(errors);

    clearTimeout(hideAll);
    hideAll = setTimeout(() => {
      setFieldValues((prev: any) => ({
        ...prev,
        maskedConfirmSSN:
            ("" + maskedSSN).slice(0, -1).replace(/./g, "*") +
            ("" + maskedSSN).slice(-1).replace(/./g, "*"),
      }));
    }, 2000);
  };




  const validFields = () => {
    const errors = { ...fieldErrors };
    const requiredFields = ["ssn", "dob", "confirmSsn"];
    let valid = true;
    requiredFields.forEach((field) => {
      if (!fieldValues[field]) {
        errors[field] = errorMessage.required;
        valid = false;
      }
    });
    setFieldErrors(errors);
    return valid;
  };

  const validSSN = () => {
    let valid = true;
    const errors: any = { ...fieldErrors };
    const validator = fieldValidator;
    if (fieldValues.ssn.length < 9) {
      errors.ssn = errorMessage.ssnLength;
      valid = false;
    } else if (!validator.ssn(fieldValues.ssn)) {
      errors.ssn = errorMessage.ssn;
      valid = false;
    }

    setFieldErrors(errors);

    return valid;
  };
  const validConfirmSSN = () => {
    let valid = true;
    const errors: any = { ...fieldErrors };
    const validator = fieldValidator;

    if (fieldValues.confirmSsn.length < 9) {
      errors.confirmSsn = errorMessage.ssnLength;
      valid = false;
    } else if (!validator.ssn(fieldValues.confirmSsn)) {
      errors.confirmSsn = errorMessage.confirmSsn;
      valid = false;
    }else if( fieldValues.confirmSsn !== fieldValues.ssn){
      errors.confirmSsn = errorMessage.ssnMismatch;
      valid = false;
    }
    setFieldErrors(errors);
    return valid;
  };


  const onSubmit = () => {
    if (validFields() && validSSN() && validConfirmSSN()) {
      onFormSubmit({
        ...fieldValues,
        identificationType: "SSN",
      });
    }
  };

  return (
    <>
      <SectionHeader
        title={personalInformationTitle}
        subTitle={personalDobSSNDescription}
      />

      {ssnDOBFields(fieldProps).map((fieldItem: any) => {
        const { name } = fieldItem.elementProps;

        const fieldProps = {
          ...(fieldItem.type === "ssnMasked" && {
            onChange: (value: any) => onSSNChange(value),
            maskedSSN: fieldValues.maskedSSN,
          }),
          ...(fieldItem.type === "confirmSsn" && {
            onChange: (value: any) => onConfirmSSNChange(value),
            maskedConfirmSSN: fieldValues.maskedConfirmSSN,
          }),
        };

        return (
          <Grid item md={12} key={name}>
            <FormField
              {...fieldItem}
              value={fieldValues[name]}
              onChange={(name: string, value: string) =>
                onFieldChange(name, value, fieldItem.elementProps)
              }
              {...fieldProps}
              error={fieldErrors[name]}
            />
          </Grid>
        );
      })}

      <FormButtons
        handleNext={onSubmit}
        handlePrevious={props.handlePrevious}
        disableNext={Object.keys(fieldErrors).length}
        customClass={"mt-5"}
      />
    </>
  );
};

export default PersonalDobSSNInputs;
