import MaterialModal from '..'
import StatementModalContent from './StatementModalContent'

interface StatementModalProps {
  isOpen: boolean
  onClose: () => void
  cardId: string
}

const StatementModal = ({
  isOpen,
  onClose,
  cardId
}: StatementModalProps) => {
  return (
    <MaterialModal open={isOpen} handleClose={onClose} width={'50rem'}>
      <StatementModalContent onClose={onClose} cardId={cardId} />
    </MaterialModal>
  )
}

export default StatementModal
