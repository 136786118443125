export const prepareCardDetails = (
  selectedCard: any,
  cardholderId: string,
  labels: any
) => {
  const cards = [selectedCard]
  const { secondaryLabel, primaryLabel, ...otherLabels } = labels
  return cards.map((cardItem: any) => ({
    cardNumber: cardItem.lastFour,
    cardType: cardItem.cardholder.primaryCardholderId ? secondaryLabel : primaryLabel,
    nameOnCard: cardItem.nameOnCard,
    state: getCardState(cardItem.physicalCardStatus, otherLabels),
    status: cardItem.status.toLowerCase(),
    actionProps: {
      ...cardItem,
      isSecondaryUser: cardItem.cardholderId !== cardholderId
    }
  }))
}

const getCardState = (phyicalCardStaus: string, labels: any) => {
  const { virtualLabel, physicalOrderedLabel, physicalLabel } = labels

  const state: any = {
    ORDER_PENDING: virtualLabel,
    NOT_APPLICABLE: virtualLabel,
    ORDERED: physicalOrderedLabel,
    ACTIVATED: physicalLabel
  }

  return state[phyicalCardStaus]
}
