import { Checkbox } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { createCardHolder } from '../../../../apis/b2c_api'
import useLabels from '../../../../hooks/use-labels'
import useStyles from '../../../../hooks/use-styles'
import { login } from '../../../../store/user/user-action'
import {
  setCardholder,
  setLoginUser
} from '../../../../store/user/user-reducer'
import { showErrorNotification } from '../../../../utils/common-utils'
import Loader from '../../../shared/loader/loader'
import FormButtons from '../../form-buttons'
import SectionHeader from '../../header/section-header'
import './agreement-style.css'
import useConfigs from '../../../../hooks/use-config'
import ScrollView from '../../../scroll-view'
import DOMPurify from 'dompurify'
import OwwQuestions from "../../OwwQuestions";

const CardHolderAgreement = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

	const agreements = props.legalAgreements;

  const { from = "/" }: any = state || {};
  const search = useLocation().search;

  const jwt = new URLSearchParams(search).get("token")!;

  const [loading, setLoading] = useState(false);
  const [isPPAgree, setPPAgreement] = useState(false);
  const [pendingOutOfWallet, setPendingOutOfWallet] = useState(null);



  const { titleColor } = useStyles(["titleColor"]);
  const {
    agreementTitle,
    agreementDescription,

    submitButtonText,
  } = useLabels(["agreementTitle", "agreementDescription", "submitButtonText"]);

  const { agreementsCheckbox, agreement_error } = useConfigs([
    "agreementsCheckbox",
    "agreement_error",
  ]);
  const [disableCheckbox, setDisableCheckbox] = useState(true);

  const {
    email,
    password,
    firstName: first_name,
    lastName: last_name,
    confirmationToken: email_confirmation_token,
  } = props.formData;

  const { setCurrentForm } = props;

  useEffect(() => {
    setLoading(!agreements?.length || agreements?.length < 1);
  }, []);
  const signup = () => {
    
    setLoading(true);
    props.formData.extRefNo =jwt;
    createCardHolder(props.formData).then(({ createCardholder }) => {
      if (!!createCardholder.pendingOutOfWallet) {
        const {pendingOutOfWallet} = createCardholder;
        const {kycStatus} = pendingOutOfWallet;
        if (kycStatus === 'PENDING_OOW_QUESTIONS') {
          setPendingOutOfWallet(pendingOutOfWallet);
          props.setIspendingOutOfWallet(true);
        }
      } else {
        dispatch(setCardholder(createCardholder.cardholder));
        createAccountAndCardholder()
      }
    })
    .catch((error) => {
      showErrorNotification(error);
      setCurrentForm("emailForm");
      setLoading(false)
    });


  };

  const createAccountAndCardholder = async () => {


    const userDetails = await login(email, password);
    dispatch(setLoginUser(userDetails));
    navigate(from);
    setLoading(false)
  };

  return (
      <>
        {pendingOutOfWallet ? (
            <OwwQuestions pendingOutOfWallet={pendingOutOfWallet} userDetail= {props.formData} />
        ) : (
            <div className="cardholder-view">
              <SectionHeader title={agreementTitle} subTitle={agreementDescription} />
              {(agreements?.length && agreements.length > 0) && (
                  <ScrollView onScrollToBottom={() => setDisableCheckbox(false)}>
                    {agreements.map(({ title, description, agreement_content }: any) => {
                      return title !== "eSign" ? (
                          <span
                              key={title}
                              style={{ color: titleColor }}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(description || agreement_content, { ADD_ATTR: ["target"] }),
                              }}
                          ></span>
                      ) : null;
                    })}
                  </ScrollView>
              )}
              <div
                  className="mt-4 d-flex align-center agreement-section"
                  style={{
                    color: titleColor,
                  }}
                  onClick={() =>
                      disableCheckbox
                          ? showErrorNotification(agreement_error)
                          : undefined
                  }
              >
                <Checkbox
                    checked={isPPAgree}
                    style={{ padding: "unset", top: "-6px" }}
                    onChange={({ target }) => setPPAgreement(target.checked)}
                    size={"small"}
                    disabled={disableCheckbox}
                />
                <div className={`${disableCheckbox ? "text-muted" : ""}`}>
                  {agreementsCheckbox}
                </div>
              </div>

              {loading && <Loader className="custom-loader" />}

              <FormButtons
                  customClass={"mt-5"}
                  handleNext={signup}
                  handlePrevious={props.handlePrevious}
                  nextButtonText={submitButtonText}
                  disablePrevious={loading}
                  disableNext={loading || !isPPAgree}
              />
            </div>
        )}
      </>
  );

}
export default CardHolderAgreement
