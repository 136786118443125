import React from 'react'
import CustomButton from '../../custom-button'
import FlexButtons from '../../flex-button'

const FormButtons = (props: any) => {
  const {
    disableNext,
    handleNext,
    handlePrevious,
    handleLogin,
    customClass,
    nextButtonText,
    outlineButtonText,
    disablePrevious,
    previousButtonText
  } = props

  return (
    <FlexButtons className={`${customClass}`}>
      {handleLogin && (
        <CustomButton
          className="weight-500"
          style={{ width: '32%' }}
          type="outline"
          text={outlineButtonText}
          onClick={handleLogin}
        />
      )}
      {handlePrevious && (
        <CustomButton
          type="previous"
          className='transparent-background'
          disabled={disablePrevious}
          onClick={handlePrevious}
          style={{ width: '32%' }}
          text={previousButtonText || 'Previous'}
        />
      )}

      <CustomButton
        text={nextButtonText}
        type="next"
        disabled={disableNext}
        onClick={handleNext}
        style={{ width: '32%' }}
      />
    </FlexButtons>
  )
}

export default FormButtons
