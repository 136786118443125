import { FC } from 'react'
import MaterialModal from '..'
import ForgotPasswordModalContent from './ForgotPasswordModalContent'

interface ForgotPasswordModalProps {
  isOpen: boolean
  onClose: () => void
}

const ForgotPasswordModal: FC<ForgotPasswordModalProps> = ({
  isOpen,
  ...rest
}) => {
  return (
    <MaterialModal open={isOpen} handleClose={rest.onClose} width={'27rem'}>
      <ForgotPasswordModalContent {...rest}/>
    </MaterialModal>
  )
}

export default ForgotPasswordModal
