import moment, { Moment, unitOfTime } from "moment";
import customToast from "../components/shared/services/toaster-service";
import { getLanguage } from "./language-helper";
import { findErrorMessage } from "./metadata";
const PNF = require("google-libphonenumber").PhoneNumberFormat;

export const RECIPIENT_TYPE = "P2P";

export const phoneInstance = () => {
  const phoneUtil =
    require("google-libphonenumber").PhoneNumberUtil.getInstance();
  return phoneUtil;
};




export const showErrorNotification = (error: any) => {
  if (typeof error === "string") {
    customToast.error(error);
  } else {
    let message = "";
    if (error.error_code) {
      message = findErrorMessage(error.error_code, getLanguage());
      if (!message) {
        console.error(
          `Could not find any message against [${error.error_code}]`
        );
      }
    }

    if (!message) {
      message = error.message;
    }

    customToast.error(message);
  }
};

export const isJSON = (val: string): boolean => {
  if (typeof val === "string" && val.length > 1) {
    const startChar = val[0];
    const endChar = val[val.length - 1];
    return ["{", "["].includes(startChar) && ["]", "}"].includes(endChar);
  }

  return false;
};
export const secondsToHms = (d: any) => {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor((d % 3600) / 60)
  var s = Math.floor((d % 3600) % 60)

  var hDisplay = h > 0 ? formatTime(h) + ':' : ''
  var mDisplay = m > 0 ? formatTime(m) + ':' : '00:'
  var sDisplay = s > 0 ? formatTime(s) : ''
  return hDisplay + mDisplay + sDisplay
}

export const formatTime = (time: number) => {
  if (time < 10) {
    return `0${time}`
  }
  return time
}

export const timeDiff = (
  from: any,
  to: any,
  unit: unitOfTime.Diff = "seconds"
): number => {
  return moment(from).diff(to, unit);
};

export const timeDiffFromNow = (time: Moment, unit = "seconds"): number => {
  return timeDiff(time, moment(), unit as unitOfTime.Diff);
};

export const dateTimeFormating = (dateTime: string, format= 'YYYY-MM-DD') => {
  let splitDateTime = dateTime.split(' ');
  let splitTime = splitDateTime[1].split(':');
  const mergeDateTime = `${splitDateTime[0]}T${splitTime[0]}:${splitTime[1]}:${splitTime[2]}`;
  return moment(new Date(mergeDateTime)).format(format);
}

export const getCustomDate = (date= new Date(), previousMonths?: number, format= 'YYYY-MM-DD') => {
  return previousMonths ? moment(date).subtract(previousMonths, "months").format(format) : moment(new Date()).format(format)
}

export const padStart = (value: string, length: number, character: string) => {
  return value.padStart(length, character);
};

export const addSpaceInCardNumber = (cardNumber: string) => {
  return cardNumber.match(/.{1,4}/g)?.join(" ");
};

export const formatLastFour = (cardNumber: string) => {
  return addSpaceInCardNumber(padStart(cardNumber, 16, "*"));
};

export const padLastFour = (lastFour: string) => {
  return lastFour.padStart(9, "**** ");
};

export const previewMaskSsn = (ssnNumber: string) => {
  const lastFour = ssnNumber.slice(ssnNumber.length - 4);
  return `***-**-${lastFour}`;
};

export const maskSSN = (ssnNumber: any) => {
  if (ssnNumber.length > 0) {
    const ssnDigits = ssnNumber.length - 1;
    const lastDigit = ssnNumber[ssnDigits];
    let ssn = "";
    const mask = "*";
    for (var i = 0; i < ssnDigits; i++) {
      ssn = `${ssn}${mask}`;
    }
    const finalMask = `${ssn}${lastDigit}`;
    return finalMask;
  } else {
    return "";
  }
};

export function formatSocialSecurity(val: any) {
  val = val.replace(/\D/g, "");
  val = val.replace(/^(\d{3})/, "$1-");
  val = val.replace(/-(\d{2})/, "-$1-");
  val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
  return val;
}

export const formatName = (firstName: string, lastName: string) => {
  return `${firstName[0].toUpperCase()}.${lastName}`;
};

export const formatCurrency = (amount: number, currency: string) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  });

  return formatter.format(amount);
};

export const capitalize = (word: string) => {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const formatPhoneNumber = (
  phoneNumber: string,
  countryDialingCode: string
) => {
  const phoneUtil = phoneInstance();
  const number = phoneUtil.parseAndKeepRawInput(phoneNumber, "US");

  return `+${countryDialingCode} ${phoneUtil.format(number, PNF.NATIONAL)}`;
};

export const decimalCount = (num: string) => {
  const numStr = String(num);
  if (numStr.includes(".")) {
    return numStr.split(".")[1].length;
  }
  return 0;
};
