import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { timeDiffFromNow } from "../utils/common-utils";
import moment from "moment";
import { refreshToken } from "../store/user/user-action";
import { setLoginUser } from "../store/user/user-reducer";

let timeoutRef : any;
const stopTokenRefresh = () => {
  if(timeoutRef){
    clearTimeout(timeoutRef);
  }
}

const RefreshAuth = (props: any) => {
  const { activeUser } = useSelector((state: RootStateOrAny) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeUser.access_token) {
      const { exp }: any = jwt_decode(activeUser.access_token);
      
      const expiryAt = moment(exp * 1000);
      const TIME_IN_SECONDS = timeDiffFromNow(expiryAt);
      const REFRESH_TIME = TIME_IN_SECONDS - 30;

      console.log(`Token will refresh in ${REFRESH_TIME} seconds`);

      timeoutRef = setTimeout(() => {
        stopTokenRefresh();
        const user = localStorage.getItem("activeUser");

        if(user && user.length){
          const loggedInUser = JSON.parse(user);

          refreshToken(loggedInUser.refresh_token)
          .then((response) => {
            dispatch(setLoginUser(response));
          }).catch(console.error);
        }
       
      }, REFRESH_TIME * 1000);

    } else {
     stopTokenRefresh()
    }

    return () => {
      stopTokenRefresh()
    };
  }, [activeUser.access_token, activeUser.refresh_token, dispatch]);

  return <></>;
};

export default RefreshAuth;
