import { Grid } from '@mui/material'
import { FC } from 'react'
import CustomSelect from '../customSelect'

import MaterialPagination from '../materialPagination'
import './table-pagination-style.css';

interface TablePaginationProps {
  rows: Array<object>
  pageProps: any
}

const TablePagination: FC<TablePaginationProps> = ({ pageProps, rows }) => {
  const handlePageChange = (
    event: React.ChangeEvent<unknown> | undefined,
    value: number
  ) => {
    pageProps.changePage(value)
  }

  const handlePageLimit = (pageLimit:string) => {
      pageProps.changeLimit(+pageLimit);
  }

  if (!rows.length || !pageProps) return null

  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent={'space-between'}
      className="mt-4"
    >
      <Grid item xs={12} sm={12} md={2} className='show-page-grid'>
        <p className="text-xs">
          Showing Page {pageProps.pageNumber} of {pageProps.totalPages}
        </p>
      </Grid>

      <Grid item xs={12} sm={12} md={8} className="d-flex justify-center">
        <MaterialPagination
          onPageChange={handlePageChange}
          pageProps={pageProps}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2}  className='page-size-grid'>
        <div className="text-xs d-flex align-center gap-2">
          <span className="half-width text-right">Page Size:</span>
          <CustomSelect
            options={[
              { name: '10', value: '10' },
              { name: '20', value: '20' },
              { name: '30', value: '30' }
            ]}
            className={"half-width table-limit-select"}
            value={pageProps.limit}
            onSelect={handlePageLimit}
          />
        </div>
      </Grid>
    </Grid>
  )
}

export default TablePagination
