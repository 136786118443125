import MaterialModal from '..'
import BillPaymentModalContent from './BillPaymentModalContent'

interface BillPaymentModalProps {
  isOpen: boolean
  onClose: () => void
  actionProps: any
}
const BillPayment = ({ isOpen, ...rest }: BillPaymentModalProps) => {
  return (
    <MaterialModal
      open={isOpen}
      handleClose={rest.onClose}
      width={'100%'}
      boxClass={'transparent-background'}
    >
      <BillPaymentModalContent {...rest} />
    </MaterialModal>
  )
}

export default BillPayment
