import LogoutIcon from "../assets/ic_logout.svg";
import CardIcon from "../assets/ic_home.svg";
import InvitationIcon from "../assets/ic_SideInvitation.svg";
import ic_transferFunds from "../assets/ic_transferFunds.svg";
import ic_faqs from "../assets/ic_faqs.svg";
import ic_fs from "../assets/ic_fs.svg";
import ic_pp from "../assets/ic_pp.svg";
import ic_tc from "../assets/ic_tc.svg";
import ic_esign from "../assets/ic_esign.svg";

import "./menu.style.css";

import "./menu.style.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { logoutUser } from "../../../../store/user/user-reducer";
import { useDispatch } from "react-redux";
import useStyles from "../../../../hooks/use-styles";
import useLabels from "../../../../hooks/use-labels";
import useConfigs from "../../../../hooks/use-config";

interface SidebarMenuProps {
  collapsed: boolean;
}

const SidebarMenu = (props: SidebarMenuProps) => {
  const { collapsed } = props;
  const cardholderString = localStorage.getItem("cardholder");
  const cardholder = cardholderString ? JSON.parse(cardholderString) : null;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { activeNavBackground } = useStyles(["activeNavBackground"]);
  const pathNames = location.pathname.split("/");

  const { showInvitationMenu } = useConfigs(["showInvitationMenu"]);

  const {
    myCardTitle,
    transferFundTitle,
    logoutTitle,
    faqTitle,
    recipientListLabel,
    feeScheduleTitle,
    cardholderLabel,
    privacyPolicyTitle,
    eSignAgreementTitle,
  } = useLabels([
    "myCardTitle",
    "transferFundTitle",
    "logoutTitle",
    "faqTitle",
    "recipientListLabel",
    "feeScheduleTitle",
    "cardholderLabel",
    "privacyPolicyTitle",
    "eSignAgreementTitle",
  ]);

  const {
    faqs_link,
    privacy_policy_link,
    fee_schedule_link,
    cardholder_agreement_link,
    e_sign_link,
  } = useConfigs([
    "faqs_link",
    "privacy_policy_link",
    "fee_schedule_link",
    "cardholder_agreement_link",
    "e_sign_link",
  ]);

  const navMenues = [
    {
      title: myCardTitle,
      icon: CardIcon,
      active: pathNames.includes("cards"),
      path: "/cards",
      visible: true,
      onClick: () =>
        navigate("/cards", { state: { pageCrumbs: [{ title: myCardTitle }] } }),
    },
    {
      title: "Invitations",
      icon: InvitationIcon,
      active: pathNames.includes("invitations"),
      path: "/invitations",
      onClick: () =>
        navigate("/invitations", {
          state: { pageCrumbs: [{ title: "Invitations" }] },
        }),
      visible: showInvitationMenu,
    },

    {
      title: faqTitle,
      icon: ic_faqs,
      visible: true,
      onClick: () =>
        window.open(faqs_link, "_blank"),
    },
    {
      title: eSignAgreementTitle,
      icon: ic_esign,
      visible: true,
      onClick: () =>
        window.open(e_sign_link, "_blank"),
    },
    {
      title: cardholderLabel,
      icon: ic_tc,
      visible: true,
      onClick: () =>
        window.open(cardholder_agreement_link, "_blank"),
    },
    {
      title: feeScheduleTitle,
      icon: ic_fs,
      visible: true,
      onClick: () =>
        window.open(fee_schedule_link, "_blank"),
    },
    {
      title: privacyPolicyTitle,
      icon: ic_pp,
      visible: true,
      onClick: () =>
        window.open(privacy_policy_link, "_blank"),
    },
    {
      title: logoutTitle,
      icon: LogoutIcon,
      onClick: () => {
        localStorage.setItem("isLoggedIn",'false')
        dispatch(logoutUser());
        navigate(`/`);
      },
      visible: true,
    },
  ];
  if(!cardholder.primaryCardholderId)
  {
    navMenues.splice(2, 0, {
      title: transferFundTitle,
      icon: ic_transferFunds,
      active: pathNames.includes("transfer-funds"),
      path: "/transfer-funds",
      visible: true,
      onClick: () =>
        navigate("/transfer-funds", {
          state: { pageCrumbs: [{ title: recipientListLabel }] },
        }),
    });
  }

  return (
    <div className="menue-container d-flex-col gap-2">
      {navMenues.map((menuItem) => {
        const { onClick, active, visible } = menuItem;
        return (
          visible && (
            <div
              key={menuItem.title}
              onClick={onClick}
              className={`${
                collapsed ? "d-flex-center" : "d-flex"
              }  pointer gap-3 menuItem align-center`}
              style={{ backgroundColor: active && activeNavBackground }}
            >
              <img src={menuItem.icon} alt={menuItem.icon} />
              {!collapsed && (
                <p className="menuText text-base text-sm">{menuItem.title}</p>
              )}
            </div>
          )
        );
      })}
    </div>
  );
};

export default SidebarMenu;
