import React from "react";

interface TitleProps {
    title: string;
    titleColor:string;
    reviewHeaderColor:string,
}
export const PreviewTitle = (props:TitleProps) => {
    const { titleColor, reviewHeaderColor } = props;
  return (
    <p
      className="preview-title title weight-600"
      style={{
        color: titleColor,
        backgroundColor: reviewHeaderColor,
      }}
    >
      {props.title}
    </p>
  );
};
