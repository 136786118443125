import { useState } from 'react'
import { Grid } from '@mui/material'
import InformationHeader from '../../../../components/information-header'
import useStyles from '../../../../hooks/use-styles'
import useLabels from '../../../../hooks/use-labels'
import FormField from '../../../../components/form/form-field'
import CustomButton from '../../../../components/custom-button'
import { profileFields } from '../../../../utils/fields.utils'
import useConfigs from '../../../../hooks/use-config'

import { errorMessage, fieldValidator } from '../../../../utils/validation'
import { phoneInstance } from '../../../../utils/common-utils'
import Loader from '../../../../components/shared/loader/loader'
import ResetPasswordModal from '../../../../components/Modal/ResetPasswordModal'
import { useSelector, RootStateOrAny } from 'react-redux'
import validator from 'validator'

const PNF = require('google-libphonenumber').PhoneNumberFormat

const ProfileForm = ({
  onUpdateProfile,
  loading,
  profileData,
  isSecondary
}: any) => {
  const {
    firstName,
    lastName,
    email,
    dateOfBirth,
    addresses,
    phoneDetails,
    identificationDocs
  } = profileData

  const {
    address1: address,
    address2,
    addressType,
    city,
    region,
    postalCode,
    country
  } = addresses[0]
  const { phoneNumber, countryDialingCode, phoneType } = phoneDetails[0]

  const { cardholder } = useSelector((state: RootStateOrAny) => state.users)
  const { cards } = cardholder;
  const isPrimaryUser = cards[0].cardholder.primaryCardholderId == null ? true : false;
  
  const checkNullValue = (value : any) => {
    if( value === 'null' || value === null)
      return false
    else return true
  }

  const phoneUtil = phoneInstance()
  const phone = checkNullValue(phoneNumber) ? phoneInstance().parseAndKeepRawInput(phoneNumber, 'US') : "";

  const { identificationValue: ssn = '', identificationType = '' } =
    identificationDocs[0] || [
      { identificatioValue: '', identificationType: '' }
    ]

  const [fieldValues, setFieldValues] = useState({
    phoneNumber: checkNullValue(phoneNumber) ? `+${countryDialingCode}${phoneUtil.format(
      phone,
      PNF.NATIONAL
    )}`: "",
    address,
    country,
    region,
    city,
    postalCode,
    firstName,
    lastName,
    dob: dateOfBirth,
    ssn
  })

  const [fieldErrors, setFieldErrors] = useState({})

  const { titleColor, subTitleColor, labelColor } = useStyles([
    'titleColor',
    'subTitleColor',
    'labelColor'
  ])

  const { countries, states } = useConfigs(['countries', 'states'])

  const {
    personalInfoLabel,
    identityLabel,
    firstNameLabel,
    lastNameLabel,
    phoneNumberLabel,
    addressLabel,
    cityLabel,
    stateLabel,
    zipCodeLabel,
    dobLabel,
    ssnLabel,
    countryLabel,
    changePasswordLabel
  } = useLabels([
    'personalInfoLabel',
    'identityLabel',
    'firstNameLabel',
    'lastNameLabel',
    'phoneNumberLabel',
    'addressLabel',
    'cityLabel',
    'stateLabel',
    'zipCodeLabel',
    'dobLabel',
    'ssnLabel',
    'identityTitle',
    'countryLabel',
    'changePasswordLabel'
  ])

  const fieldProps = {
    firstName,
    lastName,
    firstNameLabel,
    lastNameLabel,
    phoneNumberLabel,
    addressLabel,
    cityLabel,
    stateLabel,
    zipCodeLabel,
    countryLabel,
    dobLabel,
    ssnLabel,
    dateOfBirth,
    ssn,
    countries,
    states,
    labelColor: labelColor,
    textColor: subTitleColor,
    isPrimaryUser: isPrimaryUser
  }

  const [setPassword, showSetPassword] = useState(false)

  const togglePassword = () => {
    showSetPassword((previouseValue) => !previouseValue)
  }

  const renderChangePasswordModal = () => {
    return <ResetPasswordModal isOpen={setPassword} onClose={togglePassword} />
  }

  const validate = (key: string, value: string, options?: any) => {
    let errors: any = { ...fieldErrors }

    const keyValidator = fieldValidator[key]

    if (!value.trim() && options.required) {
      errors[key] = errorMessage.required
    } else if (key === 'postalCode' && !keyValidator(value)) {
      errors[key] = errorMessage.postalCode
    } else if (key === 'postalCode' && value.length < 5) {
      errors[key] = errorMessage.postalCodeLength
    } else if (keyValidator && !keyValidator(value)) {
      errors[key] = errorMessage[key]
    } else {
      delete errors[key]
    }

    setFieldErrors(errors)
  }

  const onFieldChange = (key: string, value: string, options?: any) => {
    validate(key, value, options)
    setFieldValues((prev: any) => ({ ...prev, [key]: value }))
  }

  const { personalFields, addressFields, identityFields } =
    profileFields(fieldProps)

  const validFields = () => {
    const errors: any = { ...fieldErrors }
    const requiredFields = [
      'phoneNumber',
      'country',
      'region',
      'city',
      'postalCode',
      'address'
    ]

    let valid = true
    requiredFields.forEach((field) => {
      if (!fieldValues[field as keyof typeof fieldValues]) {
        errors[field] = errorMessage.required
        valid = false
      }
    })

    setFieldErrors(errors)
    return valid
  }

  const filterObject = () => {
    // Check the condition; if true, exclude the specified key
    if (fieldValues?.ssn == "" || !validator.isNumeric(fieldValues?.ssn)){
    return Object.fromEntries(
            Object.entries(fieldValues).filter(([key]) => key !== "ssn")
        );
    }
    return fieldValues;
}

  const handleUpdate = () => {
    if (validFields()) {
      const phone = phoneInstance().parseAndKeepRawInput(
        fieldValues.phoneNumber,
        ''
      )

      onUpdateProfile({
        ...filterObject(),
        phoneType,
        countryDialingCode,
        phoneNumber: phone.getNationalNumber().toString(),
        address2,
        addressType,
        identificationType
      })
    }
  }

  return (
    <div className="pt-3">
      <div>
        {renderChangePasswordModal()}
        <p className="text-lg weight-600" style={{ color: titleColor }}>
          {firstName} {lastName}
        </p>
        <p className="text-sm" style={{ color: subTitleColor }}>
          {checkNullValue(phoneNumber) ? `+${countryDialingCode} ${phoneUtil.format(phone, PNF.NATIONAL)}` : ""}
        </p>

        <div className="d-flex align-center">
          <p className="text-sm break-word" style={{ color: subTitleColor }}>
            {email}
          </p>
          {!isSecondary && (
            <p
              className="mb-0 pointer text-sm text-underline pl-4"
              style={{ color: titleColor }}
              onClick={togglePassword}
            >
              {changePasswordLabel}
            </p>
          )}
        </div>
      </div>

      <div>
        <InformationHeader
          title={personalInfoLabel}
          className="text-sm weight-500 mt-4"
        />

        <Grid container className="mt-4">
          <Grid item md={7} xs={12} rowSpacing={1}>
            <Grid container rowSpacing={1} columnSpacing={{ md: 6, xs: 0 }}>
              {personalFields.map((item: any) => {
                const { name, required, md } = item.elementProps
                return (
                  <Grid item md={md} xs={12} key={name}>
                    <FormField
                      {...item}
                      onChange={(name: string, value: string) =>
                        onFieldChange(name, value, { required })
                      }
                      value={fieldValues[name as keyof typeof fieldValues]}
                      error={fieldErrors[name as keyof typeof fieldErrors]}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div>
        <InformationHeader
          title={addressLabel}
          className="text-sm weight-500 mt-4"
        />
        <Grid container className="mt-4">
          <Grid item md={7} xs={12} rowSpacing={1}>
            <Grid container rowSpacing={1} columnSpacing={{ md: 6, xs: 0 }}>
              {addressFields.map((item: any) => {
                const { name, required } = item.elementProps
                return (
                  <Grid item md={6} xs={12} key={name}>
                    <FormField
                      {...item}
                      onChange={(name: string, value: string) =>
                        onFieldChange(name, value, { required })
                      }
                      value={fieldValues[name as keyof typeof fieldValues]}
                      error={fieldErrors[name as keyof typeof fieldErrors]}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div>
        <InformationHeader
          title={identityLabel}
          className="text-sm weight-500 mt-4"
        />
        <Grid container className="mt-4">
          <Grid item md={7} xs={12}>
            <Grid container rowSpacing={1} columnSpacing={{ md: 6, xs: 0 }}>
              {identityFields.map((item: any) => {
                const { name, required } = item.elementProps
                return (
                  <Grid item md={6} xs={12} key={name}>
                    {isPrimaryUser ? (<FormField {...item} />) : (
                      <FormField
                      {...item}
                      onChange={(name: string, value: string) =>
                        onFieldChange(name, value, { required })
                      }
                      value={fieldValues[name as keyof typeof fieldValues]}
                      error={fieldErrors[name as keyof typeof fieldErrors]}
                    />
                    )}
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>

      {loading ? (
        <Loader className="custom-loader" />
      ) : (
        <CustomButton
          type="primary"
          text="Update Details"
          className="mt-5 width-lg"
          disabled={!!Object.keys(fieldErrors).length}
          onClick={handleUpdate}
        />
      )}
    </div>
  )
}

export default ProfileForm
