import { Grid } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'

import CustomButton from '../../../../components/custom-button'
import FlexButtons from '../../../../components/flex-button'
import PasswordFields from '../../../../components/form/password-fields'
import Loader from '../../../../components/shared/loader/loader'
import { PreviewContent } from '../../../../components/signup-form/components/detailsPreview/preview-content'
import SectionHeader from '../../../../components/signup-form/header/section-header'
import useLabels from '../../../../hooks/use-labels'
import useStyles from '../../../../hooks/use-styles'
import { formatPhoneNumber } from '../../../../utils/common-utils'

import { setPasswordFields } from '../../../../utils/fields.utils'
import { errorMessage } from '../../../../utils/validation'

const SetPasswordView = (props: any) => {
  const [fieldValues, setFieldValues] = useState({
    password: '',
    confirmPassword: ''
  }) as any

  const [errors, setErrors] = useState({})

  const { profile, email, address,address2,addressType,city,postalCode,country,region } = props.formData;


  const addressitem = {
    content: [
      {
        label: 'Address',
        value: address + address2
      },
      {
        label: 'City',
        value: city
      },
      {
        label: 'state',
        value: region
      },
      {
        label: 'Zip Code',
        value: postalCode
      }
    ]
  }

  const {
    first_name,
    last_name,
    date_of_birth,
    phone_details,
    token,
    identification_docs,
    citizenship_country_code
  } = JSON.parse(profile)

  const { country_dialing_code, phone_number } = phone_details[0]
  const { identification_value } = identification_docs[0]

  const {
    SetPasswordTitle,
    setPasswordDescription,
    passwordLabel,
    confirmPasswordLabel,
    submitButtonText
  } = useLabels([
    'SetPasswordTitle',
    'setPasswordDescription',
    'passwordLabel',
    'confirmPasswordLabel',
    'submitButtonText'
  ])

  const { titleColor } = useStyles(['titleColor'])

  const item = {
    content: [
      {
        label: 'First Name',
        value: first_name
      },
      {
        label: 'Last Name',
        value: last_name
      },
      {
        label: 'Email',
        value: email
      },
      {
        label: 'Mobile',
        value: formatPhoneNumber(phone_number, country_dialing_code)
      },
      {
        label: 'Date of Birth',
        value: moment(date_of_birth).format('MM / DD / YYYY')
      },
      {
        label: 'Country',
        value: citizenship_country_code
      },
      {
        label: 'SSN',
        value: `***-**-${identification_value.substring(
          identification_value.length - 4
        )}`
      }
    ]
  }
  

  const fieldProps = {
    passwordLabel,
    confirmPasswordLabel
  }

  const validFields = () => {
    let fieldErrors: any = { ...errors }

    const requiredFields = ['password', 'confirmPassword']

    let valid = true;
    requiredFields.forEach((field) => {
      if (!fieldValues[field]) {
        fieldErrors[field] = errorMessage.required
        valid = false
      }
    })

    setErrors(fieldErrors)
    return valid
  }

  const onLoginClick = () => {
    if (validFields()) {
      props.onSubmit({
        first_name,
        last_name,
        token,
        password: fieldValues.password
      })
    }
  }
 
  const fields = setPasswordFields(fieldProps)

  return (
    <div>
      <SectionHeader
        title={SetPasswordTitle}
        subTitle={setPasswordDescription}
        subTitleColor={titleColor}
      />

      <Grid container rowSpacing={3} columnSpacing={8} className="mb-3">
        {item.content.map((contentItem) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            className="content-columns text-left"
            key={contentItem.label}
          >
            <PreviewContent
              label={contentItem.label}
              labelClass="weight-600"
              value={contentItem.value}
              labelColor={titleColor}
              textColor={titleColor}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container rowSpacing={3} columnSpacing={8} className="mb-3">
        {addressitem.content.map((contentItem) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            className="content-columns text-left"
            key={contentItem.label}
          >
            <PreviewContent
              label={contentItem.label}
              labelClass="weight-600"
              value={contentItem.value}
              labelColor={titleColor}
              textColor={titleColor}
            />
          </Grid>
        ))}
      </Grid>

      <PasswordFields
        fields={fields}
        formValues={fieldValues}
        setFormValues={setFieldValues}
        errors={errors}
        setErrors={setErrors}
      />

      {props.loading && <Loader className="custom-loader" />}
      <FlexButtons className="mt-5 mb-3 layout-buttons">
        <CustomButton
          type="primary"
          text={"Continue"}
          onClick={onLoginClick}
          disabled={!!Object.keys(errors).length}
        />
      </FlexButtons>
    </div>
  )
}

export default SetPasswordView