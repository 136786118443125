import MaterialModal from '..'
import ApplyPhysicalModalContent from './ApplyPhysicalModalContent'

interface ApplyPhysicalModalProps {
  isOpen: boolean
  onClose: () => void
  actionProps: any,
  updateCardholder:any,
}

const ApplyPhysicalModal = (props: ApplyPhysicalModalProps) => {
  return (
    <MaterialModal
      open={props.isOpen}
      handleClose={props.onClose}
      width={'33rem'}
    >
      <ApplyPhysicalModalContent {...props} />
    </MaterialModal>
  )
}

export default ApplyPhysicalModal
