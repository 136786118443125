import { FC, useEffect, useState } from 'react'
import { getBillPaymentUrl } from '../../../../apis/b2c_api'
import useLabels from '../../../../hooks/use-labels'
import { showErrorNotification } from '../../../../utils/common-utils'
import Loader from '../../../shared/loader/loader'
import CloseIcon from '@mui/icons-material/Close';

import './bill-payment-style.css';
import { useDispatch } from 'react-redux'
import { setTransactionApi } from "../../../../store/user/user-reducer";
 
interface BillPaymentModalContentProps {
  actionProps: any
  onClose: () => void
}

const BillPaymentModalContent : FC<BillPaymentModalContentProps> = ({
  actionProps,
  onClose
}) => {
  const [secureUrl, setSecureUrl] = useState('')
  const [loading, setLoading] = useState(true)
  const { cardId } = actionProps
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true)
    getBillPaymentUrl(cardId)
      .then(({ billPaymentUrl }) => {
        setSecureUrl(billPaymentUrl.url)
      })
      .catch(showErrorNotification)

      return () => {
        setSecureUrl('')
      }
  }, [setSecureUrl, cardId])


  const onCloseModal = () => {
    onClose();
    dispatch(setTransactionApi());
  }

  return (
    <>
      <div className='mb-3 fullWidth text-right'>
        <CloseIcon className='pointer' sx={{ color: '#fff' }} onClick={onCloseModal} />
      </div>
      {!secureUrl ? (
        <Loader className="custom-loader" />
      ) : (
        <iframe
          src={secureUrl}
          title="Bill Payment Widget"
          className="modal-bill-payment-view"
        />
      )}
    </>
  )
}

export default BillPaymentModalContent
