import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";

import useLabels from "../../../../../hooks/use-labels";
import useStyles from "../../../../../hooks/use-styles";
import { errorMessage, fieldValidator } from "../../../../../utils/validation";
import CustomButton from "../../../../custom-button";
import FlexButtons from "../../../../flex-button";
import FormField from "../../../../form/form-field";
import { searchRecipient } from "../../../../../apis/b2c_api";
import { showErrorNotification } from "../../../../../utils/common-utils";
import Loader from "../../../../shared/loader/loader";

interface AddRecipientProps {
  onFormSubmit: (values: any) => void;
  onClose: () => void;
}

const SearchRecipient = (props: AddRecipientProps) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  //const [error, setError] = useState<any>({});
  const [recipients, setRecipients] = useState([]) as any;

  const {
    cancelLabel,
    searchRecipientLabel,
    searchRecipientDescription,
    enterEmailPlaceholder,
  } = useLabels([
    "cancelLabel",
    "searchRecipientLabel",
    "searchRecipientDescription",
    "enterEmailPlaceholder",
  ]);

  const { labelColor, lightBlueColor, subTitleColor, listBorderColor } =
    useStyles([
      "labelColor",
      "lightBlueColor",
      "subTitleColor",
      "listBorderColor",
    ]);

  const onSearchChange = (value: string) => {
    if (error) {
      setError("");
    }
    setEmail(value);
  };

  const onSearch = () => {
    if (email === "") {
      setError(errorMessage.emptyEmail);
    } else if (email.trim() && !fieldValidator.email(email)) {
      setError(errorMessage.email);
    } else {
      setError("");

      setLoading(true);
      searchRecipient(email)
        .then(({ searchRecipients }) => {
          if (searchRecipients.nodes.length > 0) {
            setRecipients(searchRecipients.nodes);
          } else {
            showErrorNotification("Email does not exist!");
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showErrorNotification(error);
        });
    }
  };

  const onAddMember = (member: any) => {
    props.onFormSubmit(member);
  };

  return (
    <>
      <div className="mat-modal-body pl-2 pr-2 text-center">
        <p className="weight-600 text-sm" style={{ color: labelColor }}>
          {searchRecipientLabel}
        </p>
        <p className="weight-400 text-sm mb-4" style={{ color: labelColor }}>
          {searchRecipientDescription}
        </p>

        <div className="text-center pl-4 pr-4">
          <FormField
            className="text-left"
            type="search"
            elementProps={{
              name: "email",
              placeholder: enterEmailPlaceholder,
              onSearch,
            }}
            value={email}
            error={error}
            onChange={(name: string, value: string) => onSearchChange(value)}
          />
        </div>

        <div className="pl-2 pr-2">
          {recipients.map((item: any) => {
            return (
              <div
                className="d-flex align-center justify-between pb-2"
                style={{ borderBottom: `1px solid ${listBorderColor}` }}
                key={item.email}
              >
                <div className="text-left">
                  <p
                    className="text-sm weight-600"
                    style={{ color: labelColor }}
                  >
                    {item.firstName} {item.lastName}
                  </p>

                  <p className="text-xs" style={{ color: subTitleColor }}>
                    {item.email}
                  </p>
                </div>

                <AddIcon
                  className="pointer"
                  sx={{ color: lightBlueColor }}
                  onClick={() => onAddMember(item)}
                />
              </div>
            );
          })}
        </div>
      </div>

      {loading && <Loader className="custom-loader" />}

      <FlexButtons className="mat-modal-footer layout-buttons mt-5">
        <CustomButton
          text={cancelLabel}
          type="primary"
          onClick={props.onClose}
        />
      </FlexButtons>
    </>
  );
};
export default SearchRecipient;
