// customToast.js
import { toast } from "react-toastify";
import { css } from "glamor";

const customToast = {
  success(msg: any, options = {}) {
    return toast.success(msg, {
      className: `${css({
        background: "#47B14B !important",
        color: "white !important",
      })}`,
      autoClose:10000,
      closeButton:false
    });
  },
  error(msg: any, options = {}) {
    return toast.error(msg, {
      className: `${css({
        background: "#E74C3C !important",
        color: "white !important",
      })}`,
      autoClose:10000,
      closeButton:false
    });
  },
  info(msg: string, options = {}) {
    return toast.info(msg, {
      className: `${css({
        background: "#00FF00 !important",
        color: "white !important",
        closeButton: false,
      })}`,
    });
  },
};

export default customToast;
