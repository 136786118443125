import MaterialTable from '../materialTable'
import TablePagination from '../tablePagination'
import './material-table-style.css'

const MaterialDataTable = (props: any) => {
  const { pageProps, rows, ...rest } = props

  return (
    <div>
      <MaterialTable rows={rows} {...rest} />
      <TablePagination rows={rows} pageProps={pageProps} />
    </div>
  )
}

export default MaterialDataTable
