import MaterialModal from '..'
import CardStatusModalContent from './CardStatusModalContent'

interface CardStatusModalProps {
  isOpen: boolean
  onClose: () => void
  actionProps:any,
  updateCardholder:any,
}

const CardStatusModal = ({isOpen, ...rest}: CardStatusModalProps) => {
  return (
    <MaterialModal
      open={isOpen}
      handleClose={rest.onClose}
      width={'25rem'}
    >
      <CardStatusModalContent {...rest}/>
    </MaterialModal>
  )
}

export default CardStatusModal
