import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import useStyles from '../../../../hooks/use-styles'
import CardContent from './CardContent'
import CardHeader from './CardHeader'

const Cards = () => {
  const { cardholder } = useSelector((state: RootStateOrAny) => state.users)
  const [card, setCard]: any = useState(null)
  const { cards, cardholderId } = cardholder
  const { dashboardContentColor } = useStyles(['dashboardContentColor'])

  
  useEffect(() => {  
    const currentCardString = localStorage.getItem("currentCard");
   
    currentCardString ? setCard(JSON.parse(currentCardString)) : setFirstCard();
    
  }, [setCard])
  const  setFirstCard =() =>{
    setCard(cards[0]);
    localStorage.setItem("currentCard", JSON.stringify(cards[0]))

  }

  const setNewCard = (selectedCard: any) => {
    setCard(selectedCard)
    localStorage.setItem("currentCard", JSON.stringify(selectedCard))
  }

  if (!card) return null

  return (
    <Grid container rowSpacing={3} style={{ color: dashboardContentColor }}>
      <Grid item xs={12} md={12} sm={12}>
        <CardHeader
          onSelectCard={setNewCard}
          cards={cardholder.cards}
          selectedCard={card}
        />
      </Grid>
      <Grid item xs={12} md={12} sm={12}>
        <CardContent
          selectedCard={card}
          updateCard={setNewCard}
          cardholderId={cardholderId}
        />
      </Grid>
    </Grid>
  )
}

export default Cards
