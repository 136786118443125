import useLabels from '../../../../../../../../hooks/use-labels'

import ApplyPhysicalImg from '../../../../assets/ic_applyPhysical.svg'
import ActivatePhysicalImg from '../../../../assets/ic_cardActivate.svg'
import ShipPhysicalImg from '../../../../assets/ic_shipping.svg'
import ShipPhysicalImgGrey from '../../../../assets/ic_shippingGrey.svg'

import ChangePINImg from '../../../../assets/ic_changePIN.svg'
import DepositImg from '../../../../assets/ic_deposit.svg'
import DepositImgGrey from '../../../../assets/ic_depositGrey.svg'
import BlockCardImgGrey from '../../../../assets/ic_blockGrey.svg'
import OrderCardImg from '../../../../assets/ic_orderSecondCard.svg'
import BlockCardImg from '../../../../assets/ic_block.svg'
import EditProfileImg from '../../../../assets/ic_editActive.svg'
import BillPaymentImg from '../../../../assets/ic_billPayment.svg'
import SignupInvitationImg from '../../../../assets/ic_invitation.svg'
import SignupInvitationImgGrey from '../../../../assets/ic_invitationGrey.svg'





import ApplyPhysicalGreyImg from '../../../../assets/ic_applyPhysicalGrey.svg'
import ChangePINGreyImg from '../../../../assets/ic_changePINGrey.svg'
import OrderCardGreyImg from '../../../../assets/ic_orderSecondCardGrey.svg'
import EditProfileGreyImg from '../../../../assets/ic_editGray.svg'
import ActivatePhysicalGreyImg from '../../../../assets/ic_cardActivateGrey.svg'
import BillPaymentGrayImg from '../../../../assets/ic_billPaymentGray.svg'


import MuiToolTip from '../../../../../../../../components/tooltip'

import './action-style.css'
import useConfigs from '../../../../../../../../hooks/use-config'

const GetActionImages = (props: any) => {
  const { actionProps, onActionClick } = props

  const {
    changePinTooltip,

    applyPhysicalTooltip,
    activateCardToolTip,
    shipmentTrackingTooltip,

    
    directDepositTooltip,
    orderSecondTooltip,

    blockCardToolTip,
    unblockCardToolTip,

    
    editProfileTooltip,
    
    payBillTooltip,
    signupInvitationTooltip
  } = useLabels([
    'changePinTooltip',

    'applyPhysicalTooltip',
    'activateCardToolTip',
    'shipmentTrackingTooltip',

    'directDepositTooltip',
    'orderSecondTooltip',

    'blockCardToolTip',
    'unblockCardToolTip',

    'editProfileTooltip',

    'payBillTooltip',
    'signupInvitationTooltip'
  ]);
  const { ShowApplyPhysical} = useConfigs([
    "ShowApplyPhysical",
  ]);

  const isBlocked = props.status !== 'active'
  const Lock = props.status === 'active' || props.status === 'block';
  
  const { cardholder, isSecondaryUser, physicalCardStatus } =
    props.actionProps

  const {primaryCardholderId} = cardholder 

  const ordered = physicalCardStatus === 'ORDERED';

  const orderPending = physicalCardStatus === 'ORDER_PENDING';

  return [
    {
      icon: isBlocked ? BillPaymentGrayImg : BillPaymentImg,
      tipText: payBillTooltip,
      isBlocked,
      onClick: () =>
        onActionClick({
          actionType: isBlocked ? 'unavailable' : 'payBill',
          actionProps
        })
    },
    {
      icon: isBlocked ? ChangePINGreyImg : ChangePINImg,
      tipText: changePinTooltip,
      isBlocked,
      onClick: () =>
        onActionClick({
          actionType: isBlocked ? 'unavailable' : 'changePin',
          actionProps
        })
    },
    {
      ...( (ShowApplyPhysical === 'true' ? (!isSecondaryUser &&
        !primaryCardholderId && orderPending) : orderPending ) && {
        icon: isBlocked ? ApplyPhysicalGreyImg : ApplyPhysicalImg,
        tipText: applyPhysicalTooltip,
        isBlocked,
        onClick: () =>
          onActionClick({
            actionType: isBlocked ? 'unavailable' : 'applyPhysicalCard',
            actionProps
          })
      })
    },
    {
      ...(ordered && {
        icon: isBlocked ? ActivatePhysicalGreyImg : ActivatePhysicalImg,
        tipText: activateCardToolTip,
        isBlocked,
        onClick: () =>
          onActionClick({
            actionType: isBlocked ? 'unavailable' : 'activateCard',
            actionProps
          })
      })
    },
    {
      ...(ordered &&{
        icon: isBlocked ? ShipPhysicalImgGrey :ShipPhysicalImg,
        tipText: shipmentTrackingTooltip,
        onClick: () =>
          onActionClick({
            actionType: 'cardShipment',
            actionProps
          })
      })
    },
    {
      ...(!isSecondaryUser &&
        !primaryCardholderId && {
          icon:isBlocked ? DepositImgGrey : DepositImg,
          tipText: directDepositTooltip,
          onClick: () =>
            onActionClick({
              actionType: isBlocked ? 'unavailable' :'directDeposit',
              actionProps
            })})
      
    },
    {
      ...(!isSecondaryUser &&
        !primaryCardholderId && {
          icon: isBlocked ? OrderCardGreyImg : OrderCardImg,
          tipText: orderSecondTooltip,
          isBlocked,
          onClick: () =>
            onActionClick({
              actionType: isBlocked ? 'unavailable' : 'orderSecondaryCard',
              actionProps
            })
        })
    },
    {
      ...(!isSecondaryUser &&
        !primaryCardholderId && {
          icon: isBlocked ? SignupInvitationImgGrey : SignupInvitationImg,
          tipText: signupInvitationTooltip,
          isBlocked,
          onClick: () =>
            onActionClick({
              actionType: isBlocked ? 'unavailable' : 'signupInvitation',
              actionProps
            })
        })
    },
    {
      ...(isSecondaryUser && {
        icon: isBlocked ? EditProfileGreyImg : EditProfileImg,
        tipText: editProfileTooltip,
        isBlocked,
        onClick: () =>
          onActionClick({
            actionType: isBlocked ? 'unavailable' : 'updateProfile',
            actionProps
          })
      })
    },
    {
      icon: Lock ?BlockCardImg: BlockCardImgGrey ,
      tipText: props.status === 'block' ? unblockCardToolTip : blockCardToolTip,
      onClick: () =>
        onActionClick({
          actionType:Lock ? 'cardStatus' : 'unavailable',
          actionProps
        })
    }
  ]
}

const CardActions = (actionProps: any) => {
  const actionImages = GetActionImages(actionProps)

  // return <MaterialMoreMenu menuOptions={actionImages} />

  return (
    <>
      {actionImages.map(
        ({ tipText, icon, isBlocked, onClick }, index) =>
          tipText && (
            <MuiToolTip key={index} title={tipText}>
              <img
                className={`${isBlocked ? 'cursor-block' : ''} action-img`}
                key={tipText}
                src={icon}
                alt={tipText}
                onClick={onClick}
              />
            </MuiToolTip>
          )
      )}
    </>
  )
}

export default CardActions
