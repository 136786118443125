import SidebarLogo from "./SidebarLogo";
import "./sidebar.style.css";
import SidebarMenu from "./SidebarMenu";
import useStyles from "../../../hooks/use-styles";

interface SidebarProps {
  collapsed: boolean;
}

const Sidebar = (props: SidebarProps) => {
  const { sidebarBackground } = useStyles(["sidebarBackground"]);

  return (
    <div
      className={`d-flex-col gap-${
        props.collapsed ? "3" : "4"
      } sidebar sidebar-${props.collapsed ? "collapse" : "expand"}`}
      style={{ background: sidebarBackground }}
    >
      <SidebarLogo collapsed={props.collapsed} />
      <SidebarMenu collapsed={props.collapsed} />
    </div>
  );
};

export default Sidebar;
