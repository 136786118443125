import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import useStyles from '../../hooks/use-styles'
import { FC } from 'react'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E2E2E2',
    color: '#585858',
    padding: '7px',
    border: 0,
    fontSize: '.8rem'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '.8rem',
    border: 0,
    color: '#34495E'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'transparent',
    border: 0
  },

  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(27, 69, 116,.07)',
    border: 0
  },
  // hide last border

  '&:last-child td, &:last-child th': {
    border: 0
  },
  'th:first-of-type, td:first-of-type': {
    paddingLeft: 50
  },
  td: {
    padding: '16px 7px'
  }
}))

interface MaterialTableProps {
  columns: Array<string>
  rows: Array<object>
  noDataText?: string
  scrollableHeight?: number,
  noDataStyle?:any
}

const MaterialTable: FC<MaterialTableProps> = ({
  columns,
  rows,
  noDataText,
  scrollableHeight,
  noDataStyle
}) => {

  const { fontColor } = useStyles(['fontColor'])

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '0',
        boxShadow: 'unset',
        backgroundColor: 'transparent',
        height: scrollableHeight || '100%'
      }}
    >
      <Table
        stickyHeader
        sx={{ minWidth: 700, border: 0 }}
        aria-label="customized table"
      >
        <TableHead>
          <StyledTableRow>
            {columns.map((column: string) => (
              <StyledTableCell key={column} align="left" className='weight-600'>
                {column}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>

        <TableBody>
          {rows.length > 0 ? (
            rows.map((row: any, rowIndex: number) => (
              <StyledTableRow key={`row ${rowIndex}`}>
                {Object.keys(row).map((key: string, colIndex: number) => (
                  <StyledTableCell
                    key={`${key}-${colIndex}-${rowIndex}`}
                    align="left"
                  >
                    {row[key]}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell>
                <p
                  className="table-no-data-text subTitle"
                  style={{ color: fontColor,...noDataStyle }}
                >
                  {noDataText}
                </p>
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MaterialTable
