import React from "react";

interface PreviewProps {
  label: string;
  value: string;
  labelColor: string;
  textColor: string;
  labelClass?:string;
}

export const PreviewContent = (props: PreviewProps) => {
  const { label, labelColor, value, textColor, labelClass } = props;
  return (
    <>
      <p className={`label ${labelClass}`} style={{ color: labelColor }}>
        {label}
      </p>

      <p className="content-item" style={{ color: textColor }}>
        {value}
      </p>
    </>
  );
};
