import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useLabels from "../../hooks/use-labels";
import useStyles from "../../hooks/use-styles";
import { login } from "../../store/user/user-action";
import { showErrorNotification } from "../../utils/common-utils";
import { loginFields } from "../../utils/fields.utils";
import { errorMessage } from "../../utils/validation";
import CustomButton from "../custom-button";
import FormField from "../form/form-field";
import Loader from "../shared/loader/loader";

interface LoginFormProps {
  setUserDetails: (values: any) => void;
  showForgot: () => void;
}

const LoginForm: FC<LoginFormProps> = ({ setUserDetails, showForgot }) => {
  const [loading, setLoading] = useState(false);
  const [showSignup, setSignup] = useState(false);

  const [fieldValues, setFieldValues] = useState<any>({
    email: "",
    password: "",
  });

  const [errors, setError] = useState<any>({});
  const navigate = useNavigate();

  const { titleColor } = useStyles(["titleColor"]);

  const {
    emailLabel,
    passwordLabel,
    forgotPasswordLabel,
    SignUpWithRequiredExtRefNo,
  } = useLabels([
    "emailLabel",
    "passwordLabel",
    "forgotPasswordLabel",
    "SignUpWithRequiredExtRefNo",
  ]);

  const fieldProps = {
    emailLabel,
    passwordLabel,
  };
  useEffect(() => {
    if (SignUpWithRequiredExtRefNo === "true") {
      setSignup(true);
    }
  });

  const onFieldChange = (key: string, value: string, options?: any) => {
    const fieldErrors = { ...errors };

    if (!value.trim() && options.required) {
      fieldErrors[key] = errorMessage.required;
    } else {
      delete fieldErrors[key];
    }

    setError(fieldErrors);
    setFieldValues((prev: any) => ({ ...prev, [key]: value }));
  };

  const validFields = () => {
    let fieldErrors = { ...errors };
    const requiredFields = Object.keys(fieldValues);
    let valid = true;
    requiredFields.forEach((field) => {
      if (!fieldValues[field]) {
        fieldErrors[field] = errorMessage.required;
        valid = false;
      }
    });

    setError(fieldErrors);
    return valid;
  };

  const performLogin = () => {
    const { email, password } = fieldValues;

    setLoading(true);
    login(email, password)
      .then((response) => {
        setLoading(false);
        setUserDetails(response);
        localStorage.setItem("isLoggedIn",'true')
      })
      .catch((error) => {
        setLoading(false);
        showErrorNotification(error);
        localStorage.setItem("isLoggedIn",'false')
      });
  };

  const handleLogin = (event: any) => {
    event.preventDefault();
    if (validFields()) {
      performLogin();
    }
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  return (
    <form onSubmit={handleLogin}>
      {loginFields(fieldProps).map((fieldItem) => {
        const { elementProps } = fieldItem;
        const { required, name } = elementProps;
        return (
          <Grid item md={12} key={elementProps.name}>
            <FormField
              className="mt-5 mb-0"
              {...fieldItem}
              value={fieldValues[name]}
              onChange={(name: string, value: string) =>
                onFieldChange(name, value, { required })
              }
              error={errors[elementProps.name]}
            />
          </Grid>
        );
      })}

      <div className="mt-2 mb-3">
        <p
          className="text-xs pointer width-fit-content float-right"
          style={{ color: titleColor }}
          onClick={showForgot}
        >
          {`${forgotPasswordLabel}?`}
        </p>
      </div>

      {loading && <Loader className="custom-loader" />}
      <CustomButton
        className="fullWidth mb-3 mt-7"
        type="primary"
        text="Login"
        onClick={handleLogin}
        disabled={!!Object.keys(errors).length}
        buttonType="submit"
      />
      {(!showSignup) && (
        <CustomButton
          className="fullWidth weight-500"
          type="outline"
          text="Sign Up"
          onClick={handleSignup}
        />
      )}
    </form>
  );
};

export default LoginForm;
