import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import "./style.timer.css";

import useStyles from "../../hooks/use-styles";
import { sendCodeOnEmail } from "../../apis/auth_api";
import Loader from "../shared/loader/loader";
import { showErrorNotification } from "../../utils/common-utils";
import { Grid } from "@mui/material";
import useLabels from "../../hooks/use-labels";

interface TimerProps {
  seconds: number;
  resendEmail: string;
  reasonType: string;
  resetPassword?:boolean;
}

const formatTime = (time: number) => {
  if (time < 10) {
    return `0${time}`;
  }
  return time;
};
const ResendTimer = (props: TimerProps) => {
  let [time, setTime] = useState(props.seconds);
  const [loading, setLoading] = useState(false);

  const { resendColor,subTitleColor, timeColor } = useStyles([
    "resendColor",
    "subTitleColor",
    "timeColor",
  ]);

  const {resendLabel, resendSubText} = useLabels(['resendLabel','resendSubText']);

  useEffect(() => {
    let interval: any;
    interval = setInterval(() => {
      if (time > 0) {
        setTime((prev) => prev - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const resendCode = () => {
    setLoading(true);

    sendCodeOnEmail(props.resendEmail, props.reasonType)
      .then(() => {
        setLoading(false);
        setTime(props.seconds);
      })
      .catch( error => {
        setLoading(false);
        showErrorNotification(error)
      });
  };

  return (
    <Grid item md={12} xs={12} sm={12} className="resend-label  mt-4 mb-5">
      <span
        style={{ color: subTitleColor }}
        className="text-sm"
      >
        {resendSubText}
      </span>
      <Label
        className="text-sm"
        for="resend"
        disabled={!!time}
        style={{ color: resendColor, paddingLeft: '3px' }}
        onClick={() => !time && resendCode()}
      >
         {resendLabel}
      </Label>

      {time > 0 && (
        <span className="d-none timer ml-2" style={{ color: timeColor }}>
          {formatTime(time)}
        </span>
      )}

      {loading && <Loader className="custom-loader"/>}
    </Grid>
  );
};

export default ResendTimer;
