import React from "react";
import SectionHeader from "../../header/section-header";
import { PreviewContent } from "./preview-content";
import { PreviewTitle } from "./previewTitle";
import useStyles from "../../../../hooks/use-styles";
import { previewJSON } from "../../../../utils/fields.utils";
import useLabels from "../../../../hooks/use-labels";
import { Grid } from "@mui/material";
import FormButtons from "../../form-buttons";
import "./preview-style.css";

const PreviewDetails = (props: any) => {
  const { formData } = props;
  const { titleColor, subTitleColor, reviewHeaderColor } = useStyles([
    "titleColor",
    "subTitleColor",
    "reviewHeaderColor",
  ]);
  const {
    reviewTitle,
    personalInformationTitle,
    firstNameLabel,
    lastNameLabel,
    phoneNumberLabel,
    addressLabel,
    cityLabel,
    stateLabel,
    zipCodeLabel,
    dobLabel,
    ssnLabel,
    identityTitle,
    countryLabel,
  } = useLabels([
    "reviewTitle",
    "personalInformationTitle",
    "firstNameLabel",
    "lastNameLabel",
    "phoneNumberLabel",
    "addressLabel",
    "cityLabel",
    "stateLabel",
    "zipCodeLabel",
    "dobLabel",
    "ssnLabel",
    "identityTitle",
    "countryLabel",
  ]);

  const fieldProps = {
    personalInformationTitle,
    firstNameLabel,
    lastNameLabel,
    phoneNumberLabel,
    addressLabel,
    cityLabel,
    stateLabel,
    zipCodeLabel,
    dobLabel,
    ssnLabel,
    identityTitle,
    countryLabel,
  };

  return (
    <>
      <SectionHeader title={reviewTitle} titleColor={titleColor} />

      {previewJSON(formData, fieldProps).map((item) => (
        <div key={item.title} className="preview-container">
          <PreviewTitle
            title={item.title}
            titleColor={titleColor}
            reviewHeaderColor={reviewHeaderColor}
          />
          <div className="content-container">
            <Grid container rowSpacing={3} columnSpacing={1}>
              {item.content.map((contentItem) => (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  className="content-columns"
                  key={contentItem.label}
                >
                  <PreviewContent
                    label={contentItem.label}
                    value={contentItem.value}
                    labelColor={titleColor}
                    textColor={subTitleColor}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      ))}

      <FormButtons
        handleNext={props.handleNext}
        handlePrevious={props.handlePrevious}
        nextButtonText="Continue"
        customClass={"mt-5"}
      />
    </>
  );
};

export default PreviewDetails;
