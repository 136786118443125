import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomButton from "../../../../../../components/custom-button";
import MaterialDataTable from "../../../../../../components/materialDataTable";
import useStyles from "../../../../../../hooks/use-styles";

import { getCustomDate, showErrorNotification } from "../../../../../../utils/common-utils";
import { getTransactionHistory } from "../../../../../../apis/b2c_api";
import Loader from "../../../../../../components/shared/loader/loader";
import MaterialDateRangeFilter from "../../../../../../components/material-daterange-filter";
import { DateProps } from "../../../../../../interfaces/transaction";
import useLabels from "../../../../../../hooks/use-labels";
import { prepareHistoryDetails } from "./transactionHistory.utils";
import "./transaction-style.css";
import { useNavigate } from "react-router";
import StatementModal from "../../../../../../components/Modal/StatementModal";
import { useSelector, RootStateOrAny } from "react-redux";

const columns = [
  // "Transaction Id",
  "Date ",
  // "Card Last 4",
  "Merchant",
  // `Transaction Type`,
  `Amount`,
  `Current  Balance`,
  '    ',
  `Details`,
   
];

const TransactionHistory: FC = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [statement, showStatement] = useState(false);
  const { reCallTransactions } = useSelector(
    (state: RootStateOrAny) => state.users
  );

  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState<DateProps>({
    dateFrom: getCustomDate(new Date(), 6),
    dateTo: getCustomDate(new Date())
  });

  const [pageInformation, setPageInformation] = useState({
    pageNumber: 1,
    limit: 10,
  });

  const { fontColor } = useStyles(["fontColor"]);
  const {
    recentTransactions,
    accountStatement,
    noTransactionText,
    myCardTitle,
    transactionDetailLabel,
  } = useLabels([
    "recentTransactions",
    "accountStatement",
    "noTransactionText",
    "myCardTitle",
    "transactionDetailLabel",
  ]);

  const { cardId } = props.selectedCard;

  useEffect(() => {
    fetchTransactionHistory(pageInformation, dateRange);
  }, [props.selectedCard, reCallTransactions]);

  const handleTransactionClick = (transaction: any) => {
    navigate("/cards/transaction-detail", {
      state: {
        transaction,
        pageCrumbs: [
          { title: myCardTitle, link: "/cards" },
          { title: transactionDetailLabel },
        ],
      },
    });
  };

  const fetchTransactionHistory = (
    { pageNumber, limit }: any,
    date?: DateProps
  ) => {
    const historyProps = {
      cardId,
      offset: pageNumber,
      limit,
      ...(date && date.dateFrom && { dateFrom: date.dateFrom }),
      ...(date && date.dateTo && { dateTo: date.dateTo }),
    };
    setLoading(true);

    getTransactionHistory(historyProps)
      .then((data) => {
        const { nodes, pageInfo } = data.transactions;

        const transactions = prepareHistoryDetails(nodes, {
          onTransactionClick: (transaction: any) =>
            handleTransactionClick(transaction),
        });

        setTransactions(transactions);

        setPageInformation(() => ({ ...pageInfo, pageNumber, limit }));
        setLoading(false);
      })
      .catch(showErrorNotification);
  };

  const handlePageChange = (pageNumber: number) => {
    const pageDetails = { ...pageInformation, pageNumber };
    fetchTransactionHistory(pageDetails, dateRange);
  };

  const handleLimitChange = (limit: number) => {
    const pageDetails = { ...pageInformation, limit, pageNumber: 1 };
    fetchTransactionHistory(pageDetails, dateRange);
  };

  const applyDateFilter = (date?: DateProps) => {
    date && setDateRange(date);
    fetchTransactionHistory(pageInformation, date);
  };

  const toggleModal = () => {
    showStatement((prev) => !prev);
  };

  return (
    <div className="transaction-history mt-5">
      <StatementModal
        isOpen={statement}
        onClose={toggleModal}
        cardId={cardId}
      />

      <div className="filter-box mt-3 mb-5">
        <MaterialDateRangeFilter
          applyDateFilter={applyDateFilter}
          defaultFromDate={dateRange.dateFrom}
          defaultToDate={dateRange.dateTo}
          showCardFilter ={false}

        />
      </div>

      <Grid
        className="mb-3 recent-transaction-header"
        container
        alignItems={"center"}
        justifyContent="center"
      >
        <Grid item md={4} className="mb-2">
          <p className="text-md" style={{ color: fontColor }}>
            {recentTransactions}
          </p>
        </Grid>
        <Grid item md={8} className="transaction-statement-button">
          <CustomButton
            type="outline"
            text={accountStatement}
            className="statement-button weight-500 transparent-background"
            onClick={toggleModal}
          />
        </Grid>
      </Grid>

      {loading ? (
        <Loader className="custom-loader" />
      ) : (
        <MaterialDataTable
          rows={transactions}
          columns={columns}
          noDataText={noTransactionText}
          pageProps={{
            ...pageInformation,
            changePage: handlePageChange,
            changeLimit: handleLimitChange,
          }}
        />
      )}
    </div>
  );
};

export default TransactionHistory;
