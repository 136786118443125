import useStyles from "../../hooks/use-styles";

const LinkText = (props: any) => {
  const { title, link, linkColor } = props;

  const { lightBlueColor } = useStyles(["lightBlueColor"]);

  return (
    <span
    className="pointer text-underline"
      style={{ color: linkColor || lightBlueColor }}
      onClick={() => link && window.open(link, "_blank")}
    >
      {title}
    </span>
  );
};

export default LinkText;
