import { useEffect, useState } from 'react'
import { getShipmentInfo } from '../../../../apis/b2c_api'
import useLabels from '../../../../hooks/use-labels'
import useStyles from '../../../../hooks/use-styles'
import { showErrorNotification } from '../../../../utils/common-utils'
import CustomButton from '../../../custom-button'
import Loader from '../../../shared/loader/loader'
import SectionHeader from '../../../signup-form/header/section-header'

interface ShipmentTrackingModalProps {
  onClose: () => void
  modalProps: any
}

const ShipmentTrackingModalContent = (props: ShipmentTrackingModalProps) => {
  const { onClose, modalProps } = props
  const [loading, setLoading] = useState(true)
  const [cardShipmentInfo, setShipmentInfo] = useState({}) as any

  useEffect(() => {
    getShipmentInfo(modalProps.cardId)
      .then(({ card }) => {
        setShipmentInfo(card.shipmentInfo)
        setLoading(false)
        
      })
      .catch((error) => {
        setLoading(false)
        showErrorNotification(error)
      })
  }, [modalProps.cardId])

  const {
    shipmentTrackingModalTitle,
    shipmentTrackingModalDescription,
    comingSoonShipmentDescription,
    trackingIdLabel,
    shipmentDateLabel,
    shipmentMethodLabel,
    closeLabel
  } = useLabels([
    'shipmentTrackingModalTitle',
    'shipmentTrackingModalDescription',
    'comingSoonShipmentDescription',
    'trackingIdLabel',
    'shipmentDateLabel',
    'shipmentMethodLabel',
    'closeLabel'
  ])

  const { fontColor, subTitleColor } = useStyles(['fontColor', 'subTitleColor'])

  return (
    <>
      <SectionHeader
        className="mat-modal-header"
        title={shipmentTrackingModalTitle}
      />

      {loading ? (
        <Loader className="custom-loader mb-3" />
      ) : (
        <div className="mat-modal-body pl-2 pr-2" style={{ color: fontColor }}>
          {!cardShipmentInfo ? (
            <p className="text-sm text-center mb-4">
              {comingSoonShipmentDescription}
            </p>
          ) : (
            <>
              <p className="text-sm text-center mb-4">
                {shipmentTrackingModalDescription}
              </p>

              <div className="d-table mr-auto ml-auto">
                <div className="text-left mb-3">
                  <p
                    className="text-sm weight-500"
                    style={{ color: subTitleColor }}
                  >
                    {trackingIdLabel}
                  </p>
                  <p className="text-sm weight-600 break-word">
                    {cardShipmentInfo.trackingNumber}
                  </p>
                </div>

                <div className="text-left mb-3">
                  <p
                    className="text-sm weight-500"
                    style={{ color: subTitleColor }}
                  >
                    {shipmentDateLabel}
                  </p>
                  <p className="text-sm weight-600 break-word">
                    {cardShipmentInfo.shipmentDate}
                  </p>
                </div>

                <div className="text-left mb-3">
                  <p
                    className="text-sm weight-500"
                    style={{ color: subTitleColor }}
                  >
                    {shipmentMethodLabel}
                  </p>
                  <p className="text-sm weight-600 break-word">
                    {cardShipmentInfo.shipmentMethodInfo}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <CustomButton
        className="mat-modal-footer modal-footer-button mt-1"
        text={closeLabel}
        type="primary"
        onClick={onClose}
      />
    </>
  )
}
export default ShipmentTrackingModalContent
