import useStyles from '../../hooks/use-styles'
import './information-header-style.css'

const InformationHeader = (props: { title: string; className?: string }) => {
  const { title, className } = props
  const { reviewHeaderColor } = useStyles(['reviewHeaderColor'])

  return (
    <p
      className={`information-header ${className!}`}
      style={{
        color: '#323232',
        backgroundColor: reviewHeaderColor
      }}
    >
      {title}
    </p>
  )
}

export default InformationHeader
