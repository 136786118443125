import { useEffect, useState } from 'react'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import { prepareCardDetails } from '../../../../../../apis/card_api'
import CardView from '../../../../../../components/CardView'
import MaterialModal from '../../../../../../components/Modal'
import CardModalContent from '../../../../../../components/Modal/CardModalContent'
import useLabels from '../../../../../../hooks/use-labels'
import RemitLogo from '../../../../../../assets/open_cp.svg'
import DiscoverLogo from '../../../../../../assets/Images/discover-logo.svg'

import CardDetailList from './CardDetailList'

import { formatLastFour } from '../../../../../../utils/common-utils'

import './card-detail-style.css'

const CardDetail = ({ selectedCard, updateCard, cardholderId }: any) => {
  const [showSecure, setShowSecure] = useState(false)
  const [cards, setCards] = useState([])

  const [cardMask, setCardMask] = useState({
    remitImage: RemitLogo,
    security: '***',
    validTill: '**/**',
    discoverImage: DiscoverLogo
  })

  const { secureLabel, ...otherLabels } = useLabels([
    'secureLabel',
    'primaryLabel',
    'secondaryLabel',
    'virtualLabel',
    'physicalOrderedLabel',
    'physicalLabel'
  ])

  useEffect(() => {
    const cards = prepareCardDetails(selectedCard, cardholderId, otherLabels)
    const { nameOnCard, cardNumber } = cards[0]
    setCardMask((prev) => ({
      ...prev,
      title: nameOnCard,
      cardNumber: formatLastFour(cardNumber)
    }))

    setCards(cards as [])
  }, [setCards, selectedCard])

  return (
    <div className="card-detail-container d-flex gap-4">
      {showSecure ? (
        <MaterialModal
          open={showSecure}
          handleClose={() => setShowSecure(false)}
          width={'370px'}
          boxClass={'card-widget-box'}
        >
          <CardModalContent
            cardId={selectedCard.cardId}
            onClose={() => setShowSecure(false)}
          />
        </MaterialModal>
      ) : null}

      <div className="card-view-section">
        <CardView cardProps={cardMask} className="card-mask-view" />

        <p
          className="pointer secure-tag mt-3"
          onClick={() => setShowSecure(true)}
        >
          {secureLabel} <AiOutlineEyeInvisible />
        </p>
      </div>

      <div className="card-detail-section d-flex">
        <CardDetailList cards={cards} updateCard={updateCard} />
      </div>
    </div>
  )
}

export default CardDetail
