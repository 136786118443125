import { Grid } from '@mui/material'
import moment from 'moment'
import { FC, useState } from 'react'
import useConfigs from '../../hooks/use-config'
import useLabels from '../../hooks/use-labels'
import { DateProps } from '../../interfaces/transaction'
import CustomButton from '../custom-button'
import FormField from '../form/form-field'

interface MaterialDateRangeFilterProps {
  applyDateFilter: (dates?: DateProps, card?: string) => void
  defaultFromDate?: any
  defaultToDate?: any
  showCardFilter?: any
}

const MaterialDateRangeFilter: FC<MaterialDateRangeFilterProps> = ({
  applyDateFilter, defaultFromDate, defaultToDate, showCardFilter
}): JSX.Element => {
  const { startDateLabel, endDateLabel } = useLabels([
    'startDateLabel',
    'endDateLabel',
  ])
  const { dateRangeFormat } = useConfigs(['dateRangeFormat'])
  const numberRegex = /^[0-9\b]+$/;
  const [dateValues, setDates] = useState<DateProps>({
    dateFrom: defaultFromDate || null,
    dateTo: defaultToDate || null,
  })

  const [cardValue, setCardValue] = useState<string>('')

  const fields = [
    {
      type: 'date',

      elementProps: {
        name: 'dateFrom',
        label: startDateLabel,
        placeholder: dateRangeFormat.toLowerCase(),
        format: dateRangeFormat,
        required: false
      }
    },
    {
      type: 'date',

      elementProps: {
        name: 'dateTo',
        label: endDateLabel,
        placeholder: dateRangeFormat.toLowerCase(),
        format: dateRangeFormat,
        required: false
      }
    },

  ];
  const cardFilterfields = [
    {
      type: 'date',

      elementProps: {
        name: 'dateFrom',
        label: startDateLabel,
        placeholder: dateRangeFormat.toLowerCase(),
        format: dateRangeFormat,
        required: false
      }
    },
    {
      type: 'date',

      elementProps: {
        name: 'dateTo',
        label: endDateLabel,
        placeholder: dateRangeFormat.toLowerCase(),
        format: dateRangeFormat,
        required: false
      }
    },
    {
      type: 'text',

      elementProps: {
        name: 'card',
        label: 'Card',
        placeholder: '****',
        maxLength: 4,
        inputMode: 'numeric',
        required: false
      }
    }
  ];
  

  const onFieldChange = (name: string, value: any) => {
    if (name === 'card' ) {
      if (value === '' || numberRegex.test(value)) { 
      setCardValue(value as string)}
    } else {
      value &&
        setDates((dates) => ({
          ...dates,
          [name]: moment(value).format(dateRangeFormat)
        }))
    }
  }

  const onSearch = () => {
    applyDateFilter(dateValues, cardValue)
  }

  const onReset = () => {
    const resetDate = { dateFrom: defaultFromDate || null, dateTo: defaultToDate || null };
    setDates(resetDate)
    setCardValue('')
    applyDateFilter(resetDate, '')
  }

  return (
    <Grid container className="mt-3 align-center gap-4">
      {(showCardFilter ? cardFilterfields : fields).map((fieldItem) => {
        const { name } = fieldItem.elementProps
        return (
          <Grid item sm={3} xs={12} md={2} key={name}>
            <FormField
              className="mb-0"
              {...fieldItem}
              value={name === 'card' ? cardValue : dateValues[name as keyof typeof dateValues]}
              onChange={(name: string, value: Date | string) =>
                onFieldChange(name, value)
              }
            />
          </Grid>
        )
      })}

      <CustomButton
        type="primary"
        text="Search"
        className="text-sm"
        style={{ width: '6rem' }}
        onClick={onSearch}
      />
      <CustomButton
        type="outline"
        text="Reset"
        className="text-sm weight-500"
        style={{ width: '6rem' }}
        onClick={onReset}


        disabled={!dateValues.dateFrom && !dateValues.dateTo}
      />
    </Grid>
  )
}

export default MaterialDateRangeFilter
