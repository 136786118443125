import { FC } from 'react'
import useLabels from '../../../../hooks/use-labels'
import useStyles from '../../../../hooks/use-styles'
import CustomButton from '../../../custom-button'
import SectionHeader from '../../../signup-form/header/section-header'
import SignupInvitations from './SignupInvitations'


interface SignupModalProps {
  onClose: () => void
  cardId: string
}

const SignupModalContent: FC<SignupModalProps> = ({
  onClose,
  cardId
}) => {
  const { closeLabel, signupInvitation, signupInvitationModalDescription } = useLabels([
    'signupInvitation',
    'signupInvitationModalDescription',
    'routingNumberLabel',
    'closeLabel'
  ])

  const { fontColor } = useStyles(['fontColor'])

  return (
    <>
      <SectionHeader
        title={signupInvitation}
        subTitle={signupInvitationModalDescription}
        subTitleColor={fontColor}
        className={'half-width'}
        subTitleClass={'mt-3'}
      />

      <div className="mat-modal-body fullWidth">
        <SignupInvitations cardId={cardId} />
      </div>

      <CustomButton
        className="mat-modal-footer modal-footer-button mt-2"
        text={closeLabel}
        type="primary"
        onClick={onClose}
      />
    </>
  )
}

export default SignupModalContent