import { useState } from 'react'
import { addRecipient } from '../../../../../apis/b2c_api'
import useLabels from '../../../../../hooks/use-labels'
import useStyles from '../../../../../hooks/use-styles'
import {
  RECIPIENT_TYPE,
  showErrorNotification
} from '../../../../../utils/common-utils'
import { errorMessage } from '../../../../../utils/validation'
import CustomButton from '../../../../custom-button'
import FlexButtons from '../../../../flex-button'
import FormField from '../../../../form/form-field'
import Loader from '../../../../shared/loader/loader'
import customToast from '../../../../shared/services/toaster-service'

interface ConfirmRecipientProps {
  onPrevious: (values: any) => void
  member: any
  onClose: () => void
  onConfirmSuccess: () => void
}

const ConfirmRecipient = (props: ConfirmRecipientProps) => {
  const [loader, setLoader] = useState(false)

  const [nickName, setNickName] = useState('')
  const [error, setError] = useState('')

  const {
    confirmLabel,
    confirmRecipientDescription,
    cancelLabel,
    nickNameLabel,
    nickNamePlaceholder,
    previousLabel,
    addRecipientSuccessMessage
  } = useLabels([
    'confirmLabel',
    'confirmRecipientDescription',
    'cancelLabel',
    'nickNameLabel',
    'nickNamePlaceholder',
    'previousLabel',
    'addRecipientSuccessMessage'
  ])

  const { cardholderId, firstName, lastName, email } = props.member

  const { labelColor, subTitleColor, lightBlueColor } = useStyles([
    'labelColor',
    'subTitleColor',
    'lightBlueColor'
  ])

  const onNickNameChange = (value: string) => {
    if (!value.trim()) {
      setError(errorMessage.required)
    } else {
      setError('')
    }

    setNickName(value)
  }

  const onConfirmAddRecipient = () => {
    if (!nickName) {
      setError(errorMessage.required)
    } else {
      const recipientProps = {
        cardholderId,
        firstName,
        lastName,
        email,
        nickName,
        recipientType: RECIPIENT_TYPE,
        country: null
      }

      setLoader(true)
      addRecipient(recipientProps)
        .then(() => {
          customToast.success(addRecipientSuccessMessage)
          setLoader(false)
          props.onConfirmSuccess()
        })
        .catch((error) => {
          setLoader(false)
          showErrorNotification(error)
        })
    }
  }

  return (
    <>
      <div className="align-center">
        <p className="weight-500 text-sm pb-4" style={{ color: labelColor }}>
          {confirmRecipientDescription}
        </p>
        <div className="text-center">
          <p className="text-sm weight-600" style={{ color: labelColor }}>
            {firstName} {lastName}
          </p>

          <p className="text-xs" style={{ color: subTitleColor }}>
            {email}
          </p>
        </div>

        <div className="text-center mt-3">
          <FormField
            type="text"
            elementProps={{
              name: 'nickName',
              placeholder: nickNamePlaceholder,
              label: nickNameLabel
            }}
            value={nickName}
            error={error}
            onChange={(name: string, value: string) => onNickNameChange(value)}
          />
        </div>

        {loader && <Loader className="custom-loader" />}

        <FlexButtons className="mt-5 layout-buttons">
          <CustomButton
            className="weight-500 transparent-background"
            type="previous"
            text={previousLabel}
            onClick={props.onPrevious}
          />

          <CustomButton
            type="primary"
            text={confirmLabel}
            onClick={onConfirmAddRecipient}
          />
        </FlexButtons>
        <p
          className="pointer text-sm text-center mt-3 mb-4"
          style={{ color: lightBlueColor }}
          onClick={props.onClose}
        >
          {cancelLabel}
        </p>
      </div>
    </>
  )
}
export default ConfirmRecipient
