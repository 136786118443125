import axios from "axios";
import {INIT_APP_ID, INIT_KEY, INIT_URL} from "../../credentials";

export const initialize = async () => {
    const {data} = await axios.get(`${INIT_URL()}/init`, {
        headers: {
            "x-api-key": INIT_KEY(),
            "x-app-id": INIT_APP_ID(),
        },
    });
    const initData = data?.data ? JSON.stringify(data?.data, undefined, 2) : "{}";
    localStorage.setItem("init", initData);
}
