import { FC } from 'react'
import { Pagination, PaginationItem } from '@mui/material'
import useStyles from '../../hooks/use-styles'
import './material-pagination-style.css';

interface MaterialPaginationProps {
  onPageChange: (
    event: React.ChangeEvent<unknown> | undefined,
    value: number
  ) => void
  pageProps: any
}

const MaterialPagination: FC<MaterialPaginationProps> = ({
  onPageChange,
  pageProps
}) => {
  const { titleColor } = useStyles(['titleColor'])

  const onFirstClick = (): void => {
    onPageChange(undefined, 1)
  }

  const onLastClick = (): void => {
    onPageChange(undefined, pageProps.totalPages)
  }

  return (
    <div className="d-flex align-center text-xs">
      <p className="pointer first-page-text" onClick={onFirstClick}>
        First
      </p>
      <Pagination
        count={pageProps.totalPages}
        color="primary"
        onChange={onPageChange}
        page={pageProps.pageNumber}
        size="small"
        siblingCount={2}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            className='material-pagination-item'
            sx={{
                '&.MuiPaginationItem-page':{
                    color:'#98A0A6'
                },
              '&.Mui-selected': {
                backgroundColor: titleColor,
                color: 'white',
              },
              '&.Mui-selected:hover':{
                backgroundColor: titleColor,
                color: 'white',
              },
              '&.MuiPaginationItem-root':{
                  margin:'0 .32rem'
              }
            }}
          />
        )}
      />
      <p className="pointer last-page-text" onClick={onLastClick}>
        Last
      </p>
    </div>
  )
}

export default MaterialPagination