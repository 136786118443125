import { FC } from 'react'

interface ContentContainerProps {
  className?: string
  style?: object
  children: JSX.Element | Array<JSX.Element>
}

const ContentContainer: FC<ContentContainerProps> = ({
  className,
  children,
  style
}) => {
  return (
    <div className={`pl-1 pr-1 pt-3 pb-3 ${className!}`} style={style}>
      {children}
    </div>
  )
}

export default ContentContainer