import MaterialModal from '..'
import DDModalContent from './DDModalContent'

interface DirectDepositModalProps {
  isOpen: boolean
  onClose: () => void
  actionProps: any
}

const DirectDepositModal = (props: DirectDepositModalProps) => {
  return (
    <MaterialModal
      open={props.isOpen}
      handleClose={props.onClose}
      width={'33rem'}
    >
      <DDModalContent onClose={props.onClose} modalProps={props.actionProps} />
    </MaterialModal>
  )
}

export default DirectDepositModal
