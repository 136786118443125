import MaterialModal from '..'
import ShipmentTrackingModalContent from './ShipmentTrackingModalContent'

interface ShipmentTrackingModalProps {
  isOpen: boolean
  onClose: () => void
  actionProps: any
}

const ShipmentTrackingModal = (props: ShipmentTrackingModalProps) => {
  return (
    <MaterialModal
      open={props.isOpen}
      handleClose={props.onClose}
      width={'33rem'}
    >
      <ShipmentTrackingModalContent
        onClose={props.onClose}
        modalProps={props.actionProps}
      />
    </MaterialModal>
  )
}

export default ShipmentTrackingModal
