import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import {
  deleteInvitation,
  resendInvitation,
  signupInvitations,
} from "../../../../../apis/b2c_api";
import {
  showErrorNotification,
} from "../../../../../utils/common-utils";

import IcActivate from '../../../../../assets/ic_activate.svg'


import MaterialDataTable from "../../../../materialDataTable";
import Loader from "../../../../shared/loader/loader";
import useLabels from "../../../../../hooks/use-labels";
import { prepareInvitationDetails } from "./invitation.util";
import customToast from "../../../../shared/services/toaster-service";
import ConfirmationModal from "../../../ConfirmationModal";
import MaterialModal from "../../..";
import useStyles from "../../../../../hooks/use-styles";

interface SignupInvitationProps {

  cardId: string
}

const columns = [
  "Sr.",
  "Email",
  "Name",
  "Phone Number",
  `Status`,
  "Expiry Time",
  "Actions",
];

const SignupInvitations:FC<SignupInvitationProps> = ({

  cardId
}) => {
  const [confirmOpen, showConfirm] = useState(false);
  const [token, setToken] = useState("");
  const card = cardId; 
  const [invitations, setInvitations] = useState([]);
  const [loading, setLoader] = useState(false);


  const {
    noInvitationsText,
    deleteInvitationSuccessMessage,
    resendInvitationSuccessMessage,
    confirmDeleteLabel,
    cancelLabel,
    deleteLabel,
    confirmationText
  } = useLabels([
    "noInvitationsText",
    "deleteInvitationSuccessMessage",
    "resendInvitationSuccessMessage",
    'confirmDeleteLabel',
    'confirmationText',
    'cancelLabel',
    'deleteLabel',
  ]);

  const { titleColor } = useStyles(['titleColor']);

  const navigate = useNavigate();

  useEffect(() => {
    fetchAllInvitations();
  }, []);

  const fetchAllInvitations = () => {
    setLoader(true);
    signupInvitations()
      .then((response) => {
        const { signupInvitations } = response;
        const detailProps = {
          signupInvitations,
          onDelete,
          onEdit,
          onResend,
        };
        const invitationsDetails = prepareInvitationDetails(detailProps);

        setInvitations(invitationsDetails);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        showErrorNotification(error);
      });
  };


  const onEdit = (invitationDetail: any) => {
    // 
    navigate("/cards/secondary-card", {
      state: {
        cardId: card,
        invitationDetail,
        balance: "",
        pageCrumbs: [
          { title: "My Cards", link: "/cards" },
          { title: "Secondary Card" },
        ],
      },
    });
  };

  const onDelete = (token: string) => {
    setToken(token);
    toggleConfirm();
  };

  const onDeleteConfirm = () => {
    setLoader(true);
    deleteInvitation(token)
      .then((response) => {
        setLoader(false);
        customToast.success(deleteInvitationSuccessMessage);
        toggleConfirm();
        fetchAllInvitations();
      })
      .catch((error) => {
        setLoader(false);
        showErrorNotification(error);
      });
  };

  const onResend = (token: string) => {
    setLoader(true);
    resendInvitation(token)
      .then((response) => {
        setLoader(false);
        customToast.success(resendInvitationSuccessMessage);
        fetchAllInvitations();
      })
      .catch((error) => {
        setLoader(false);
        showErrorNotification(error);
      });
  };

  const toggleConfirm = () => {
    showConfirm((previousValue) => !previousValue);
  };

  const renderConfirmModal = () => {
    if (!confirmOpen) return null;

    const modalProps = {
      confirmationImage: IcActivate,
      confirmationTitle: confirmDeleteLabel,
      confirmationText,
      closeLabel: cancelLabel,
      confirmButtonText: deleteLabel,
      onClose: toggleConfirm,
      titleColor,
      onConfirm: onDeleteConfirm,
      loading
    }

    return (
      <MaterialModal open={true} handleClose={toggleConfirm} width={"25rem"}>
        <ConfirmationModal modalProps={modalProps} />
      </MaterialModal>
    );
  };

  return (
    <>
      {loading ? (
        <Loader className="custom-loader" />
      ) : (
        <MaterialDataTable
          rows={invitations}
          columns={columns}
          noDataText={noInvitationsText}
          noDataStyle={{ transform: "translateX(-50%)", width: "fit-content" }}
          scrollableHeight={invitations.length ? 280 : "100%"}
        />
      )}
      {renderConfirmModal()}
    </>
  );
};

export default SignupInvitations;
