import { Fade, Tooltip } from '@mui/material'
import { withStyles } from '@mui/styles'
import useStyles from '../../hooks/use-styles'

const MuiToolTip = (props: any) => {
  const { fontColor: toolTipBackground } = useStyles(['fontColor'])
  const { children, ...tooltipProps } = props

  const EnhancedToolTip = withStyles({
    tooltip: {
      color: '#fff',
      backgroundColor: toolTipBackground,
      fontWeight: 400,
      fontSize: '.6rem'
    },
    arrow: {
      '&:before': {
        border: `1px solid ${toolTipBackground}`
      },
      color: toolTipBackground
    }
  })(Tooltip)

  return (
    <EnhancedToolTip
      arrow
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      {...tooltipProps}
    >
      {children && children}
    </EnhancedToolTip>
  )
}

export default MuiToolTip
