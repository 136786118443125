import { Breadcrumbs, Link, Stack } from '@mui/material'
import { FC } from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import useStyles from '../../hooks/use-styles'

import './crumb-style.css'
import { useNavigate } from 'react-router'

interface MaterialCrumbsProps {
  breadCrumbs: Array<{ title: string; link?: string }>
}

const MaterialCrumbs: FC<MaterialCrumbsProps> = ({ breadCrumbs }) => {
  const { topBarTitleColor } = useStyles(['topBarTitleColor'])
  const navigate = useNavigate()

  const handleClick = (crumbItem: { title: string; link?: string }) => {
    navigate(crumbItem.link || '', { state: { pageCrumbs: [crumbItem] } })
  }

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={
          <ArrowRightAltIcon
            className="crumb-icon"
            sx={{ color: topBarTitleColor }}
          />
        }
      >
        {breadCrumbs.map((crumbItem, index) => {
          const { link, title } = crumbItem
          const isClickable = link && breadCrumbs.length > 1

          if (index > 0) {
            return (
              <Link
                key={index}
                underline={isClickable ? 'hover' : 'none'}
                className={`text-xs weight-500 crumb-current-title ${
                  isClickable && 'pointer'
                }`}
                style={{
                  color: topBarTitleColor
                }}
                onClick={() => isClickable && handleClick(crumbItem)}
              >
                {title}
              </Link>
            )
          }

          return (
            <Link
              key={index}
              underline={isClickable ? 'hover' : 'none'}
              className={`${isClickable?'text-md':'text-md'} weight-500 ${isClickable && 'pointer'}`}
              style={{ color: topBarTitleColor }}
              onClick={() => isClickable && handleClick(crumbItem)}
            >
              {title}
            </Link>
          )
        })}
      </Breadcrumbs>
    </Stack>
  )
}

export default MaterialCrumbs
