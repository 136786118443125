import AccountInputs from "./components/accountInformation/account-inputs";
import PreviewDetails from "./components/detailsPreview";
import PersonalAddressInputs from "./components/personalInformation/personal-address-inputs";
import PersonalDobSSNInputs from "./components/personalInformation/personal-dob-ssn-inputs";
import PersonalPhoneName from "./components/personalInformation/personal-phone-name-inputs";
import VerifyEmailInput from "./components/verifyEmailInput/verify-email-input";
import VerifyCode from "./components/verifyCode/verify-code";

import ESignAgreement from "./components/cardholderAgreement/e-sign-agreement";
import CardHolderAgreement from "./components/cardholderAgreement";

export const signUpFormSteps: any = {
  emailForm: {
    next: "pincodeForm",
    previous: "",
    component: (props: any) => <VerifyEmailInput {...props} />,
    listStep: 1,
  },
  pincodeForm: {
    component: (props: any) => <VerifyCode {...props} />,
    listStep: 1,
    next: "phoneNameForm",
    previous: "emailForm",
  },
  phoneNameForm: {
    component: (props: any) => <PersonalPhoneName {...props} />,
    listStep: 2,
    next: "addressForm",
    previous: "emailForm",
  },
  addressForm: {
    component: (props: any) => <PersonalAddressInputs {...props} />,
    listStep: 2,
    next: "dobSSNForm",
    previous: "phoneNameForm",
  },
  dobSSNForm: {
    component: (props: any) => <PersonalDobSSNInputs {...props} />,
    listStep: 2,
    next: "accountForm",
    previous: "addressForm",
  },
  accountForm: {
    component: (props: any) => <AccountInputs {...props} />,
    listStep: 3,
    next: "previewForm",
    previous: "dobSSNForm",
  },
  previewForm: {
    component: (props: any) => <PreviewDetails {...props} />,
    listStep: 4,
    next: "eSignAgreement",
    previous: "accountForm",
  },
  eSignAgreement: {
    component: (props: any) => <ESignAgreement {...props} />,
    listStep: 5,
    next: "agreements",
    previous: "previewForm",
  },
  agreements: {
    component: (props: any) => <CardHolderAgreement {...props} />,
    listStep: 6,
    next: "",
    previous: "eSignAgreement",
  },
};
