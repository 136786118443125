import React, {useEffect, useState} from "react";
import Loader from "./components/shared/loader/loader";
import {initialize} from "./apis/init_api";
import App from "./App";

const Init = (): JSX.Element => {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        initialize().then(() => setInitialized(true)).catch(console.error);
    }, []);

    if (!initialized) {
        return <Loader/>;
    }

    return <App/>;
};

export default Init;
