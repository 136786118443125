import { useEffect } from "react";
import "./scroll-style.css";

const ScrollView = (props: any) => {
  const { onScrollToBottom } = props;

  useEffect(() => {
    const element = document.getElementsByClassName("scroll-container")[0];
    checkBottom(element);
  }, []);

  const checkBottom = ({ scrollHeight, scrollTop, clientHeight, offsetHeight  }: any) => {

    const bottom = 
      (parseInt(scrollHeight) - parseInt(scrollTop) - 1) <= parseInt(clientHeight);

    if (bottom && onScrollToBottom) {
      onScrollToBottom();
    }
  };

  const handleScroll = (e: any) => {
    checkBottom(e.target);
  };

  return (
    <div className="scroll-container" onScroll={handleScroll}>
      {props.children && props.children}
    </div>
  );
};

export default ScrollView;
