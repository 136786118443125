import CustomDropdown from '../../../../../components/form-components/CustomDropdown'
import useLabels from '../../../../../hooks/use-labels'
import {
  formatCurrency,
  formatName,
  padLastFour
} from '../../../../../utils/common-utils'
import './card-header-style.css'

interface CardHeaderProps {
  onSelectCard: (value: string) => void
  selectedCard: any
  cards: any
}

const CardHeader = (props: CardHeaderProps) => {
  const { balance, lastFour } = props.selectedCard
  const { availableBalance, currencyCode } = balance
  const { selectCardLabel, availableBalanceLabel } = useLabels([
    'selectCardLabel',
    'availableBalanceLabel'
  ])

  const getCardOptions = (cards: any) => {
    return cards.map((cardItem: any, index: number) => {
      const { firstName, lastName } = cardItem.cardholder
      return {
        name: (
          <p
            key={index}
            className={`${
              lastFour === cardItem.lastFour ? 'text-underline' : ''
            }`}
          >
            {`${padLastFour(cardItem.lastFour)} - ( ${formatName(
              firstName,
              lastName
            )} )`}
          </p>
        ),
        value: cardItem
      }
    })
  }

  return (
    <div className="d-flex justify-between align-center flex-header">
      <p className="weight-500 d-flex align-center">
        <span className="text-base">{availableBalanceLabel}</span>
        <span className="text-lg pl-1">
          {formatCurrency(availableBalance, currencyCode)}
        </span>
      </p>

      <div className="d-flex align-center gap-3">
        <p className="weight-500 label">{selectCardLabel}</p>

        <CustomDropdown
          options={getCardOptions(props.cards)}
          value={padLastFour(lastFour)}
          placeholder="Select Card"
          onSelect={props.onSelectCard}
        />
      </div>
    </div>
  )
}

export default CardHeader
