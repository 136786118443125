import useStyles from '../../../../../hooks/use-styles'
import CardDetail from './CardDetail'
import TransactionHistory from './TransactionHistory'

export const CardContent = (props: any) => {
  const { horizontalLineColor } = useStyles(['horizontalLineColor'])
  return (
    <>
      <CardDetail {...props} />
      <hr
        className="mt-4 mb-4"
        style={{ backgroundColor: horizontalLineColor }}
      />
      <TransactionHistory {...props}/>
    </>
  )
}

export default CardContent
