import { useState } from 'react'
import useLabels from '../../../../hooks/use-labels'
import StepsView from '../../../signup-form/components/stepsView'
import SectionHeader from '../../../signup-form/header/section-header'
import SearchRecipient from './SearchRecipient'
import ConfirmRecipient from './ConfirmRecipient'

interface AddRecipientModalContentProps {
  onClose: () => void
  onSuccess: () => void
}

const AddRecipientModalContent = (props: AddRecipientModalContentProps) => {
  const [step, setStep] = useState(1)
  const [member, setMember] = useState({})

  const { addRecipientLabel, confirmLabel } = useLabels([
    'addRecipientLabel',
    'confirmLabel'
  ])

  const onFormSubmit = (member: any) => {
    setMember(member)
    handleNext()
  }

  const handleNext = () => {
    setStep((prev) => prev + 1)
  }

  const handlePrevious = () => {
    setStep((prev) => prev - 1)
  }

  const componentSteps: any = {
    1: {
      title: addRecipientLabel,
      component: () => (
        <SearchRecipient onFormSubmit={onFormSubmit} onClose={props.onClose} />
      )
    },
    2: {
      title: confirmLabel,
      component: () => (
        <ConfirmRecipient
          onPrevious={handlePrevious}
          member={member}
          onClose={props.onClose}
          onConfirmSuccess={props.onSuccess}
        />
      )
    }
  }

  return (
    <div className='align-center'>
      <SectionHeader
        className="mat-modal-header"
        title={componentSteps[step].title}
      />
      <StepsView className={'mb-4'} numberofSteps={2} listStep={step} />
      {componentSteps[step].component()}
    </div>
  )
}
export default AddRecipientModalContent
