import MaterialModal from "..";
import ResetPasswordContent from "./ResetPasswordContent";

interface ResetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ResetPasswordModal = (props: ResetPasswordModalProps) => {
  return (
    <MaterialModal
      open={props.isOpen}
      handleClose={props.onClose}
      width={"25rem"}
      height={"75rem"}
    >
      <ResetPasswordContent onClose={props.onClose} />
    </MaterialModal>
  );
};

export default ResetPasswordModal;
