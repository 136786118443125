import { Grid } from '@mui/material'
import useConfigs from '../../../../hooks/use-config'
import useLabels from '../../../../hooks/use-labels'
import useStyles from '../../../../hooks/use-styles'
import CustomButton from '../../../custom-button'
import SectionHeader from '../../../signup-form/header/section-header'

import './DD-modal-style.css'
interface DDModalProps {
  onClose: () => void
  modalProps: any
}

const DDModalContent = (props: DDModalProps) => {
  const { onClose, modalProps } = props

  const { accountNumber, routingNumber } = modalProps.additionalData

  const {
    directDepositModalTitle,
    accountNumberLabel,
    routingNumberLabel,
    closeLabel
  } = useLabels([
    'directDepositModalTitle',
    'accountNumberLabel',
    'routingNumberLabel',
    'closeLabel'
  ])

  const { fontColor, subTitleColor } = useStyles(['fontColor', 'subTitleColor'])
  const { directDepositModalInfo } = useConfigs(['directDepositModalInfo'])

  return (
    <>
      <SectionHeader
        className="mat-modal-header"
        title={directDepositModalTitle}
      />

      <div className="mat-modal-body pl-2 pr-2" style={{ color: fontColor }}>
        <p className="text-sm text-center">{directDepositModalInfo}</p>

        <Grid
          container
          columnSpacing={{ md: 3, xs: 0 }}
          rowSpacing={1}
          className="mt-4 ml-0 mb-3 account-routing-info"
        >
          <Grid item md={6} xs={12} className="pl-1">
            <p className="text-sm weight-500" style={{ color: subTitleColor }}>
              {accountNumberLabel}
            </p>
            <p className="text-sm weight-600 break-word">{accountNumber}</p>
          </Grid>

          <Grid item md={6} xs={12}>
            <p className="text-sm weight-500" style={{ color: subTitleColor }}>
              {routingNumberLabel}
            </p>
            <p className="text-sm weight-600 break-word">{routingNumber}</p>
          </Grid>
        </Grid>
      </div>

      <CustomButton
        className="mat-modal-footer modal-footer-button mt-1"
        text={closeLabel}
        type="primary"
        onClick={onClose}
      />
    </>
  )
}
export default DDModalContent
