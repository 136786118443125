import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { signUpFormSteps } from "./step.utils";
import StepsView from "./components/stepsView";
import { logoutUser } from "../../store/user/user-reducer";
import useConfigs from "../../hooks/use-config";
import useStyles from "../../hooks/use-styles";
import openCPImage from "../../assets/my529Logo.png";
import "./style.css";
import { useLocation, useNavigate } from "react-router";
import useLabels from "../../hooks/use-labels";
import { getLegalAgreement, signupWithExtRefNo } from "../../apis/b2c_api";
import Loader from "../shared/loader/loader";
import SignupError from "./components/errorPage";
import DOMPurify from "dompurify";

const SignupForm = () => {
  const [currentForm, setCurrentForm] = useState("emailForm");
  const [loading, setLoading] = useState(false);
  const [isSignUpFlow, setSignUpFlow] = useState(true);
  const [isErrorFlow, setErrorFlow] = useState(false);
  const [isTokenError, setTokenError] = useState<string>('');

  const [ispendingOutOfWallet, setIspendingOutOfWallet] = useState(false);
  const { SignUpWithRequiredExtRefNo } = useLabels([
    "SignUpWithRequiredExtRefNo",
  ]);

  const search = useLocation().search;

  const jwt = new URLSearchParams(search).get("token")!;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [legalAgreements, setLegalAgreements] = useState([]);

  const { agreementText } = useConfigs([
		"agreementText",
  ]);

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  useEffect(() => {
    if (SignUpWithRequiredExtRefNo === "true") {
      if (jwt === null) {
        setLoading(false);
        setSignUpFlow(true);
        setErrorFlow(true);
      } else {
        setLoading(true);
        signupWithExtRefNo(jwt)
          .then((response: any) => {
            const { signupWithExtRefNo } = response;

            if (signupWithExtRefNo.success === "false") {
              setLoading(false);
              setTokenError('Account approved');
              setSignUpFlow(true);
              setErrorFlow(true);
            } else {
              setLoading(false);
              setSignUpFlow(true);
            }
          })
          .catch((error) => {

            if (error.message.trim() === "Token is Expired.") {
              setLoading(false);
              setTokenError(error.message.trim());
              setSignUpFlow(true);
              setErrorFlow(true);
            } else {
              setLoading(false);
              setSignUpFlow(true);
              setErrorFlow(true);
            }
          });
      }
    } else {
      setSignUpFlow(true);
    }
  }, [jwt]);

  const getCurrentFormDetails = () => {
    return signUpFormSteps[currentForm];
  };

  const onFormSubmit = (formValues: any) => {
    setUserData((prev) => ({ ...prev, ...formValues }));
    setCurrentForm(getCurrentFormDetails().next);
  };

  const handleNext = () => {
		setCurrentForm(getCurrentFormDetails().next);
  };

  const handlePrevious = () => {
    currentForm === "addressForm" && setUserData({ ...userData, code: "" });
    setCurrentForm(getCurrentFormDetails().previous);
  };

  const fetchLegalAgreements = () => {
    getLegalAgreement()
		.then((response: any) => {
			const legalAgreement = response.legalAgreements;
			if (legalAgreement) setLegalAgreements(legalAgreement);
		})
		.catch((error) => {
			setLegalAgreements(agreementText);
		});
  };

  const handleLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (currentForm === "eSignAgreement") {
      if (legalAgreements.length < 1) {
        fetchLegalAgreements();
      }
    }
  }, [currentForm]);

  const formProps = {
    formData: userData,
    legalAgreements,
    setIspendingOutOfWallet,
    handleNext,
    handlePrevious,
    handleLogin,
    setCurrentForm,
    onFormSubmit
  };

  const { component, listStep } = getCurrentFormDetails();

  const { blackColor } = useStyles(["blackColor"]);
  const { cardIssuanceInformation } = useConfigs(["cardIssuanceInformation"]);

  return (
    <>
      {isSignUpFlow && (
        <div className="d-flex-center signup-container">
          {loading && <Loader className="custom-loader" />}
          {!isErrorFlow && (
              <div style={{flexGrow: 1}} className="pt-5 pb-5 d-flex-center">
                <img className="header-img" src={openCPImage} alt="headerImg"/>
                <p className="signup-footer w-100 weight-500 pt-4" style={{ color: blackColor}} >
                  To sign up for a my529 Access Card, you must be the owner of a my529 account.
                </p>
                <p className="signup-footer w-100 weight-500 pb-3" style={{ color: blackColor}} >
                  Use your name, not the beneficiary’s,
                  to sign up for the card.
                </p>
                <Grid
                    container
                    direction="row"
                    className="steps-container pt-4 pb-4 mt-4"
                >
                  <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="steps-section mb-3"
                  >
                    {!ispendingOutOfWallet && (<StepsView listStep={listStep} numberofSteps={6}/>)}

                  </Grid>
                  <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="component-section mb-4"
                  >
                    {component(formProps)}
                  </Grid>
                </Grid>
              </div>
          )}
          {isErrorFlow && (
              <div style={{flexGrow: 1}} className="pt-5 pb-5 d-flex-center">
                <img className="header-img" src={openCPImage} alt="headerImg"/>
                <Grid
                    container
                    direction="row"
                className="steps-container pt-4 pb-4 mt-4"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className="component-section mb-4"
                >
                  <SignupError tokenError={isTokenError} message={isTokenError}></SignupError>
                </Grid>
              </Grid>
            </div>
          )}

            <p className="signup-footer pt-5 pb-3" style={{ color: blackColor }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(cardIssuanceInformation, { ADD_ATTR: ['target'] }),
              }}
              >
            </p>
        </div>
      )}
    </>
  );
};

export default SignupForm;
