import MaterialModal from '..'
import AddRecipientModalContent from './AddRecipientModalContent'

interface AddRecipientModalProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}

const AddRecipientModal = ({ isOpen, ...rest }: AddRecipientModalProps) => {
  return (
    <MaterialModal open={isOpen} handleClose={rest.onClose} width={'33rem'}>
      <AddRecipientModalContent {...rest} />
    </MaterialModal>
  )
}
export default AddRecipientModal
